
import { Amplify } from 'aws-amplify';
import React, { useState, useEffect, useRef, useContext } from 'react'
//import isUrlHttp from 'is-url-http';
//import './Podcast.css'; 
import {
    TextField, 
    Text,
    CheckboxField,
    TextAreaField,
    Button,
    Flex,
    SelectField
  } from '@aws-amplify/ui-react';
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
import PropTypes from 'prop-types';
import styles from './Podcast.module.css';  
import {listApplicants, getSecrets} from '../../graphql/queries'
import {createAudienceGeoInfo, GetRSSFeedJSON}  from '../../graphql/mutations'
import StatusMessageBox from "../../ui-components/StatusMessageBox";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import {uuidv4} from "../../BusinessLogic/UtilitiesManagement"; 

import { generateClient } from 'aws-amplify/api';
import Footer from '../RhyteItComponents/Footer/Footer';
import { SEO } from '../SEO/SEO';
import {RhyteItLogging} from '../../BusinessLogic/UserManagement';
import PodcastEpisodes from './PodcastEpisodes';
import InterviewApplicant from './InterviewApplicant';
import CreateYourOwnPodcast from './CreateYourOwnPodcast';
import { Helmet } from "react-helmet-async";
import { fetchGeoLocation } from "../../BusinessLogic/geoLocationService";


const Podcast = (props) => {

    Podcast.propTypes = {
        UserEmail: PropTypes.string,
        UserId: PropTypes.string,
        UserName: PropTypes.string
      };

    
    const [location, setLocation] = useState(null);
    const [vFirstTouch, setvFirstTouch] = useState(true);  
    const client = generateClient();


    const ref = useRef(null);
    const isInView = useInView(ref);
    const mainControls = useAnimation();

    const [vEpisodesRefresh, setvEpisodesRefresh] = useState()  

    useEffect(() => {

        (async function() {
            try {  
                window.scrollTo(0, 0);
              async function getLocation() {
                const data = await fetchGeoLocation(vFirstTouch, "Podcast");
                setLocation(data);
                setvFirstTouch(false)
              }
              getLocation();


                } catch (err) {

                  const errorMessage = err.message || 'Unknown error';
                  const errorStack = err.stack || 'No stack trace available';
    
    
                  RhyteItLogging(
                      "NA",
                      "Podcast",
                      "useEffect[]",
                      errorMessage,
                      '{"ERROR": "' + errorStack + '"}',
                      0
                  );
              }
            }
          )();    
                
    },[])   

    useEffect(() => {
        // //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);
        
         if (isInView) {
           mainControls.start("visible");
         }
       }, [isInView]);



    return (
        <motion.div
                key="Podcast"
                style={{
                    transform: isInView ? "none" : "translateX(-200px)",
                    opacity: isInView ? 0.8 : 0,
                    transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                  }}
                className = {styles.Background}
               >      
              <div className = {styles.fuzzyoverlay} />
              <div className = {styles.girl} />          
               <section ref={ref} className = {styles.Section}>   
                <Helmet>
                  <title>"Adult Creator Mastery Podcast: Thrive as an OnlyFans Creator"</title>
                  <meta name="description" content="Unlock the secrets to success as an OnlyFans creator with the Adult Creator Mastery Podcast. Discover proven strategies, trending tools, and actionable tips to grow your fan base, boost engagement, and increase your earnings. Each weekly episode is packed with insights to help you master your business and thrive in the competitive creator economy." />                  
                  <meta name="keywords" content="OnlyFans podcast, adult content creators, Fansly tips, Fanvue tools, grow OnlyFans followers, content marketing, creator economy strategies, RhyteIt podcast, adult creator tips, passive income for creators, content moderation, fan engagement" />                                    
                  <meta name="robots" content="index, follow"/>  
                  <meta name="author" content="Simone Giacche"/>  
                  <link rel="canonical" href="https://www.rhyteit.com/podcast"/>  
                  <meta property="og:type" content="website"/>  
                  <meta property="og:title" content="Adult Creator Mastery Podcast: Thrive as an OnlyFans Creator"/>   
                  <meta property="og:description" content="Proven strategies, trending tools, and actionable tips to grow your OnlyFans business. Listen to weekly episodes of the Adult Creator Mastery Podcast and take your content creation to the next level."/>   
                  <meta property="og:url" content="https://www.rhyteit.com/podcast"/>   
                  <meta property="og:image" content="https://podcast.rhyteit.com/PodcastMetaCardImage.png"/>   
                  <meta property="og:site_name" content="RhyteIt"/>   
                  
                  <meta name="twitter:card" content="summary_large_image"/>   
                  <meta name="twitter:title" content="Adult Creator Mastery Podcast: Thrive as an OnlyFans Creator"/>   
                  <meta name="twitter:description" content="Discover the tools and strategies that top adult creators use to grow their fan base, increase engagement, and boost earnings. Weekly insights tailored for OnlyFans creators."/>   
                  <meta name="twitter:image" content="https://podcast.rhyteit.com/PodcastMetaCardImage.png"/>   
                  <meta name="twitter:site" content="@RhyteIt"/>   
                  <meta name="twitter:creator" content="@RhyteIt"/>                     
                </Helmet>                                   
                <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        alignContent="flex-start"
                        wrap="wrap"
                        gap="3rem"
                        className = {styles.FormDiv}>                  
                    <motion.div 
                        className = {styles.HeaderMenuDiv}
                        key="Podcast"
                        style={{
                            transform: isInView ? "none" : "translateX(-200px)",
                            opacity: isInView ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
                            }}>
                    <HeaderMenu className = {styles.HeaderMenu} pageSource="Application"/>  
                    </motion.div>                      
                      <PodcastEpisodes key={vEpisodesRefresh}/>                      
                      <InterviewApplicant />
                      <CreateYourOwnPodcast />
                      <Footer/>   
                </Flex>        
                </section> 
            </motion.div>
                
    );
}

export default Podcast;    