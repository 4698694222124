import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listRhyteItLogs,
  listSMMADAccounts,
} from "../../graphql/queries";
import {
  createRhyteItLog,
  updateRhyteItLogs,
  deleteRhyteItLog
} from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogV2,
  ReturnUserToLandingPage,
} from "../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../Menu/MenuBackground";
import { get_uuidv4 } from "../../BusinessLogic/uuidv4";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./RhyteItLog.module.css";


import RhyteItLogMetricTable from "./RhyteItLogMetricTable"


const RhyteItLog = (props) => {
  RhyteItLog.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vRhyteItLog, setvRhyteItLog] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/RhyteItLog", Title: "RhyteItLog", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);  
  
  let [vRhyteItLogs, setvRhyteItLogs] = useState();

  const [vRhyteItLogEnvironmentSelected, setvRhyteItLogEnvironmentSelected] = useState({"Environment":"None"});
  let [vRhyteItLogList, setvRhyteItLogList] = useState();  
  const [vAddRhyteItLogVisible, setvAddRhyteItLogVisible] = useState(false);

  const [vRhyteItLogVisible, setvRhyteItLogVisible] = useState(false);
 
  const [nextToken, setnextToken] = useState(undefined) 
  const [IsLoading, setIsLoading] = useState(false)
  const [vRhyteItLogFilter, setvRhyteItLogFilter] = useState()
  const [vRhyteItLogFiltered, setvRhyteItLogFiltered] = useState()
  const [vRhyteItLogEntriesReturned, setvRhyteItLogEntriesReturned] = useState(0)
  const [ShowTable, setShowTable] = useState(false); 
  const [vViewRhyteItLogEntries, setvViewRhyteItLogEntries] = useState(false); 

  
  const [vSearchTerm, setvSearchTerm] = useState();
  let [vRhyteItEnvironments, setvRhyteItEnvironments] = useState();  
  let [vPages, setvPages] = useState();  
  
  let [vPageSelected, setvPageSelected] = useState();  
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetRhyteItLogEntriesByFilter("ALL") 
           
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogV2(
          Users,
          "RhyteItLog",
          "useEffect[CognitoGroup]",
          err
      );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

  }, [vRhyteItLogEnvironmentSelected]);

  useEffect(() => {
  
      //console.log("RhyteItLog.useEffect[vRhyteItLogFilter].vRhyteItLogFilter: ",vRhyteItLogFilter);

      if (vRhyteItLogFilter !== undefined) {

        GetRhyteItLogs(vRhyteItLogFilter)
          
      }

    }, [vRhyteItLogFilter]);     
  
  useEffect(() => {




  }, [vRhyteItLogEnvironmentSelected]);

  useEffect(() => {

    if ( vRhyteItLogs !== undefined) {
      //console.log("RhyteItLog.useEffect[vRhyteItLogs].vRhyteItLogs.length: ",vRhyteItLogs.length);
    }

  }, [vRhyteItLogs]);

  useEffect(() => {

    const fetch1 = async () => {
      

      try {

            let RhyteItLogData = null

            if(nextToken !== null && nextToken !== undefined) {

              //console.log("RhyteItLog.fetch1.RhyteItLogData.vRhyteItLogFilter:  ",vRhyteItLogFilter)
              
              setIsLoading(true)

                let variables = {
                  nextToken,
                  filter: vRhyteItLogFilter
                };

                
    
                const RhyteItLogData  = await client.graphql({
                  query: listRhyteItLogs,
                  variables: variables
                });     

              
              //console.log("RhyteItLog.fetch1.RhyteItLogData.length:  ",RhyteItLogData.data.listRhyteItLogs.items.length)


              if (RhyteItLogData.data.listRhyteItLogs.items.length > 0) {

                  let vRhyteItLogDataTmp = vRhyteItLogs   

                  if (vRhyteItLogDataTmp !== undefined) {
        
                      RhyteItLogData.data.listRhyteItLogs.items.forEach(item => 
                        {   
                      
                          if ([...vRhyteItLogDataTmp].filter(Content => item.id === Content.id)["length"] === 0) {  
                            vRhyteItLogDataTmp.push(item) 
                          }
    
                        })

                      setvRhyteItLogs(vRhyteItLogDataTmp)  

                  } else {

                    setvRhyteItLogs(RhyteItLogData.data.listRhyteItLogs.items)  

                  }

                  if (RhyteItLogData.data.listRhyteItLogs.nextToken !== null ) {
                    //console.log("RhyteItLog.fetch1.RhyteItLogData.data.listRhyteItLogs.nextToken.valid1:  ",RhyteItLogData.data.listRhyteItLogs.nextToken.substring(0, 4))
                    
                    if (RhyteItLogData.data.listRhyteItLogs.nextToken !== nextToken) {
                      setnextToken(RhyteItLogData.data.listRhyteItLogs.nextToken)    
                    }

                  } else if (RhyteItLogData.data.listRhyteItLogs.nextToken === null){          
              
                    //console.log("RhyteItLog.fetch1.vToken.null:  ",RhyteItLogData.data.listRhyteItLogs.nextToken)
                    setnextToken()
                    setIsLoading(false)  
                  }  
                    
              } else if (RhyteItLogData.data.listRhyteItLogs.items.length === 0) {
              
                if (RhyteItLogData.data.listRhyteItLogs.nextToken !== null){                  
                  //console.log("RhyteItLog.fetch1.RhyteItLogData.data.listRhyteItLogs.nextToken.valid2:  ",RhyteItLogData.data.listRhyteItLogs.nextToken.substring(0, 4))
                  
                  if (RhyteItLogData.data.listRhyteItLogs.nextToken !== nextToken) {
                    setnextToken(RhyteItLogData.data.listRhyteItLogs.nextToken)    
                  }
                      
  
                } else if (RhyteItLogData.data.listRhyteItLogs.nextToken === null){                  
                  setnextToken()
                  setIsLoading(false)         
  
                }
            }
            
            } else {setIsLoading(false) }

      } catch (err) {
        
          console.error("RhyteItLog.useEffect.[nextToken].fetch1.Error: ", err, err.stack)

      } 
    }
  
    fetch1()

  }, [nextToken]) 

  useEffect(() => {

    console.log("RhyteItLog.useEffect[IsLoading].vRhyteItLogFilter:  ",vRhyteItLogFilter)
    console.log("RhyteItLog.useEffect[IsLoading].IsLoading:  ",IsLoading)
    console.log("RhyteItLog.useEffect[IsLoading].nextToken:  ",nextToken)
    console.log("RhyteItLog.useEffect[IsLoading].vRhyteItLogs:  ",vRhyteItLogs)

    if (vRhyteItLogFilter !== undefined && vRhyteItLogs !== undefined && IsLoading === false && nextToken === undefined) {

      //console.log("RhyteItLog.useEffect[IsLoading].vRhyteItLogs.length2:  ",vRhyteItLogs.length)
      //console.log("RhyteItLog.useEffect[IsLoading].vRhyteItLogs:  ",vRhyteItLogs)
      let vRhyteItLogEntriesSorted = SortLogsByDate(vRhyteItLogs)
      setvRhyteItLogs(vRhyteItLogEntriesSorted)
      setvRhyteItLogFiltered(vRhyteItLogEntriesSorted)
      setvViewRhyteItLogEntries(true)
      GetEnvironments(vRhyteItLogs)
      GetPages(vRhyteItLogs)

    }

  }, [IsLoading]);  

  useEffect(() => {

    if (vRhyteItLogFiltered !== undefined) {
      //console.log("RhyteItLog.useEffect[vRhyteItLogFiltered].vRhyteItLogFiltered.length:  ",vRhyteItLogFiltered.length)
      setvRhyteItLogEntriesReturned(vRhyteItLogFiltered.length)
      setupdatedKey(Date.now());
    }


  }, [vRhyteItLogFiltered]);    

  function SortLogsByDate(pLogs) {
    pLogs.sort(GFG_sortFunction);
    return pLogs
//    //console.log(JSON.stringify(pEmails));
}

function GFG_sortFunction(a, b) {
  let RhyteItLogA = a.CreationDateTime;
  let RhyteItLogB = b.CreationDateTime;
  return RhyteItLogA < RhyteItLogB ? 1 : -1;
}

  async function GetRhyteItLogs() {
    try {
      setErrorMessage("");
      
      let variables = {
        nextToken,
        filter: vRhyteItLogFilter
      };
      setIsLoading(true) 

      const listRhyteItLogsData2 = await client.graphql({
        query: listRhyteItLogs,
        variables: variables,
      });

      //console.log("RhyteItLog.GetRhyteItLogs.listRhyteItLogs.data.listRhyteItLogs.items.length : ", listRhyteItLogsData2.data.listRhyteItLogs.items.length );

      if (listRhyteItLogsData2.data.listRhyteItLogs.items.length > 0) {
         // console.log("listRhyteItLogsData2.data.listRhyteItLogs.items.length: ",listRhyteItLogsData2.data.listRhyteItLogs.items.length);
                     
          setvRhyteItLogs(listRhyteItLogsData2.data.listRhyteItLogs.items)    
          
          if (listRhyteItLogsData2.data.listRhyteItLogs.nextToken !== null ) {
            //console.log("RhyteItLog.GetRhyteItLogs.listRhyteItLogsData2.data.listRhyteItLogs.nextToken.valid:  ",listRhyteItLogsData2.data.listRhyteItLogs.nextToken.substring(0, 4))
            setnextToken(listRhyteItLogsData2.data.listRhyteItLogs.nextToken)  

          } 
        
      }  
        
      if (listRhyteItLogsData2.data.listRhyteItLogs.nextToken !== null){                  
          //console.log("RhyteItLog.GetRhyteItLogs.listRhyteItLogsData2.data.listRhyteItLogs.nextToken.valid:  ",listRhyteItLogsData2.data.listRhyteItLogs.nextToken.substring(0, 4))
          setnextToken(listRhyteItLogsData2.data.listRhyteItLogs.nextToken)        

      } 
      else if (listRhyteItLogsData2.data.listRhyteItLogs.nextToken === null){
      //  console.log("RhyteItLog.GetRhyteItLogs.vToken.null:  ",listRhyteItLogsData2.data.listRhyteItLogs.nextToken)
        setIsLoading(false)  
      }                    


    } catch (err) {

      
        RhyteItLogV2(
          Users,
          "RhyteItLog",
          "GetRhyteItLogs",
          err
      );
    }
  }


  function GetEnvironments(pRhyteItLog) {

    const vEnvironments = Array.from(
      new Set(pRhyteItLog.map(log => log.Environment)) // Get unique environments
    ).map(environment => ({ Environment: environment })); // Map to JSON objects

    setvRhyteItEnvironments(vEnvironments);

  }
  
  function GetPages(pRhyteItLog) {

    const vPagesTmp = Array.from(
      new Set(pRhyteItLog.map(log => log.Page)) // Get unique environments
    ).map(page => ({ Page: page })); // Map to JSON objects

    setvPages(vPagesTmp);

  }
  

  async function FilterSelection(pFilter, pFilterValue) {
    try {
      //console.log("RhyteItLog.FilterSelection.pFilter: ", pFilter);
      //console.log("RhyteItLog.FilterSelection.pFilterValue: ", pFilterValue);
      let vSelectedRhyteItLog = vRhyteItLogs.filter(
        (vRhyteItLogItem) => vRhyteItLogItem[pFilter] === pFilterValue
      );
      

      setvRhyteItLogFiltered(vSelectedRhyteItLog)

      if (pFilter === "Environment"){
        setvRhyteItLogEnvironmentSelected(pFilterValue);
      }

      
      if (pFilter === "Page"){
        setvPageSelected(pFilterValue);
      }

    } catch (err) {

    
      RhyteItLogV2(
        Users,
        "RhyteItLog",
        "FilterSelection",
        err
    );

    }
  }

  async function GetRhyteItLogEntriesByFilter(pFilter) {
    try {

              setvRhyteItLogEntriesReturned(0)
              setShowTable(false)
              setvViewRhyteItLogEntries(false)
                    

              var date = new Date();
              date.setDate(date.getDate() - 1);
              var Yesterday = date.toISOString() 
              
              date.setDate(date.getDate() - 7);
              var LastWeek = date.toISOString() 

              //console.log("RhyteItLog.GetRhyteItLogEntriesByFilter.vRhyteItLogEnvironmentSelected:  ",vRhyteItLogEnvironmentSelected)

              let vFilter = ""

              if (pFilter === "TODAYENVIRONMENT") {
                vFilter = {
                      Environment:{eq: vRhyteItLogEnvironmentSelected},
                      CreationDateTime: {
                        gt: Yesterday
                      }
                    }
              } else if (pFilter === "TODAYALL") {

                vFilter = {
                  CreationDateTime: {
                    gt: Yesterday
                  }
                  }
                             

              } else if (pFilter === "ALL") {

                  vFilter = {}              

              } else if (pFilter === "READY2PUBLISH") {

                vFilter = {
                  Blocked: {eq: false}, 
                  ArticleTextCollected:{eq: true}, 
                  ArticleAnalyzed:{eq: true}, 
                  ArticleTrendAnalyzed:{eq: true}, 
                  TwitterPostCreated:{eq: true}, 
                  ArticlePublished:{eq: false}, 
                  CreationDateTime: {gt: LastWeek}
                }  
                
              } else if (pFilter === "PUBLISHED") {

                vFilter = {
                  Blocked: {eq: false}, 
                  ArticleTextCollected:{eq: true}, 
                  ArticleAnalyzed:{eq: true}, 
                  ArticleTrendAnalyzed:{eq: true}, 
                  TwitterPostCreated:{eq: true}, 
                  ArticlePublished:{eq: true}, 
                  CreationDateTime: {
                    gt: Yesterday
                  }
                }  
                
              } else if (pFilter === "TWITTERPOSTS") {

                vFilter = {
                  TwitterPostCreated:{eq: true}, 
                  CreationDateTime: {gt: LastWeek}
                }  
                
              }  else if (pFilter === "ID") {
                vFilter = {
                      id:{eq: vSearchTerm}
                    }
              } else if (pFilter === "TITLE") {
      
                  vFilter = {
                    ArticleTitle: {contains: vSearchTerm}
                  }          
      
              } else if (pFilter === "TEXT") {
      
                vFilter = {
                  TextString: {contains: vSearchTerm}
                }  
                
              } 
      
            setvRhyteItLogFilter(vFilter)
          

    } catch (err) { 
    
      RhyteItLogV2(
        Users,
        "RhyteItLog",
        "GetRhyteItLogEntriesByFilter",
        err
    );
                }
  }      
  
  function DeleteAllFilteredRows() {
    try {

         //console.log("DeleteAllFilteredRows.Users:   ",Users    ) 

          if (Users === "edwardhawke@rhyteit.com") {
        
            vRhyteItLogFiltered.forEach(item => 
              {   
                LogDeleteRow(item) 

              })
          
          GetRhyteItLogs()      
        } 
    
        } catch (err) { 

          RhyteItLogV2(
            Users,
            "RhyteItLog",
            "DeleteAllFilteredRows",
            err
        );
     
      }               
    } 

  function LogDeleteRow(pLogEntry) {
    try {

         //console.log("LogDeleteRow.pLogEntry:  ",pLogEntry    ) 

          if (Users === "edwardhawke@rhyteit.com") {
        
              var today = new Date();

                const vVariables = {
                  id: pLogEntry.id,
                  _version: pLogEntry._version
                }
                


                const ReturnMessage = client.graphql({
                  query: deleteRhyteItLog,
                  variables: {
                    input: vVariables
                  }
                });  

            
              ErrorMessageStr = "Item deleted at: " + today.toLocaleString("en-US")
              setErrorMessage(ErrorMessageStr)       
                
        } 
    
        } catch (err) { 

          RhyteItLogV2(
            Users,
            "RhyteItLog",
            "LogDeleteRow",
            err
        );
    
      }               
    } 

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="RhyteItLog Manager"
          SubTitle="Manage your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="RhyteItLog"
        />
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
      >        
       
        <Fieldset className={styles.Fieldset} size="small">
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroupLabel}>Records Returned:</Text> 
              <Text  className={styles.AccountGroup}>{vRhyteItLogEntriesReturned}</Text>
            </Flex>             
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >            
          <SelectField
            className={styles.SelectField}
            value={vRhyteItLogEnvironmentSelected.Environment}
            placeholder="Select Environment"
            onChange={(e) => FilterSelection("Environment", e.target.value)}
          >
            {vRhyteItEnvironments !== undefined &&
              vRhyteItEnvironments.map((vRhyteItEnvironment) => (
                <option
                  key={vRhyteItEnvironment.Environment}
                  className={styles.SelectFieldOption}
                  value={vRhyteItEnvironment.Environment}
                  label={vRhyteItEnvironment.Environment}
                >
                  {vRhyteItEnvironment.Environment}
                </option>
              ))}
          </SelectField>  
          <SelectField
            className={styles.SelectField}
            value={vRhyteItLogEnvironmentSelected.Environment}
            placeholder="Select Page"
            onChange={(e) =>  FilterSelection("Page", e.target.value)}
          >
            {vPages !== undefined &&
              vPages.map((vPage) => (
                <option
                  key={vPage.Page}
                  className={styles.SelectFieldOption}
                  value={vPage.Page}
                  label={vPage.Page}
                >
                  {vPage.Environment}
                </option>
              ))}
          </SelectField>            
          </Flex>
          <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >            
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Delete Records"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => DeleteAllFilteredRows(e)}
                      
                    >
                    Delete
                </Button>   
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Delete Records"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => GetRhyteItLogEntriesByFilter("TODAYALL") }
                      
                    >
                    Refresh
                </Button>                            
          </Flex>          
          {vRhyteItLogEntriesReturned !== undefined &&  (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="nowrap"
              gap="0.25rem"
            >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroupLabel}>Environment:</Text> 
              <Text  className={styles.AccountGroup}>{vRhyteItLogEnvironmentSelected.Environment}</Text>
            </Flex> 
            </Flex>
                          
          )}
          </Flex> 

          {vRhyteItLogEnvironmentSelected["Environment"] !== "None" &&  (          
          <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}>  
                <SelectField
                    label="Fruit"
                    labelHidden
                    className={styles.SelectField}
                    placeholder="Select Query"
                    value={vRhyteItLogFilter}
                    onChange={(e) => setvRhyteItLogFilter(e.target.value)}
                  >
                    <option className={styles.SelectFieldOption} value="TODAYLOGS">Last 24 hours</option>
                    <option className={styles.SelectFieldOption} value="READY2ANALYZE">Ready to analyze</option>
                    <option className={styles.SelectFieldOption} value="READY2PUBLISH">Ready to publish</option>
                    <option className={styles.SelectFieldOption} value="PUBLISHED">New articles published today</option>
                    <option className={styles.SelectFieldOption} value="PUBLISHEDTODAY">All articles published today</option>
                    
                    <option className={styles.SelectFieldOption} value="TWITTERPOSTS">Twitter posts</option>
                </SelectField>                                                                                                         
          </Flex>   
          )}                 
        </Fieldset>        
        
      </Flex>   
      {vRhyteItLogEntriesReturned !== undefined && vRhyteItLogEntriesReturned > 0 &&  (        
          <RhyteItLogMetricTable key={updatedKey} Users={Users} RhyteItLogs={vRhyteItLogFiltered}/>         
        )}                      
      </Flex> 
                  
    </m.div>
  );
};

export default RhyteItLog;
