
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import AffiliateProgramRow from './AffiliateProgramRow';  
import styles from './AffiliateProgramTable.module.css';


const AffiliateProgramTable = (props) => {       

  AffiliateProgramTable.propTypes = {
      AffiliateProgram: PropTypes.object
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [AffiliateProgram, setAffiliateProgram] = useState(props.AffiliateProgram)
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

        console.log("AffiliateProgramTable.useEffect[].props.AffiliateProgram:  ",props.AffiliateProgram) 
         

        if (Object.keys(AffiliateProgram).length > 0) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        console.log("AffiliateProgramTable.useEffect[AffiliateProgram].AffiliateProgram:  ",AffiliateProgram) 
  
      },[AffiliateProgram])        

  return ( 
    <div>
       
       {
         AffiliateProgram !== undefined &&  (   
      <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
      <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Email</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Stripe Account ID</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Referral Code</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Clicks</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Sign Ups</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Earnings</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Active</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Owner</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Updated</TableCell> 
              <TableCell className={styles.TableHeaderRowCellsActions} colSpan="2" >Actions</TableCell> 
            </TableRow>                                      
          </TableHead>  
          { 
                AffiliateProgram !== undefined &&                        
                  (<AffiliateProgramRow   key={AffiliateProgram.id}  AffiliateProgram={AffiliateProgram}/>)        
                  
              }                                
        </Table>)
              }    
      </div> 
    
  )
}

export default AffiliateProgramTable;


