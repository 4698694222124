import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./NewsLetter.module.css";

import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
//import {useInView} from "react-intersection-observer";

import { Button, Flex, Text, TextAreaField } from "@aws-amplify/ui-react";


import {listApplicants} from '../../../graphql/queries'
import {createNewsletterSubscribers, createApplicants, createMeetings}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import { fetchGeoLocation } from "../../../BusinessLogic/geoLocationService";

const NewsLetter = (props) => {
  NewsLetter.propTypes = {
    scrollYProgress: PropTypes.object,
  };


  const client = generateClient();

  const scale = useTransform(props.scrollYProgress, [0.71, 0.80], [0.02, 2.75]);
  //const rotate = useTransform(props.scrollYProgress, [0.90, 0.95], [0, 360 ]);
  //const opacity = useTransform(props.scrollYProgress, [0.9, 0.95], [1, 1 ]);
  const y = useTransform(props.scrollYProgress, [0.71, 0.80], [-100, 40 ]);

  const [vEmail, setvEmail] = useState(null);


  const refNewsLetterSection = useRef(null);
  const isInView = useInView(refNewsLetterSection);
  var StatusMessageStr = ""
  const [StatusMessage, setStatusMessage] = useState(StatusMessageStr)  
    const [location, setLocation] = useState(null);
    const [vFirstTouch, setvFirstTouch] = useState(true);

  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("NewsLetter.useEffect[isInView].props.scrollYProgress: ", props.scrollYProgress);

    if (isInView) {
        async function getLocation() {
          const data = await fetchGeoLocation(vFirstTouch, "LandingPageV5.NewsLetter");
          setLocation(data);
          setvFirstTouch(false)
        }
        getLocation();


    }
  }, [isInView]);

  useEffect(() => {

 //   console.log("NewsLetter.useEffect[vEmail].vEmail: ", vEmail);

  }, [vEmail]);


  function HandleEmailChange(event, data) {
    try {              

       // //console.log("RhyteItAccountSD.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
 

       //console.log("NewsLetter.HandleEmailChange.event: ", event);
        let vEmailTmp = event.target.value;
        setvEmail(vEmailTmp)  


      } catch (err) { 

        const errorMessage = err.message || 'Unknown error';
        const errorStack = err.stack || 'No stack trace available';


        RhyteItLogging(
          "NA",
          "LandingPageV5.NewsLetter",
          "HandleEmailChange",
          errorMessage,
          '{"ERROR": "' + errorStack + '"}',
          0
        );
    }        
  }    

  async function ProcessApplicant(e, data) {
    try {
        setStatusMessage("")
        
        console.log("NewsLetter.ProcessApplicant.vEmail: ", vEmail);
        let ProcessApplicantStatusMessage = ""

            let EmailAddressAlreadyExists = await CheckEmailAddress(vEmail)
            
            if (EmailAddressAlreadyExists === true) {
        
                    User_Add(vEmail);
                    
                    ProcessApplicantStatusMessage = "Alright! Congratulations on joining our reading list."
                    setStatusMessage(ProcessApplicantStatusMessage)

         }

   

    } catch (err) { //console.log('error fetching Users')
      const errorMessage = err.message || 'Unknown error';
      const errorStack = err.stack || 'No stack trace available';


      RhyteItLogging(
        "NA",
        "LandingPageV5.NewsLetter",
        "ProcessApplicant",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
                }
    }      


async function CheckEmailAddress(pApplicantEmail) {
    try {

        let vCheckEmailAddressReturnMessage = true
        console.log("Conversation.CheckEmailAddress.pApplicantEmail: ", pApplicantEmail)

        if (pApplicantEmail.length === 0) {
          setStatusMessage("Email address is blank") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search("Email address is blank") > -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search("@") === -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }
        if (pApplicantEmail.search(".") === -1) {
          setStatusMessage("Enter valid email address") 
          vCheckEmailAddressReturnMessage = false
        }        
   
        console.log("Conversation.CheckEmailAddress.vCheckEmailAddressReturnMessage: ", vCheckEmailAddressReturnMessage)
        return vCheckEmailAddressReturnMessage

    } catch (err) { //console.log('error fetching Users')
      const errorMessage = err.message || 'Unknown error';
      const errorStack = err.stack || 'No stack trace available';


      RhyteItLogging(
        "NA",
        "LandingPageV5.NewsLetter",
        "CheckEmailAddress",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
                }
}   

function convertToISO(datetimeStr) {
  // Create a new Date object from the provided string
  let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
  
  // Convert it to ISO string
  return date.toISOString();
}  



async function User_Add(pNewUser) {
    try {

        let ReturnMessage = ""
        let datetimeStr = "1999-01-01 01:00:00";
        let isoDate = convertToISO(datetimeStr);
        var vUpdateDateTimeNew = new Date().toISOString();

        const vSubscriptionPreferencesJSONString = JSON.stringify({"frequency": "weekly", "content": "news"});   
  
        let SubscriberItem = {
          id: vEmail,
          Newsletter: "The Adult Content Creator's Edge",
          AccountGroup: "RhyteIt Account Group 2",
          SubscriberEmail: vEmail,
          SubscriptionStatus: "subscribed",
          SubscribedDate: vUpdateDateTimeNew,
          SubscriberSegment: "",
          LastEmailSentDate: isoDate,
          LastEmailOpenedDate: isoDate,
          SendCount: 0,
          OpenCount:  0,
          ClickCount: 0,
          SubscriptionSource: "Manual",
          Geolocation: "Unknown",
          SubscriptionPreferences: vSubscriptionPreferencesJSONString,
          Active: true,
          CreationName: vEmail,
          CreationDateTime: vUpdateDateTimeNew,
          UpdateName: vEmail,
          UpdateDateTime: vUpdateDateTimeNew,
        };
  

                     const createNewsletterResponse = await client.graphql({
                       query: createNewsletterSubscribers,
                       variables: {
                         input: SubscriberItem,
                       },
                     });



    } catch (err) {   

        let errorMessage = null;
        let errorStack = null;
        let errorType = err.name;
  
        //console.log("NewsletterPublisherTable.GetSubscribers.err: ", err);
  
        if ("data" in err && "errors" in err) {
          errorMessage = err.errors[0]["errorType"] || err.toString;
          errorStack = err.errors[0]["message"] || "No stack trace available";
        } else {
          errorMessage = err.message || err.toString;
          errorStack = err.stack || "No stack trace available";
        }

        RhyteItLogging(
          "NA",
          "LandingPageV5.NewsLetter",
          "User_Add",
          errorType,
          errorMessage,
          '{"ERROR": "' + errorStack + '"}',
          0
        );      
    }        
}  


  return (
    <section ref={refNewsLetterSection} className={styles.SectionContainer} >   
      <motion.img src={"https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/TurquoiseCircle.svg"} 
            alt="Your SVG" 
            className={styles.Circle}     
            style={{ scale,  y, originY: 0.1 }} />
      <Flex
        direction="column"
        justifyContent="start"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        className={styles.TextFlexColumn}
      >
          <Flex
            direction="column"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            className={styles.HeaderDiv}
          >     
            <h1 className={styles.Title}>bECOME AN eXPERT</h1>
            <h1 className={styles.Text}>Learn how to dominate the subscription based social media industry</h1>
            <h1 className={styles.Text}>Stay up to date with the latest adult content creator trends</h1>
            <h1 className={styles.Text}>Case studies on how our customers are using RhyteIt to increase their fanbase</h1>
            <h1 className={styles.Text}>Preview of tools we are working on to help you achieve your ambitions</h1>
            <h1 className={styles.Text}>Interviews with adult content creators, management agencies and anyone else who will talk to us</h1>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            className={styles.EmailDiv}
            >              
              <TextAreaField className={styles.TextAreaField} 
                    name="vRhyteItAccount.Prompt"   
                    isRequired={true}            
                    placeholder="Your email address..."
                    defaultValue={vEmail}
                    onChange={ (e) => HandleEmailChange(e, vEmail) } />  
              <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  alignContent="start"
                  wrap="nowrap"
                  className={styles.ButtonRowDiv}
                  onClick={ (e) => ProcessApplicant(e, vEmail) } 
                  >                      
                  <h1 className={styles.ButtonText}>Subscribe</h1>
                  <img 
                  className={styles.ButtonArrow} src="https://rhyteiticons.s3.us-west-2.amazonaws.com/Backgrounds/Arrow.svg" alt="Test"/>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            justifyContent="start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.CaveatDiv}
          >        
            <h1 className={styles.caveats}>* We are lucky if we get one newsletter out a week :)</h1>
            <h1 className={styles.caveats}>* You can unsubscribe anytime</h1>
            <h1 className={styles.StatusMessage}>{StatusMessage}</h1>
          </Flex>   
      </Flex>

    </section>
  );
};

export default NewsLetter;
