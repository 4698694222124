
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteRhyteItvAffiliatePrograms, updateRhyteItLog}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './AffiliateProgramRow.module.css';  
  import { UserContext} from '../../../BusinessLogic/Hooks/UserContext';  

const AffiliateProgramRow = (props) => {       

  AffiliateProgramRow.propTypes = {
    AffiliateProgram: PropTypes.object
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vAffiliateProgram, setvAffiliateProgram] = useState(props.AffiliateProgram)      

    useEffect(() => {

       // console.log("AffiliateProgramRow.useEffect[].props.vAffiliateProgram:  ",props.vAffiliateProgram) 
      
      },[])  

      useEffect(() => {

        console.log("AffiliateProgramRow.useEffect[vAffiliateProgram].vAffiliateProgram:  ",vAffiliateProgram) 
       
       },[vAffiliateProgram])        

      async function UpdatevAffiliateProgramStatus(pStatus) {
        try {
  
  
              if (Users === "edwardhawke@rhyteit.com") {
  
                
  
                var vUpdateDateTime = new Date().toISOString()
                
                vAffiliateProgram.UpdateDateTime = vUpdateDateTime       
                vAffiliateProgram.UpdateName = Users
  
                  let vAffiliateProgramItem = {
                    "id": vAffiliateProgram.id,
                    "vAffiliateProgramAccount": vAffiliateProgram.vAffiliateProgramAccount,
                    "From": vAffiliateProgram.From,
                    "To": vAffiliateProgram.To,
                    "Subject": vAffiliateProgram.Subject,
                    "S3Bucket": vAffiliateProgram.S3Bucket,
                    "S3Key": vAffiliateProgram.S3Key,
                    "vAffiliateProgramText":  vAffiliateProgram.vAffiliateProgramText,
                    "vAffiliateProgramHtml": vAffiliateProgram.vAffiliateProgramHtml,
                    "vAffiliateProgramRawText": vAffiliateProgram.vAffiliateProgramRawText,
                    "vAffiliateProgramStatus": pStatus,
                    "ContentType": vAffiliateProgram.ContentType,
                    "Size": vAffiliateProgram.Size,
                    "SizeUnits": vAffiliateProgram.SizeUnits,
                    "ContentTransferEncoding": vAffiliateProgram.ContentTransferEncoding,
                    "InReplyTo": vAffiliateProgram.InReplyTo,
                    "MessageID": vAffiliateProgram.MessageID,
                    "CharSet": vAffiliateProgram.CharSet,
                    "CreationName": vAffiliateProgram.CreationName,
                    "CreationDateTime": vAffiliateProgram.CreationDateTime,
                    "UpdateName": vAffiliateProgram.UpdateName,
                    "UpdateDateTime": vAffiliateProgram.UpdateDateTime,
                }
  
                console.log("vAffiliateProgramDetail.UpdateToReadStatus.vAffiliateProgramItem    :  ",vAffiliateProgramItem    ) 
  
                const updateRhyteItLogResponse = await client.graphql({
                  query: updateRhyteItLog,
                  variables: {
                    input: vAffiliateProgramItem
                  }
                });  
  
                setvAffiliateProgram(updateRhyteItLogResponse.data.updateRhyteItLog)
                setErrorMessage(ErrorMessageStr)
      
   
                
            } else {
  
              ErrorMessageStr = "You do not have permission to update vAffiliateProgram"
              setErrorMessage(ErrorMessageStr)  
  
            }
        
            } catch (err) { 
  
            ErrorMessageStr = "vAffiliateProgramDetail.UpdateToReadStatus.Error: " + err 
  
            console.error(err); 
            
            setErrorMessage(ErrorMessageStr)         
          }               
        
      }    

 

    function EditAffiliateProgramRow(event, data) {
      try {           
        
          setErrorMessage("")


        } catch (err) { 
          ErrorMessageStr = ("vAffiliateProgram.EditAffiliateProgramRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }      
    



  return (
      <TableBody>   
      { vAffiliateProgram !== undefined &&  (        
          <TableRow  className={vAffiliateProgram.Active === true ?  styles.TableRowProcessed : styles.TableRowBackground} key={vAffiliateProgram.AffiliateProgramID}>
            <TableCell className={styles.TableRowHeadingData} >  
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.Email}
                </Text>  
              </TableCell>                                        
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.StripeAccountID}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.ReferralCode}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.TotalClicks}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.TotalSignups}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.TotalEarnings}
                </Text>  
            </TableCell>                                            
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.Active}
                </Text>  
            </TableCell>                                          
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vAffiliateProgram.Owner}
                </Text>  
            </TableCell>                  
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextFieldRecieved}>
                                  {new Date(vAffiliateProgram.UpdateDateTime).toLocaleString()}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
            <Link key={vAffiliateProgram.id} isExternal={false} to="/vAffiliateProgramDetail" state={{ vvAffiliateProgram: vAffiliateProgram }} >   
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => EditAffiliateProgramRow(e, vAffiliateProgram)}
                  >
                  E
                  </Button> 
              </Link>                 
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                    onClick={(e) => UpdatevAffiliateProgramStatus("delete") }
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow> )
              }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow  >              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default AffiliateProgramRow;

