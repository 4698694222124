import React, { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import 'mapbox-gl/dist/mapbox-gl.css';
import awsconfig from './aws-exports';

import { RhyteItLogV2 } from "./BusinessLogic/UserManagement";


Amplify.configure(awsconfig);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


root.render(     
    <React.StrictMode>
            <Authenticator.Provider>
                <BrowserRouter>   
                        <App />
                </BrowserRouter>
                </Authenticator.Provider>
        </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
