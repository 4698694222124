import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listBlogs,listBlogPosts
} from "../../../graphql/queries";
import  { createNewsletterSubscribers, createAudienceGeoInfo}  from '../../../graphql/mutations'
import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement";

import { generateClient } from "aws-amplify/api";

import {
  RhyteItLogging,
} from "../../../BusinessLogic/UserManagement";
import { motion as m } from "framer-motion";
import { useNavigate } from "react-router-dom";


import BlogEntries from './BlogEntries';
import BlogCategoryPosts from './BlogCategoryPosts';
import BlogMenuIcon from './BlogMenuIcon';
import { fetchGeoLocation } from "../../../BusinessLogic/geoLocationService";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField,
  SearchField
} from "@aws-amplify/ui-react";
import styles from "./Blog.module.css";
import { SEO } from '../../SEO/SEO';


function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

const Blog = (props) => {
  Blog.propTypes = {
    RedditUser: PropTypes.object,
  };

  const width = useWindowWidth();

  const client = generateClient();
  const [vBlog, setvBlog] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/Blog", Title: "Blog", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);


  const [vBlogSelected, setvBlogSelected] = useState();
  const [vNewsletterEmail, setvNewsletterEmail] = useState();
  const [vSearchTerm, setvSearchTerm] = useState();
  const inputRef = React.useRef(null);
  const searchButtonRef = React.useRef(null);
  const [vUpdateEntries, setvUpdateEntries] = useState();
  const [vSubscribed, setvSubscribed] = useState(false);
  const [vBlogCategoryData, setvBlogCategoryData] = useState(null);
  const [vBlogCategories, setvBlogCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vCategoryClicked, setvCategoryClicked] = useState(false);
  const [vCategorySelected, setvCategorySelected] = useState();
  const [vCategoryPosts, setvCategoryPosts] = useState();
  const [vBlogEntriesViewable, setvBlogEntriesViewable] = useState(false);
  const [vSideBarViewable, setvSideBarViewable] = useState(false);
  const [vCellPhone, setvCellPhone] = useState(false);


  const [vKeyWords, setvKeyWords] = useState();
  const [vTrends, setvTrends] = useState();
  
  const [vBlogPostFilter, setvBlogPostFilter] = useState();   
  const [vPageTitle, setvPageTitle] = useState();     
  const [vPageSubCategoryTitles, setvPageSubCategoryTitles] = useState();  

  const [vBlogPosts, setvBlogPosts] = useState()  
  const [vBlogPostsFiltered, setvBlogPostsFiltered] = useState()  
  
      const [location, setLocation] = useState(null);
          const [vFirstTouch, setvFirstTouch] = useState(true);  

  useEffect(() => {


    async function getLocation() {
      const data = await fetchGeoLocation(vFirstTouch, "Blog");
      setLocation(data);
      setvFirstTouch(false)
    }
    getLocation();

    window.scrollTo(0, 0);
    GetBlogs()
    getData()
    if (width < 800) {
      setvCellPhone(true)
    }

    //console.log("Blog.useEffect[].width: ", width);


      const fetchData = async () => {
        try {



            const response = await fetch('https://s3.us-west-2.amazonaws.com/blog.rhyteit.com/BlogCategories.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();            
            setvBlogCategoryData(jsonData);


        } catch (err) {
                  const errorMessage = err.message || 'Unknown error';
                  const errorStack = err.stack || 'No stack trace available';
    
    
                  RhyteItLogging(
                      "NA",
                      "Blog",
                      "useEffect[]",
                      errorMessage,
                      '{"ERROR": "' + errorStack + '"}',
                      0
                  );
        } finally {
            setLoading(false);
        }
    };

    fetchData();

  }, []);

  useEffect(() => {

    setvUpdateEntries(Date.now());

  }, [vBlogSelected]);

  useEffect(() => {
    
    //console.log("Blog.useEffect[vCellPhone].vCellPhone: ", vCellPhone);

    if (vCellPhone === false) {
      setvBlogEntriesViewable(true)
      setvSideBarViewable(true)
    } else {
      setvBlogEntriesViewable(true)
      setvSideBarViewable(false)
    }

  }, [vCellPhone]);

  useEffect(() => {

    //console.log("Blog.useEffect[vBlogEntriesViewable].vBlogEntriesViewable: ", vBlogEntriesViewable);

  }, [vBlogEntriesViewable]);

  useEffect(() => {

    if (vBlogCategoryData !== undefined && vBlogCategoryData !== null) {
      //console.log("Blog.useEffect[vBlogCategoryData].vBlogCategoryData: ", vBlogCategoryData);
      
      let vSortedBlogCategories = SortBlogCategoriesByPost(vBlogCategoryData["Categories"])
      setvBlogCategories(vSortedBlogCategories)

      let vSortedBlogPosts = SortBlogPosts(vBlogCategoryData["BlogPosts"])
      setvBlogPosts(vSortedBlogPosts)

      
      let vSortedKeyWords = SortKeyWords(vBlogCategoryData["KeyWords"])
      setvKeyWords(vSortedKeyWords.slice(0, 10))

      
      let vSortedTrends = SortKeyWords(vBlogCategoryData["Trends"])
      setvTrends(vSortedTrends.slice(0, 3))
      
    }

  }, [vBlogCategoryData]);  

  useEffect(() => {

    //console.log("Blog.useEffect[vKeyWords].vKeyWords: ", vKeyWords);

  }, [vKeyWords]); 


  useEffect(() => {

    window.scrollTo(0, 0);

  }, [vCategoryPosts]); 

  useEffect(() => {


    
    //console.log("News.useEffect.[vPageTitle].vPageTitle:  ",vPageTitle)
    if (vPageTitle !== undefined) {

      CreateSubCategoryJson(vPageTitle)
      
    }

}, [vPageTitle]);  


  function SortBlogPosts(pBlogPosts) {
    pBlogPosts.sort(GFG_SortBlogPosts);
    return pBlogPosts;
    //console.log(""JSON.stringify(pEmails));
  }
  
  function GFG_SortBlogPosts(a, b) {
    let CreationDateTimeA = a.CreationDateTime;
    let CreationDateTimeB = b.CreationDateTime;
    return CreationDateTimeA < CreationDateTimeB ? 1 : -1;
  }

  function SortKeyWords(pKeyWords) {
    pKeyWords.sort(GFG_SortKeyWords);
    return pKeyWords;
    //console.log(""JSON.stringify(pEmails));
  }
  
  function GFG_SortKeyWords(a, b) {
    let UpdateDateTimeA = a.UpdateDateTime;
    let UpdateDateTimeB = b.UpdateDateTime;
    return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
  }

  async function CreateSubCategoryJson(pCategory) {
    try {
       
  
        //console.log("News.CreateSubCategoryJson.pCategory:  ",pCategory)
  
        let vSubCategories = ""
  
        if (pCategory === "Recent Blog Posts") {
  
          
          //console.log("News.CreateSubCategoryJson.Headlines:  ",pCategory)
          //console.log("News.CreateSubCategoryJson.vNewsCategoryData.SubCategories:  ",vNewsCategoryData["SubCategories"].slice(0,5))
  
          vSubCategories = vBlogCategoryData["SubCategories"];
  
        } else {
  
          vSubCategories = vBlogCategoryData["SubCategories"].filter(item => item.Category === pCategory); 
  
        }
  
  
        
        let vSortedNewsCategories = SortSubCategories(vSubCategories)
        setvPageSubCategoryTitles(vSortedNewsCategories.slice(0,5))
  
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "News",
        "CategoryClickHandler",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  
  function SortSubCategories(pSubCategories) {
    pSubCategories.sort(GFG_sortSubCategories);
    return pSubCategories;
    //console.log(""JSON.stringify(pEmails));
  }
  
  function GFG_sortSubCategories(a, b) {
    let UpdateDateTimeA = a.UpdateDateTime;
    let UpdateDateTimeB = b.UpdateDateTime;
    return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
  }

          const getData = async () => {

                                          

            if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
                  vFirstTouch = false;    
          
                let DynamoUUID =  await uuidv4()
          
                let AudienceGeoItem = {
                  id: DynamoUUID,
                  UserId: "NA",
                  Page: "Blog",
                  IPv4: "1.1.1.1.1",
                  city: "Blog",
                  country_code: "Blog",
                  country_name: "Blog",
                  latitude: "37.751" ,
                  longitude: "-97.822",
                  postal: "NA",
                  state: "NA",
                  CreationName:  "Blog",
                  UpdateName: "Blog"
                }                      
                
                try{
          
                  let createAudienceGeoInfoResponse = await client.graphql({                    
                    query: createAudienceGeoInfo,
                    variables:{
                        input: AudienceGeoItem
                      }
                });         
                    
          
                } catch (err)  {
                  RhyteItLogging("NA", "Blog", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
              }
          
            }
          
          }

          async function GetBlogs() {
            try {
              setErrorMessage("");
              setvCategoryClicked(false)
              setvCategorySelected()
              
              //console.log("Blog.GetBlogs.Users: ", Users);
              let variables = {
                  filter: {
                    id: {
                      eq: "Adult Content Creator Corner",
                    },
                  },
                };

              const listBlogsData2 = await client.graphql({
                query: listBlogs,
                variables: variables,
              });

              //console.log("Blog.GetBlogs.listBlogs.data.listBlogs.items.length : ", listBlogsData2.data.listBlogs.items.length );

              if (listBlogsData2.data.listBlogs.items.length > 0) {
                const Blog1 =
                  listBlogsData2.data.listBlogs.items;
                  setvBlogSelected(Blog1[0])
              } 



            } catch (err) {
              RhyteItLogging(
                "NA",
                "Blog",
                "GetBlogs",
                err.toString(),
                '{"ERROR": "' + err.toString() + '"}',
                0
              );
            }
          }

          async function vSearchTermChanged(event, data) {
            try {

              let vTmp = event.target.value

                setvSearchTerm(vTmp);
            
          } catch (err) {
            RhyteItLogging(
              "NA",
              "Blog",
              "vSearchTermChanged",
              err.toString(),
              '{"ERROR": "' + err.toString() + '"}',
              0
            );
          }
          }

          async function GetSearch(event, data) {
            try {

              setvCategoryClicked(false)
              setvCategorySelected()
              setvPageTitle(vSearchTerm)   
              SearchBlogPosts()
              //console.log("Blog.GetSearch.event: ", event );
            
          } catch (err) {
            RhyteItLogging(
              "NA",
              "Blog",
              "vSearchTermChanged",
              err.toString(),
              '{"ERROR": "' + err.toString() + '"}',
              0
            );
          }
          }

          async function vClearSearchBar(event) {
            try {

              setvCategoryClicked(false)
              
              //console.log("Blog.vClearSearchBar.event: ", event );
              setvSearchTerm()
            
          } catch (err) {
              RhyteItLogging(
                "NA",
                "Blog",
                "vSearchTermChanged",
                err.toString(),
                '{"ERROR": "' + err.toString() + '"}',
                0
              );
            }
          }

          async function NewsletterSubscriberField(event, data) {
            try {

              let vTmp = event.target.value

                setvNewsletterEmail(vTmp);
            
          } catch (err) {
            RhyteItLogging(
              "NA",
              "Blog",
              "letterSubscriberField",
              err.toString(),
              '{"ERROR": "' + err.toString() + '"}',
              0
            );
          }
          }


          const onClick = React.useCallback(() => {
            inputRef.current.focus();
            //console.log("Blog.onClick.inputRef.current.value: ", inputRef.current.value );
            setvSearchTerm(inputRef.current.value);
            setvUpdateEntries(Date.now());
          }, []);

          React.useEffect(() => {
            const searchButtonRefCurrent = searchButtonRef.current;
            if (searchButtonRef && searchButtonRefCurrent) {
              // Note: this example is contrived to demonstrate using refs.
              // Use the `onSubmit` prop on `SearchField` instead which
              // responds to input field `Enter` keypresses and Submit button clicks.
              searchButtonRefCurrent.addEventListener('click', onClick, false);
              return () => {
                searchButtonRefCurrent.removeEventListener('click', onClick, false);
              };
            }
          }, [onClick]);

      function convertToISO(datetimeStr) {
        // Create a new Date object from the provided string
        let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
        
        // Convert it to ISO string
        return date.toISOString();
      }  

      async function CreateNewsletterSubscriber() {
              
        
        try {           
            setErrorMessage("")
                    
            var vUpdateDateTimeNew = new Date().toISOString();
        
            let vId = "TheAdultContentCreatorsEdge-RhyteItAccountGroup2-" + vNewsletterEmail

            let datetimeStr = "1999-01-01 01:00:00";
            let isoDate = convertToISO(datetimeStr);
            const vSubscriptionPreferencesJSONString = JSON.stringify({"frequency": "weekly", "content": "news"});   

            let SubscriberItem = {
              id: vId,
              Newsletter: "The Adult Content Creator's Edge",
              AccountGroup: "RhyteIt Account Group 2",
              SubscriberEmail: vNewsletterEmail,
              SubscriptionStatus: "subscribed",
              SubscribedDate: vUpdateDateTimeNew,
              SubscriberSegment: "",
              LastEmailSentDate: isoDate,
              LastEmailOpenedDate: isoDate,
              SendCount: 0,
              OpenCount:  0,
              ClickCount: 0,
              UnsubscribeReason: "",
              SubscriptionSource: "BlogPage",
              Geolocation: "Unknown",
              SubscriptionPreferences: vSubscriptionPreferencesJSONString,
              BounceReason: "",
              Active: true,
              CreationName: vNewsletterEmail,
              CreationDateTime: vUpdateDateTimeNew,
              UpdateName: vNewsletterEmail,
              UpdateDateTime: vUpdateDateTimeNew,
            };


            //console.log("Blog.CreateNewsletterSubscriber.SubscriberItem    : ", SubscriberItem );
        
            const createNewsletterResponse = await client.graphql({
              query: createNewsletterSubscribers,
              variables: {
                input: SubscriberItem,
              },
            });

            setvSubscribed(true)
            setvNewsletterEmail("")

          } catch (err) { 
            console.error("Blog.CreateNewsletterSubscriber.Error:  ", err, err.stack); 
            RhyteItLogging("NA", "Blog", "CreateNewsletterSubscriber",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
        }        
      }    

      function convertUTCDateTimeStringToDateTime(utcDateTimeString) {
        //console.log("Blog.convertUTCDateTimeStringToDateTime.utcDateTimeString    : ", utcDateTimeString );
        return new Date(utcDateTimeString);
      }


      function SortBlogCategoriesByPost(pBlogCategories) {
        pBlogCategories.sort(GFG_sortFunction);
        return pBlogCategories;
        //    //console.log(""JSON.stringify(pEmails));
      }

      function GFG_sortFunction(a, b) {
        let BlogPostCountA = a.BlogPostCount;
        let BlogPostCountB = b.BlogPostCount;
        return BlogPostCountA < BlogPostCountB ? 1 : -1;
      }

    async function CategoryClickHandler(event, pCategory) {
      try {
          //console.log("Blog.CategoryClickHandler.pCategory: ", pCategory );
          setvCategoryClicked(true)
          setvCategorySelected(pCategory)

          if (vCellPhone === true) {
            setvBlogEntriesViewable(true)
            setvSideBarViewable(false)
          } 

          if (pCategory !== "All") {
            let filteredArray = filterByCategory(pCategory);
            setvCategoryPosts(filteredArray)

          } else {

            let vBlogIndividalPosts = GetAllIndividualPosts(vBlogCategoryData["BlogPosts"])

            //console.log("Blog.CategoryClickHandler.vBlogIndividalPosts: ", vBlogIndividalPosts);
            setvCategoryPosts(vBlogIndividalPosts)
          }
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "Blog",
        "CategoryClickHandler",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }

    const filterByCategory = (pCategory) => {
      return vBlogCategoryData["BlogPosts"].filter(item => item.Category === pCategory);
    };

    const GetAllIndividualPosts = (pBlogCategoryPosts) =>  {
      try {
        
      
        //console.log("Blog.GetAllIndividualPosts.Start");
      
        let vBlogPostsIndividual = []

        pBlogCategoryPosts.forEach(obj => {

        if (vBlogPostsIndividual !== undefined) {

            let vBlogIndividalItemExists = vBlogPostsIndividual.filter(item => item.Title === obj.Title)

            if (vBlogIndividalItemExists.length === 0) {
              vBlogPostsIndividual.push(obj)
            }
        } else {

          vBlogPostsIndividual.push(obj)

        }

      });

      //console.log("Blog.GetAllIndividualPosts.vBlogPostsIndividual: ", vBlogPostsIndividual.length );

      return vBlogPostsIndividual
      
    } catch (error) {
      console.error("Blog.GetAllIndividualPosts.Error:  ", error); 
    }

    }

    async function MenuClickHandler(event, pvBlogEntriesViewable) {
      try {
          let vTmp = event.target.value

          setvBlogEntriesViewable(false)
          setvSideBarViewable(true)
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "Blog",
        "CategoryClickHandler",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }

    async function GetBlogPostsByFilter(event, pFilter, pCategory) {
      try {    

                //console.log(".GetBlogPostsByFilter.pFilter:  ",pFilter)
                var date = new Date();
                date.setDate(date.getDate() - 1);
                var Yesterday = date.toISOString() 
                
                date.setDate(date.getDate() - 30);
                var LastWeek = date.toISOString() 


                let vFilter = ""

                if (pFilter === "TODAYBlogPosts") {
                  //console.log("Blog.GetBlogPostsByFilter.pFilter:  ",pFilter)
                  //console.log("Blog.GetBlogPostsByFilter.LastWeek:  ",LastWeek)
                  //let filteredData = vBlogPosts.filter(blogpost => convertUTCDateTimeStringToDateTime(blogpost.CreationDateTime) > LastWeek);
                  let filteredData = vBlogPosts.filter(blogpost => blogpost.CreationDateTime > LastWeek);              
                  //console.log("Blog.GetBlogPostsByFilter.filteredData.length:  ",filteredData.length)
                  setvBlogPostsFiltered(filteredData)
                  setvPageTitle("Recent Blog Posts")
                } else if (pFilter === "CATEGORY") {
                  setvPageTitle(pCategory)
                  let filteredData = vBlogPosts.filter(blogpost => blogpost.Category === pCategory);
                  setvBlogPostsFiltered(filteredData)
                } else if (pFilter === "KEYWORD") {
                  setvPageTitle(pCategory)
                  let filteredData = vBlogPosts.filter(blogpost => blogpost.KeyWords.includes(pCategory));
                  setvBlogPostsFiltered(filteredData)
                } else if (pFilter === "SUBCATEGORY") {
                  setvPageTitle(pCategory)
                  let filteredData = vBlogPosts.filter(blogpost => blogpost.SubCategory === pCategory);
                  setvBlogPostsFiltered(filteredData)
                } else if (pFilter === "TREND") {
                  setvPageTitle(pCategory)
                  let filteredData = vBlogPosts.filter(blogpost => blogpost.Trend === pCategory);
                  setvBlogPostsFiltered(filteredData)
                }
      
              setvBlogPostFilter(vFilter)

              setvUpdateEntries(Date.now)
              setvSideBarViewable(false)

              setvBlogEntriesViewable(true)
            

      } catch (err) { 
        ErrorMessageStr = (".setblogpostFilter.Error: ", err.stack)
        console.error(ErrorMessageStr)
        ErrorMessageStr = (".setblogpostFilter.Error: ", err)
        console.error(ErrorMessageStr)
        RhyteItLogging("NA", "SBSMAdmin", "setblogpostFilter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
    }    


    useEffect(() => {


      if (vBlogSelected !== undefined) {
      GetBlogPostsByFilter("event", "TODAYBlogPosts", null)
      }

    }, [vBlogSelected]);

    async function NewsletterSubscriberField(event, data) {
      try {
    
        let vTmp = event.target.value
    
          setvNewsletterEmail(vTmp);
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "News",
        "NewsletterSubscriberField",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }

    async function SearchBlogPosts() {
      try {
        setErrorMessage("");
        
        //console.log("BlogEntries.SearchBlogPosts.vBlogSelected.id: ", vBlogSelected["id"]);
        //console.log("BlogEntries.SearchBlogPosts.vSearchTerm: ", vSearchTerm);
        let variables = {
            filter: {
              Blog: {
                eq: vBlogSelected["id"],
              },
              BlogPostString: {
                contains: vSearchTerm,
              },
            },
          };
  
        const listBlogPostsData2 = await client.graphql({
          query: listBlogPosts,
          variables: variables,
        });
  
        //console.log("BlogEntries.SearchBlogPosts.listBlogPosts.data.listBlogPosts.items.length : ", listBlogPostsData2.data.listBlogPosts.items.length );
  
        if (listBlogPostsData2.data.listBlogPosts.items.length > 0) {
          const BlogEntries1 =
            listBlogPostsData2.data.listBlogPosts.items;
            let vSortedBlogPosts = SortBlogPostsByDate(BlogEntries1)
            setvBlogPostsFiltered(vSortedBlogPosts)
 
            setvBlogPostFilter("SearchTerm")

            setvUpdateEntries(Date.now)

        } else {
          
          setvBlogPostsFiltered()
 
          setvBlogPostFilter("SearchTerm")

          setvUpdateEntries(Date.now)
        }
  
  
  
      } catch (err) {

        console.error("BlogEntries.SearchBlogPosts.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "BlogEntries",
          "SearchBlogPosts",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }    


    function SortBlogPostsByDate(pBlogPostEditionEmail) {
      pBlogPostEditionEmail.sort(GFG_sortFunction);
      return pBlogPostEditionEmail;
      //    //console.log(""JSON.stringify(pEmails));
    }



  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className={styles.Background}
    >    
      <SEO
          title="RhyteIt Blog: Strategies for Adult Content Creators to Boost Profits"
          description='Explore RhyteIt Blog for expert insights and actionable tips designed to help adult content creators maximize their profits, enhance client satisfaction, and thrive in the subscription-based landscape.'
          name='RhyteIt'
          type='blogpost' />      
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="0.01rem"
        className={styles.MainColumn}
      >
      {vBlogSelected !== undefined &&  vCellPhone === true && (
        <section className={styles.HeaderSection}>
          <Flex
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.LogoTitleRow}
            
          >
            <img className={styles.logoimg}
                  onClick={(e) => GetBlogPostsByFilter(e, "TODAYBlogPosts", null)} 
                  src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>
            <h1 className={styles.BlogTitle}>{vBlogSelected.Blog}</h1>          
            <BlogMenuIcon
              className={styles.Hamburger}
              NavBarMenuClicked={vBlogEntriesViewable}
              onClick={(e) => MenuClickHandler(e, vBlogEntriesViewable)}
              />    
              
          </Flex>   
        </section>
      )}
      {vBlogSelected !== undefined && vCellPhone === false &&    (
        <section className={styles.HeaderSection}>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.LogoTitleRow}
          >
            <img 
            onClick={(e) => GetBlogPostsByFilter(e, "TODAYBlogPosts", null)}
            className={styles.logoimg} src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>
            <h1 className={styles.BlogTitle}>{vBlogSelected.Blog}</h1>         
          </Flex>   
        </section>
      )}   
      {vBlogSelected !== undefined && vCellPhone === false && (
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          gap="0.5rem"
          className={styles.CategoryRow}
        >
          {
                    vBlogCategories !== undefined && vBlogCategories.map((vCategory) => (                      
                        (
                          <Text className={styles.TextCategory} onClick={(e) =>  GetBlogPostsByFilter(e, "CATEGORY", vCategory["Category"]) }>
                              {vCategory["Category"]} 
                          </Text>
                        )        
                      ))
                    } 
        </Flex>    
      )}   
      {vBlogSelected !== undefined && vCellPhone === false && (      
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.KeyWordRow}
        >
          <Text className={styles.TextKeyWordHeader}>Keywords:</Text>
           {
                    vKeyWords !== undefined && vKeyWords.map((vKeyWord) => (                      
                        (
                          <Text className={styles.TextKeyWord} onClick={(e) => GetBlogPostsByFilter(e, "KEYWORD", vKeyWord["KeyWord"])}>
                              {vKeyWord["KeyWord"]} 
                          </Text>
                        )        
                      ))
                    } 
        </Flex>     
      )}    
      {vBlogSelected !== undefined && vCellPhone === false && (      
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.TrendRow}
        >
          <Text className={styles.TextKeyWordHeader}>Trends:</Text>
           {
                    vTrends !== undefined && vTrends.map((vTrend) => (                      
                        (
                          <Text className={styles.TextKeyWord} onClick={(e) => GetBlogPostsByFilter(e, "TREND", vTrend["Trend"])}>
                              {vTrend["Trend"]} 
                          </Text>
                        )        
                      ))
                    } 
        </Flex>    
      )}     
      {vBlogSelected !== undefined && vCellPhone === false && (              
        <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.SubscribeRow}
                    >                  
                    <SearchField
                      placeholder="Search here..."
                      hasSearchIcon={true}
                      labelHidden={true}
                      hasSearchButton={false}
                      onClear={(e) => vClearSearchBar(e)}
                      onChange={(e) => vSearchTermChanged(e, vSearchTerm)}
                      value={vSearchTerm}
                      className={styles.SearchField}
                      ref={inputRef}
                      searchButtonRef={searchButtonRef}
                    />
                    <Button
                              className={styles.Button}
                              onClick={(e) => GetSearch(e, vSearchTerm)}
                              
                            >Search</Button> 
                  <TextField
                      className={styles.SubscribeField}
                      name="vNewsletterEmail"
                      placeholder="Type your email..."
                      defaultValue={vNewsletterEmail}
                      onChange={(e) => NewsletterSubscriberField(e, vNewsletterEmail)}
                    />                    
                    <Button
                        className={styles.Button}
                        onClick={(e) => CreateNewsletterSubscriber(e)}
                        
                      >Subscribe</Button>                             
        </Flex>  
      )}   
      <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.ButtonRow}
        >    
        <Link className={styles.Link} href="/news" isExternal={false}>
        <Button
            className={styles.Button}             
          >News</Button> 
        </Link>  
        <Link className={styles.Link} href="/Podcast" isExternal={false}>
        <Button
            className={styles.Button}             
          >Podcast</Button> 
        </Link> 
        <Link className={styles.Link} href="/AdultContentCreatorSearch" isExternal={false}>
        <Button
            className={styles.Button}             
          >Models</Button> 
        </Link> 
        <Link className={styles.Link} href="/TurboFanBoost" isExternal={false}>
        <Button
            className={styles.Button}             
          >Products</Button> 
        </Link> 
        </Flex>          
      {vBlogSelected !== undefined &&  (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexColumn}
          >      
          {vCategoryClicked === false && vCategorySelected === undefined && vBlogEntriesViewable === true && (   
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexColumnBlogEntries}
          >
            <Text className={styles.PageTitle}>
              {vPageTitle}
            </Text> 
            {vCellPhone === false && ( 
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
                className={styles.SubCategoryRow}
              >
                {  
                  vPageSubCategoryTitles !== undefined && vPageSubCategoryTitles.map((vSubCategory) => (  
                  <Text className={styles.TextSubcategory}  onClick={(e) => GetBlogPostsByFilter(e, "SUBCATEGORY", vSubCategory.SubCategory)}>
                          {vSubCategory.SubCategory}
                          </Text> )        
                  )
                }                
            </Flex>  
          )}                
            <BlogEntries Blog={vBlogSelected} 
                          key={vUpdateEntries} 
                          SearchTerm={vSearchTerm}
                          BlogPosts={vBlogPostsFiltered}
                          BlogPostFilter={vBlogPostFilter}
                          CellPhone={vCellPhone}/>

          </Flex>  
          )}       
        </Flex>   
      )}       
      {vBlogSelected !== undefined &&  vCellPhone === true && vSideBarViewable === true && (
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="center"
        wrap="wrap"
        gap="0.1rem"
        className={styles.CellPhoneCategoriesFlex}
      >
        <Text className={styles.CellPhoneCategoriesTitle}>Blog Sections</Text>
      {
          vBlogCategories !== undefined && vBlogCategories.map((vBlogCategory) => (          
            <Text className={styles.CellPhoneCategoriesTitles}  onClick={(e) =>  GetBlogPostsByFilter(e, "CATEGORY", vBlogCategory["Category"]) }>{vBlogCategory["Category"]}</Text>
          ))
        }    
      <Text className={styles.CellPhoneCategoriesTitle}>Top Trends</Text>
      {
          vTrends !== undefined && vTrends.map((vBlogTrend) => (          
            <Text className={styles.CellPhoneCategoriesTitles}  onClick={(e) =>  GetBlogPostsByFilter(e,"TREND", vBlogTrend["Trend"]) }>{vBlogTrend["Trend"]}</Text>
          ))
        }            
      </Flex> 
     )}     
    </Flex>         
    </m.div>
  );
};

export default Blog;
