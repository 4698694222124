import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listAffiliatePrograms,
  listSMMADAccounts,
  listSMMADAccountGroups,
} from "../../../graphql/queries";
import {
  createAffiliatePrograms,
  updateAffiliatePrograms
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./AffiliateProgramManager.module.css";
import AffiliateProgramTable from './AffiliateProgramTable';  

const AffiliateProgramManager = (props) => {
  AffiliateProgramManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vAffiliateProgramManager, setvAffiliateProgramManager] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/AffiliateProgramManager", Title: "AffiliateProgramManager", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);
  
  const [vAccountGroup, setvAccountGroup] = useState();
  
  const [vAccountGroupSelected, setvAccountGroupSelected] = useState({"AccountGroup":"None"});
  let [vAccountGroupList, setvAccountGroupList] = useState();

  const [vAffiliateProgramSelected, setvAffiliateProgramSelected] = useState({"Name":"None"});
  let [vAffiliateProgramList, setvAffiliateProgramList] = useState();  
  const [vAddAffiliateProgramVisible, setvAddAffiliateProgramVisible] = useState(false);

  const [vAffiliateProgramVisible, setvAffiliateProgramVisible] = useState(false);
  
  let [vNewAffiliateProgram, setvNewAffiliateProgram] = useState();


  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccountGroups();
           
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "AffiliateProgramManager",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

    if (vAccountGroupSelected.AccountGroup !== "None") {
    console.log("AffiliateProgramManager.useEffect[vAccountGroupSelected].vAccountGroupSelected:  ",vAccountGroupSelected)
  
     GetAffiliatePrograms(vAccountGroupSelected.AccountGroup);
    }
  }, [vAccountGroupSelected]);
  
  useEffect(() => {

   console.log("AffiliateProgramManager.useEffect[vAffiliateProgramSelected].vAffiliateProgramSelected:  ",vAffiliateProgramSelected)



  }, [vAffiliateProgramSelected]);

  async function GetAffiliatePrograms(pAccountGroup) {
    try {
      setErrorMessage("");
      
      console.log("AffiliateProgramManager.GetAffiliatePrograms.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: pAccountGroup,
            },
          },
        };
      } else {
        variables = {};
      }

      const listAffiliateProgramsData2 = await client.graphql({
        query: listAffiliatePrograms,
        variables: variables,
      });

      console.log("AffiliateProgramManager.GetAffiliatePrograms.listAffiliatePrograms.data.listAffiliatePrograms.items.length : ", listAffiliateProgramsData2.data.listAffiliatePrograms.items.length );

      if (listAffiliateProgramsData2.data.listAffiliatePrograms.items.length > 0) {
        const AffiliateProgramManager1 =
          listAffiliateProgramsData2.data.listAffiliatePrograms.items;
          SortAffiliateProgramsByName(AffiliateProgramManager1)
          setvAffiliateProgramList(AffiliateProgramManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "GetAffiliatePrograms",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function GetSMMADAccountGroups() {
    try {
      setErrorMessage("");
      
      console.log("AffiliateProgramManager.GetAffiliatePrograms.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            ParentAccount: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountGroupsData2 = await client.graphql({
        query: listSMMADAccountGroups,
        variables: variables,
      });

      console.log("AffiliateProgramManager.GetSMMADAccountGroups.listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length : ", listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length );

      if (listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length > 0) {
        const AffiliateProgramManager1 =
          listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items;

          setvAccountGroupList(AffiliateProgramManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "GetSMMADAccountGroups",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortAffiliateProgramsByName(pAffiliateProgram) {
    pAffiliateProgram.sort(GFG_sortFunction);
    return pAffiliateProgram;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let AffiliateProgramA = a.AffiliateProgram;
    let AffiliateProgramB = b.AffiliateProgram;
    return AffiliateProgramA > AffiliateProgramB ? 1 : -1;
  }

  async function createAffiliateProgram() {
    try {
      console.log("AffiliateProgramManagerTable.createAffiliateProgram.vNewAffiliateProgram:  ",vNewAffiliateProgram);
      console.log("AffiliateProgramManagerTable.createAffiliateProgram.Users:  ",Users);
      console.log("AffiliateProgramManagerTable.createAffiliateProgram.AccountGroup:  ",vAccountGroupSelected["AccountGroup"]);

      var vUpdateDateTimeNew = new Date().toISOString();

      var vAffiliateProgramID = vNewAffiliateProgram.replace(/\s+/g,"") +"_" + vAccountGroupSelected["AccountGroup"].replace(/\s+/g,"")

      
      console.log("AffiliateProgramManagerTable.createAffiliateProgram.vAffiliateProgramID:  ",vAffiliateProgramID);

      let vAffiliateProgramItem = {
        AffiliateProgramID: vAffiliateProgramID,
        AccountGroup: vAccountGroupSelected["AccountGroup"],
        Name: vNewAffiliateProgram,
        Email: Users,
        StripeAccountID: 0,
        ReferralCode: 0,
        TotalClicks: 0,
        TotalSignups: 0,
        TotalEarnings: 0,
        AffiliateProgramStatus: "new",
        Active: true,
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      console.log("AffiliateProgramManagerTable.createAffiliateProgram.vAffiliateProgramItem:  ",vAffiliateProgramItem);
     


      const ccreateAffiliateProgramResponse = await client.graphql({
        query: createAffiliatePrograms,
        variables: {
          input: vAffiliateProgramItem,
        },
      });
      setvAddAffiliateProgramVisible(false);
      setvNewAffiliateProgram("");
      GetAffiliatePrograms();

    } catch (err) {

      console.log("AffiliateProgramManagerTable.createAffiliateProgram.err:  ",err);
      let vErrorMessage = ""
      if (err["errors"] !== undefined) {
        vErrorMessage =  err["errors"][0]["message"]
      } else {        
        vErrorMessage =  err["errors"]
      }


      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "createAffiliateProgram",
        vErrorMessage,
        '{"ERROR": "' + vErrorMessage + '"}',
        0
      );
    }
  }


  async function AffiliateProgramSelectedAction(pAffiliateProgram) {
    try {
      console.log("AffiliateProgramManager.AffiliateProgramSelectedAction.pAffiliateProgram: ", pAffiliateProgram);
      let vSelectedAffiliateProgram = vAffiliateProgramList.filter(
        (vAffiliateProgramItem) => vAffiliateProgramItem["Name"] === pAffiliateProgram
      );

      console.log("AffiliateProgramManager.AffiliateProgramSelectedAction.vSelectedAffiliateProgram: ",vSelectedAffiliateProgram);
      console.log("AffiliateProgramManager.AffiliateProgramSelectedAction.vSelectedAffiliateProgram[0]: ",vSelectedAffiliateProgram[0]);
      setvAffiliateProgramVisible(true);
      //setvAffiliateProgramSelected(pAccount);
      setvAffiliateProgramSelected(vSelectedAffiliateProgram[0]); 
    } catch (err) {

      const errorMessage = err.message || 'Unknown error';
      const errorStack = err.stack || 'No stack trace available';


      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "AffiliateProgramSelectedAction",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
    }
  }

  async function AccountGroupSelectedAction(pAccountGroup) {
    try {
      //    console.log("AffiliateProgramManager.AffiliateProgramSelectedAction.pAccount: ", pAccount);
      let vSelectedAffiliateProgramGroup = vAccountGroupList.filter(
        (vAccountGroupItem) => vAccountGroupItem["AccountGroup"] === pAccountGroup
      );

      console.log("AffiliateProgramManager.AccountGroupSelectedAction.vSelectedAffiliateProgramGroup: ",vSelectedAffiliateProgramGroup);
      setvAffiliateProgramVisible(true);
      //setvAffiliateProgramSelected(pAccount);
      setvAccountGroupSelected(vSelectedAffiliateProgramGroup[0]);
    } catch (err) {
      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "AccountGroupSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ViewConfig(event) {
    try {
      console.log("AffiliateProgramManager.ViewConfig.event: ", event);
      console.log("AffiliateProgramManager.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
      setvAddAffiliateProgramVisible(!vAddAffiliateProgramVisible)


    } catch (err) {
      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "ViewConfig",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }
  
   function NewAffiliateProgram_handleChange(event, data) {
    try {
      setvNewAffiliateProgram(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "AffiliateProgramManager",
        "NewAffiliateProgram_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }



  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="AffiliateProgram Manager"
          SubTitle="Manage your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="AffiliateProgramManager"
        />
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
      >        
        {vAccountGroupList !== undefined &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Account Group</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
          <SelectField
            className={styles.SelectField}
            value={vAccountGroupSelected.AccountGroup}
            placeholder="Select account"
            onChange={(e) => AccountGroupSelectedAction(e.target.value)}
          >
            {vAccountGroupList !== undefined &&
              vAccountGroupList.map((AccountGroup) => (
                <option
                  key={AccountGroup.AccountGroup}
                  className={styles.SelectFieldOption}
                  value={AccountGroup.AccountGroup}
                  label={AccountGroup.AccountGroup}
                >
                  {AccountGroup.AccountGroup}
                </option>
              ))}
          </SelectField>  
          {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="nowrap"
              gap="0.25rem"
            >
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow}
              >
                <Text  className={styles.AccountGroup}>{vAccountGroupSelected.AccountGroup}</Text>  
              </Flex> 
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow}
              >
                <Text  className={styles.AccountGroupLabel}>Parent Account:</Text> 
                <Text  className={styles.AccountGroup}>{vAccountGroupSelected.ParentAccount}</Text>
                <Text  className={styles.AccountGroupLabel}>Sync On:</Text> 
                <Text  className={styles.AccountGroup}>{vAccountGroupSelected.SyncAccounts.toString()}</Text>
              </Flex> 
            </Flex>              
          )}
          </Flex>         
        </Fieldset>        
        )}   
        {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Affiliate Programs</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow}
            >
              <SelectField
                className={styles.SelectField}
                value={vAffiliateProgramSelected.Name}
                placeholder="Select AffiliateProgram"
                onChange={(e) => AffiliateProgramSelectedAction(e.target.value)}
              >
                {vAffiliateProgramList !== undefined &&
                  vAffiliateProgramList.map((AffiliateProgram) => (
                    <option
                      key={AffiliateProgram.Name}
                      className={styles.SelectFieldOption}
                      value={AffiliateProgram.Name}
                      label={AffiliateProgram.Name}
                    >
                      {AffiliateProgram.Name}
                    </option>
                  ))}
              </SelectField>
              <Button
                  className={styles.Button}
                  variation="primary"
                  onClick={(e) => ViewConfig(e)}
                >
                  Add Affiliate Program
                </Button>            
            </Flex>
            {vAddAffiliateProgramVisible === true && (
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
                className={styles.NewAffiliateProgramRow}
              >
                <TextField
                  className={styles.CampaignField}
                  name="Account"
                  placeholder="Add new AffiliateProgram"
                  defaultValue={vNewAffiliateProgram}
                  onChange={(e) => NewAffiliateProgram_handleChange(e, vNewAffiliateProgram)}
                />
                <Button
                  className={styles.Button}
                  variation="primary"
                  onClick={(e) => createAffiliateProgram(e)}
                >
                  Create Affiliate Program
                </Button>
              </Flex>
            )}
          </Flex>                  
        </Fieldset>             
        )}           
      </Flex>   
      
      {vAffiliateProgramSelected["Name"] !== "None"  &&  (
        <AffiliateProgramTable  AffiliateProgram={vAffiliateProgramSelected}/>            
      )}          
      </Flex>                   
    </m.div>
  );
};

export default AffiliateProgramManager;
