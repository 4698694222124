import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listSBSMManagers,
} from "../../../graphql/queries";
import  { createNewsletterSubscribers, createAudienceGeoInfo}  from '../../../graphql/mutations'
import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement";

import { generateClient } from "aws-amplify/api";

import {
  RhyteItLogging,
} from "../../../BusinessLogic/UserManagement";
import { motion as m } from "framer-motion";
import { useNavigate } from "react-router-dom";


import NewsEntries from './NewsEntries';
import NewsMenuIcon from './NewsMenuIcon';
import { fetchGeoLocation } from "../../../BusinessLogic/geoLocationService";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField,
  SearchField
} from "@aws-amplify/ui-react";
import styles from "./news.module.css";
import { SEO } from '../../SEO/SEO';


function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}

const News = (props) => {
  News.propTypes = {
    RedditUser: PropTypes.object,
  };

  const width = useWindowWidth();

  const client = generateClient();
  const [vNews, setvNews] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/News", Title: "News", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);


  const [vNewsSelected, setvNewsSelected] = useState();
  const [vNewsletterEmail, setvNewsletterEmail] = useState();
  const [vSearchTerm, setvSearchTerm] = useState();
  const inputRef = React.useRef(null);
  const searchButtonRef = React.useRef(null);
  const [vUpdateEntries, setvUpdateEntries] = useState();
  const [vSubscribed, setvSubscribed] = useState(false);
  
  const [vNewsCategoryData, setvNewsCategoryData] = useState(null);
  const [vNewsCategories, setvNewsCategories] = useState(null);
  const [vTrendingTopics, setvTrendingTopics] = useState(null);
  
  
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vCategoryClicked, setvCategoryClicked] = useState(false);
  const [vCategorySelected, setvCategorySelected] = useState();
  const [vCategoryPosts, setvCategoryPosts] = useState();
  const [vNewsEntriesViewable, setvNewsEntriesViewable] = useState(false);
  const [vSideBarViewable, setvSideBarViewable] = useState(false);
  const [vCellPhone, setvCellPhone] = useState(false);    

  const [vSBSMNewsFilter, setvSBSMNewsFilter] = useState();   
  const [vPageTitle, setvPageTitle] = useState();     
  const [vPageSubCategoryTitles, setvPageSubCategoryTitles] = useState();  

  const [vNewsArticles, setvNewsArticles] = useState()  
  const [vNewsArticlesFiltered, setvNewsArticlesFiltered] = useState()  

    const [location, setLocation] = useState(null);
    const [vFirstTouch, setvFirstTouch] = useState(true);  
  useEffect(() => {

    window.scrollTo(0, 0);
    getData()
    GetNews()

    if (width < 800) {
      setvCellPhone(true)
    }

    //console.log("News.useEffect[].width: ", width);

      const fetchData = async () => {
        try {
            const response = await fetch('https://s3.us-west-2.amazonaws.com/news.rhyteit.com/ArticleNewsCategoriesKeyWords.json');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();            
            setvNewsCategoryData(jsonData);

      async function getLocation() {
                const data = await fetchGeoLocation(vFirstTouch, "News");
                setLocation(data);
                setvFirstTouch(false)
              }
              getLocation();

        } catch (err) {
             const errorMessage = err.message || 'Unknown error';
            const errorStack = err.stack || 'No stack trace available';
                
                
                              RhyteItLogging(
                                  "NA",
                                  "News",
                                  "useEffect[]",
                                  errorMessage,
                                  '{"ERROR": "' + errorStack + '"}',
                                  0
                              );
        } finally {
            setLoading(false);
        }
    };

    fetchData();

  }, []);


  useEffect(() => {


    if (vNewsSelected !== undefined) {
    GetArticlesByFilter("event", "TODAYARTICLES", null)
    }

  }, [vNewsSelected]);

  useEffect(() => {


    
      //console.log("News.useEffect.[vPageTitle].vPageTitle:  ",vPageTitle)
      if (vPageTitle !== undefined) {

        CreateSubCategoryJson(vPageTitle)
        
      }

  }, [vPageTitle]);  

  useEffect(() => {
    
    //console.log("News.useEffect[vCellPhone].vCellPhone: ", vCellPhone);

    if (vCellPhone === false) {
      setvNewsEntriesViewable(true)
      setvSideBarViewable(true)
    } else {
      setvNewsEntriesViewable(true)
      setvSideBarViewable(false)
    }

  }, [vCellPhone]);

  useEffect(() => {

    //console.log("News.useEffect[vNewsEntriesViewable].vNewsEntriesViewable: ", vNewsEntriesViewable);

  }, [vNewsEntriesViewable]);

  useEffect(() => {

    if (vNewsCategoryData !== undefined && vNewsCategoryData !== null) {
      //console.log("News.useEffect[vNewsCategoryData].vNewsCategoryData[Categories]: ", vNewsCategoryData["Categories"]);

      let vSortedArticles = SortTrendingTopics(vNewsCategoryData["Articles"])
      setvNewsArticles(vSortedArticles)
      
      let vSortedNewsCategories = SortNewsCategoriesByPost(vNewsCategoryData["Categories"])
      setvNewsCategories(vSortedNewsCategories)

      let vSortedTrendingTopics = SortTrendingTopics(vNewsCategoryData["Pronouns"])
      setvTrendingTopics(vSortedTrendingTopics.slice(0, 10))
    }

  }, [vNewsCategoryData]);  

  useEffect(() => {

    window.scrollTo(0, 0);

  }, [vCategoryPosts]); 

  useEffect(() => {

    
    //console.log("News.useEffect[vPageSubCategoryTitles].vPageSubCategoryTitles:  ",vPageSubCategoryTitles)

    if (vPageSubCategoryTitles !== undefined) {

      setvUpdateEntries(Date.now)

    }

  }, [vPageSubCategoryTitles]); 
  

  const getData = async () => {

                                  

    if (vFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
           vFirstTouch = false;    
  
        let DynamoUUID =  await uuidv4()
  
        let AudienceGeoItem = {
          id: DynamoUUID,
          UserId: "NA",
          Page: "News",
          IPv4: "000.00.00.000",
          city: "NA",
          country_code:"NA",
          country_name: "NA",
          latitude: "00.000",
          longitude: "00.000",
          postal: "NA",
          state: "NA",
          CreationName:  "News",
          UpdateName: "News"
        }                      
        
        try{
  
          let createAudienceGeoInfoResponse = await client.graphql({                    
            query: createAudienceGeoInfo,
            variables:{
                input: AudienceGeoItem
              }
        });         
            
  
        } catch (err)  {
          RhyteItLogging("NA", "News", "getData",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
       }
  
    }
  
  }


  async function GetNews() {
    try {
      setErrorMessage("");
      setvCategoryClicked(false)
      setvCategorySelected()
      
      //console.log("News.GetNews.Users: ", Users);
      let variables = {
          filter: {
            id: {
              eq: "RhyteItAccountGroup2AdultContentCreatorNews",
            },
          },
        };

      const listSBSMManagersData2 = await client.graphql({
        query: listSBSMManagers,
        variables: variables,
      });

      //console.log("News.GetNews.listSBSMManagers.data.listSBSMManagers.items.length : ", listSBSMManagersData2.data.listSBSMManagers.items.length );

      if (listSBSMManagersData2.data.listSBSMManagers.items.length > 0) {
        const News1 =
          listSBSMManagersData2.data.listSBSMManagers.items;
          setvNewsSelected(News1[0])
      } 



    } catch (err) {
      RhyteItLogging(
        "NA",
        "News",
        "GetNews",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function vSearchTermChanged(event, data) {
    try {

      let vTmp = event.target.value

        setvSearchTerm(vTmp);
    
  } catch (err) {
    RhyteItLogging(
      "NA",
      "News",
      "vSearchTermChanged",
      err.toString(),
      '{"ERROR": "' + err.toString() + '"}',
      0
    );
  }
}

async function GetSearch(event, data) {
  try {

    setvCategoryClicked(false)
    setvCategorySelected()
    //console.log("News.GetSearch.event: ", event );
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "News",
    "vSearchTermChanged",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

async function vClearSearchBar(event) {
  try {

    setvCategoryClicked(false)
    
    //console.log("News.vClearSearchBar.event: ", event );
    setvSearchTerm()
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "News",
    "vSearchTermChanged",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

async function NewsletterSubscriberField(event, data) {
  try {

    let vTmp = event.target.value

      setvNewsletterEmail(vTmp);
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "News",
    "NewsletterSubscriberField",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}


const onClick = React.useCallback(() => {
  inputRef.current.focus();
  //console.log("News.onClick.inputRef.current.value: ", inputRef.current.value );
  setvSearchTerm(inputRef.current.value);
  setvUpdateEntries(Date.now());
}, []);

React.useEffect(() => {
  const searchButtonRefCurrent = searchButtonRef.current;
  if (searchButtonRef && searchButtonRefCurrent) {
    // Note: this example is contrived to demonstrate using refs.
    // Use the `onSubmit` prop on `SearchField` instead which
    // responds to input field `Enter` keypresses and Submit button clicks.
    searchButtonRefCurrent.addEventListener('click', onClick, false);
    return () => {
      searchButtonRefCurrent.removeEventListener('click', onClick, false);
    };
  }
}, [onClick]);

function convertToISO(datetimeStr) {
  // Create a new Date object from the provided string
  let date = new Date(datetimeStr.replace(' ', 'T') + 'Z'); // Replace the space with 'T' to make it compatible
  
  // Convert it to ISO string
  return date.toISOString();
}  

async function CreateNewsletterSubscriber() {
        
  
  try {           
      setErrorMessage("")
               
      var vUpdateDateTimeNew = new Date().toISOString();
  
      let vId = "TheAdultContentCreatorsEdge-RhyteItAccountGroup2-" + vNewsletterEmail

      let datetimeStr = "1999-01-01 01:00:00";
      let isoDate = convertToISO(datetimeStr);
      const vSubscriptionPreferencesJSONString = JSON.stringify({"frequency": "weekly", "content": "News"});   

      let SubscriberItem = {
        id: vId,
        Newsletter: "The Adult Content Creator's Edge",
        AccountGroup: "RhyteIt Account Group 2",
        SubscriberEmail: vNewsletterEmail,
        SubscriptionStatus: "subscribed",
        SubscribedDate: vUpdateDateTimeNew,
        SubscriberSegment: "",
        LastEmailSentDate: isoDate,
        LastEmailOpenedDate: isoDate,
        SendCount: 0,
        OpenCount:  0,
        ClickCount: 0,
        UnsubscribeReason: "",
        SubscriptionSource: "NewsPage",
        Geolocation: "Unknown",
        SubscriptionPreferences: vSubscriptionPreferencesJSONString,
        BounceReason: "",
        Active: true,
        CreationName: vNewsletterEmail,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: vNewsletterEmail,
        UpdateDateTime: vUpdateDateTimeNew,
      };


      //console.log("News.CreateNewsletterSubscriber.SubscriberItem    : ", SubscriberItem );
  
      const createNewsletterResponse = await client.graphql({
        query: createNewsletterSubscribers,
        variables: {
          input: SubscriberItem,
        },
      });

      setvSubscribed(true)
      setvNewsletterEmail("")

    } catch (err) { 
      console.error("News.CreateNewsletterSubscriber.Error:  ", err, err.stack); 
      RhyteItLogging("NA", "News", "CreateNewsletterSubscriber",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
  }        
}    

function SortNewsCategoriesByPost(pNewsCategories) {
  pNewsCategories.sort(GFG_sortFunction);
  return pNewsCategories;
  //console.log(""JSON.stringify(pEmails));
}

function GFG_sortFunction(a, b) {
  let ArticleCountA = a.ArticleCount;
  let ArticleCountB = b.ArticleCount;
  return ArticleCountA < ArticleCountB ? 1 : -1;
}

function SortTrendingTopics(pTrendingTopics) {
  pTrendingTopics.sort(GFG_sortTrendingTopics);
  return pTrendingTopics;
  //console.log(""JSON.stringify(pEmails));
}

function GFG_sortTrendingTopics(a, b) {
  let UpdateDateTimeA = a.UpdateDateTime;
  let UpdateDateTimeB = b.UpdateDateTime;
  return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
}

async function MenuClickHandler(event, pvNewsEntriesViewable) {
  try {
      let vTmp = event.target.value

      setvNewsEntriesViewable(false)
      setvSideBarViewable(true)
  
} catch (err) {
  RhyteItLogging(
    "NA",
    "News",
    "CategoryClickHandler",
    err.toString(),
    '{"ERROR": "' + err.toString() + '"}',
    0
  );
}
}

async function CreateSubCategoryJson(pCategory) {
  try {
     

      //console.log("News.CreateSubCategoryJson.pCategory:  ",pCategory)

      let vSubCategories = ""

      if (pCategory === "Headlines") {

        
        //console.log("News.CreateSubCategoryJson.Headlines:  ",pCategory)
        //console.log("News.CreateSubCategoryJson.vNewsCategoryData.SubCategories:  ",vNewsCategoryData["SubCategories"].slice(0,5))

        vSubCategories = vNewsCategoryData["SubCategories"];

      } else {

        vSubCategories = vNewsCategoryData["SubCategories"].filter(item => item.Category === pCategory); 

      }


      
      let vSortedNewsCategories = SortSubCategories(vSubCategories)
      setvPageSubCategoryTitles(vSortedNewsCategories.slice(0,5))

    
  } catch (err) {
    RhyteItLogging(
      "NA",
      "News",
      "CategoryClickHandler",
      err.toString(),
      '{"ERROR": "' + err.toString() + '"}',
      0
    );
  }
}

function SortSubCategories(pSubCategories) {
  pSubCategories.sort(GFG_sortSubCategories);
  return pSubCategories;
  //console.log(""JSON.stringify(pEmails));
}

function GFG_sortSubCategories(a, b) {
  let UpdateDateTimeA = a.UpdateDateTime;
  let UpdateDateTimeB = b.UpdateDateTime;
  return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
}
function convertUTCDateTimeStringToDateTime(utcDateTimeString) {
  return new Date(utcDateTimeString);
}

async function GetArticlesByFilter(event, pFilter, pCategory) {
  try {    

            //console.log("News.GetArticlesByFilter.pFilter:  ",pFilter)
            var date = new Date();
            date.setDate(date.getDate() - 1);
            var Yesterday = date.toISOString() 
            
            date.setDate(date.getDate() - 7);
            var LastWeek = date.toISOString() 

            //console.log("News.GetArticlesByFilter.LastWeek:  ",LastWeek)

            if (pFilter !== undefined){            
              
              let vFilter = ""

              if (pFilter === "TODAYARTICLES") {
  
                let filteredData = vNewsArticles.filter(article => convertUTCDateTimeStringToDateTime(article.CreationDateTime) > date);
                setvNewsArticlesFiltered(filteredData)
                setvPageTitle("Headlines")
              } else if (pFilter === "CATEGORY") {
                setvPageTitle(pCategory)
                let filteredData = vNewsArticles.filter(article => article.Category === pCategory);
                setvNewsArticlesFiltered(filteredData)
              } else if (pFilter === "TRENDINGTOPIC") {
                setvPageTitle(pCategory)
                let filteredData = vNewsArticles.filter(article => article.Pronouns.includes(pCategory));
                setvNewsArticlesFiltered(filteredData)
              } else if (pFilter === "SUBCATEGORY") {
                setvPageTitle(pCategory)
                let filteredData = vNewsArticles.filter(article => article.SubCategory === pCategory);
                setvNewsArticlesFiltered(filteredData)
              }
     
            setvSBSMNewsFilter(vFilter)
  
            setvUpdateEntries(Date.now)
  
            
            setvSideBarViewable(false)
  
            setvNewsEntriesViewable(true)

            }


        

  } catch (err) { 

    const errorMessage = err.message || 'Unknown error';
    const errorStack = err.stack || 'No stack trace available';
    
    RhyteItLogging(
      "NA",
      "News",
      "setArticleFilter",
      errorMessage,
      JSON.stringify({ ERROR: errorStack }),
      0
    );  

              }
}    

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
      className={styles.Background}
    >    
      <SEO
          title="RhyteIt News: Strategies for Adult Content Creators to Boost Profits"
          description='Explore RhyteIt News for expert insights and actionable tips designed to help adult content creators maximize their profits, enhance client satisfaction, and thrive in the subscription-based landscape.'
          name='RhyteIt'
          type='article' />      
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.MainColumn}
      >
    {vNewsSelected !== undefined &&  vCellPhone === true &&(
      <section className={styles.HeaderSection}>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.LogoTitleRow}
        >
          <img className={styles.logoimg}
                onClick={(e) => GetArticlesByFilter(e, "TODAYARTICLES", null)}
                 src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" alt="RhyteIt"/>
          <NewsMenuIcon
            className={styles.Hamburger}
            NavBarMenuClicked={vNewsEntriesViewable}
            onClick={(e) => MenuClickHandler(e, vNewsEntriesViewable)}
            />    
            
        </Flex>   
      </section>
    )}
    {vNewsSelected !== undefined && vCellPhone === false &&    (
      <section className={styles.HeaderSection}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.LogoTitleRow}
        >
          <img className={styles.logoimg}         
                onClick={(e) => GetArticlesByFilter(e, "TODAYARTICLES", null)}
                src="https://rhyteitartwork.s3.us-west-2.amazonaws.com/RhyteItFavicon2.svg" 
                alt="RhyteIt"/>
          {
                    vNewsCategories !== undefined &&  vNewsCategories !== null && vNewsCategories.map((vNewsCategory) => (                      
                        (
                          <Text className={styles.TextCategory} onClick={(e) =>  GetArticlesByFilter(e, "CATEGORY", vNewsCategory["Category"]) }>
                              {vNewsCategory["Category"]} 
                          </Text>
                        )        
                      ))
                    } 
        </Flex>  
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.TrendingTopicRow}
        >
          <Text className={styles.TextTopicHeader}>Trending:</Text>
           {
                    vTrendingTopics !== undefined && vTrendingTopics.map((vTrendingTopic) => (                      
                        (
                          <Text className={styles.TextTopic} onClick={(e) => GetArticlesByFilter(e, "TRENDINGTOPIC", vTrendingTopic["Pronoun"])}>
                              {vTrendingTopic["Pronoun"]} 
                          </Text>
                        )        
                      ))
                    } 
        </Flex>    
        <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.SubscribeRow}
                    >                  
                    <SearchField
                      placeholder="Search here..."
                      hasSearchIcon={true}
                      labelHidden={true}
                      hasSearchButton={false}
                      onClear={(e) => vClearSearchBar(e)}
                      onChange={(e) => vSearchTermChanged(e, vSearchTerm)}
                      onClick={(e) => GetSearch(e, vSearchTerm)}
                      value={vSearchTerm}
                      className={styles.SearchField}
                      ref={inputRef}
                      searchButtonRef={searchButtonRef}
                    />
                    <Button
                              className={styles.Button}
                              onClick={(e) => GetSearch(e, vSearchTerm)}
                              
                            >Search</Button> 
                  <TextField
                      className={styles.SubscribeField}
                      name="vNewsletterEmail"
                      placeholder="Type your email..."
                      defaultValue={vNewsletterEmail}
                      onChange={(e) => NewsletterSubscriberField(e, vNewsletterEmail)}
                    />                    
                    <Button
                        className={styles.Button}
                        onClick={(e) => CreateNewsletterSubscriber(e)}
                        
                      >Subscribe</Button>                             
        </Flex>                   
      </section>
    )}  
      <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.ButtonRow}
        >    
        <Link className={styles.Link} href="/blog" isExternal={false}>
        <Button
            className={styles.Button}             
          >Blog</Button> 
        </Link>  
        <Link className={styles.Link} href="/Podcast" isExternal={false}>
        <Button
            className={styles.Button}             
          >Podcast</Button> 
        </Link> 
        <Link className={styles.Link} href="/AdultContentCreatorSearch" isExternal={false}>
        <Button
            className={styles.Button}             
          >Models</Button> 
        </Link> 
        <Link className={styles.Link} href="/TurboFanBoost" isExternal={false}>
        <Button
            className={styles.Button}             
          >Products</Button> 
        </Link> 
        </Flex>    
    {vNewsSelected !== undefined &&  (
      <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexColumn}
        >      
        {vUpdateEntries !== undefined && vSBSMNewsFilter !== undefined &&  vCategoryClicked === false && vCategorySelected === undefined && vNewsEntriesViewable === true && (   
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexColumnNewsEntries}
        >
            <Text className={styles.PageTitle}>
              {vPageTitle}
            </Text> 
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              className={styles.SubCategoryRow}
            >
              {  
                vPageSubCategoryTitles !== undefined && vPageSubCategoryTitles.map((vSubCategory) => (  
                <Text className={styles.TextSubcategory}  onClick={(e) => GetArticlesByFilter(e, "SUBCATEGORY", vSubCategory.SubCategory)}>
                        {vSubCategory.SubCategory}
                        </Text> )        
                )
              }                
            </Flex>          
          <NewsEntries News={vNewsSelected} key={vUpdateEntries}
                          CellPhone={vCellPhone} 
                          PageTitle={vPageTitle} 
                          NewsArticles={vNewsArticlesFiltered} 
                          PageSubCategoryTitles={vPageSubCategoryTitles} 
                          SBSMNewsFilter={vSBSMNewsFilter} 
                          SearchTerm={vSearchTerm}/>
        </Flex>  
        )}       
      </Flex>   
    )}   
    {vNewsSelected !== undefined &&  vCellPhone === true && vSideBarViewable === true && (
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="center"
        wrap="wrap"
        gap="0.1rem"
        className={styles.CellPhoneCategoriesFlex}
      >
        <Text className={styles.CellPhoneCategoriesTitle}>Blog Sections</Text>
      {
          vNewsCategories !== undefined && vNewsCategories.map((vBlogCategory) => (          
            <Text className={styles.CellPhoneCategoriesTitles}  onClick={(e) =>  GetArticlesByFilter(e, "CATEGORY", vBlogCategory["Category"]) }>{vBlogCategory["Category"]}</Text>
          ))
        }    
      <Text className={styles.CellPhoneCategoriesTitle}>Top Trends</Text>
      {
          vTrendingTopics !== undefined && vTrendingTopics.map((vBlogTrend) => (          
            <Text className={styles.CellPhoneCategoriesTitles}  onClick={(e) =>  GetArticlesByFilter(e,"TRENDINGTOPIC", vBlogTrend["Pronoun"]) }>{vBlogTrend["Pronoun"]}</Text>
          ))
        }            
      </Flex> 
    )}           
    </Flex>         
    </m.div>
  );
};

export default News;
