
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './LandingPageV4.module.css';
import { SEO } from '../SEO/SEO';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

import { createAudienceGeoInfo } from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import { uuidv4 } from "../../BusinessLogic/UtilitiesManagement";
import Background from "../Experiments/Background";
import HeaderMenu from "../HeaderMenu/LandingPageNavBar";
import { motion as m } from "framer-motion";
import Footer from '../RhyteItComponents/Footer/Footer';
import {
    Button,
    Flex,
    Text
} from '@aws-amplify/ui-react';
import './LandingPageV4.css';
import DigitalMarketPlaceComponent from "../DigitalMarketPlace/DigitalMarketPlaceComponent";
import { UserContext, CognitoGroupContext } from '../../BusinessLogic/Hooks/UserContext';
import { GetGroupRoleAccess, RhyteItLogging } from '../../BusinessLogic/UserManagement';
import { fetchGeoLocation } from "../../BusinessLogic/geoLocationService";



const LandingPageV4 = (props) => {

    LandingPageV4.propTypes = {
        UserId: PropTypes.string,
        UserName: PropTypes.string
    };

    let ErrorMessageStr = ""
    const { Users, setUsers } = useContext(UserContext);
    const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);
    const [location, setLocation] = useState(null);
    const [vFirstTouch, setvFirstTouch] = useState(true);

    useEffect(() => {
        (async function () {
            try {

                async function getLocation() {
                    const data = await fetchGeoLocation(vFirstTouch, "LandingPageV4");
                    setLocation(data);
                    setvFirstTouch(false)
                }
                getLocation();


            } catch (err) {

                const errorMessage = err.message || 'Unknown error';
                const errorStack = err.stack || 'No stack trace available';


                RhyteItLogging(
                    "NA",
                    "LandingPageV4",
                    "useEffect[]",
                    errorMessage,
                    '{"ERROR": "' + errorStack + '"}',
                    0
                );
            }
        }
        )();

    }, [])


    useEffect(() => {


    }, [CognitoGroup])


    return (

        <m.div
            key="LandingPageV4"
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ duration: 0.75, ease: "easeOut" }}
            exit={{ opacity: 1 }}
            className={styles.LandingPage}

        >
            <SEO
                title="Automate your marketing"
                description='RhyteIt will help you grow your fan base'
                name='RhyteIt'
                type='article' />
            <Background className={styles.Background}></Background>
            <Flex
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                wrap="wrap"
                className={styles.MasterContainer}
            >
                <HeaderMenu className={styles.HeaderMenu} pageSource="LandingPage" />
                <Text className={styles.TextBackGround}>
                    <Text className={styles.h1Blue}>
                        STAND OUT
                    </Text>
                    <Text className={styles.h1Orange}>
                        You give us stuff to post
                    </Text>
                    <Text className={styles.h1Turq}>
                        We post it everywhere for you
                    </Text>
                    <Text className={styles.h1Purple}>
                        So you can spend more time getting rich
                    </Text>
                </Text>

                <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    gap="3rem"
                    className={styles.ButtonRow}
                >
                    <div className={styles.Button}>
                        <Button className={styles.metallicbutton}>
                            <Link className={styles.ApplicationLink} to="/TurboFanBoost">
                                Just sign up with us, send us your content, and we'll start posting it for you right away!
                            </Link>
                        </Button>
                    </div>
                </Flex>
                <Text className={styles.NegativeText}>
                    <Text className={styles.h1Blue}>
                        Don't go back to a 9/5 job
                    </Text>
                </Text>
                <Footer />
            </Flex>
        </m.div>


    );
}

export default LandingPageV4