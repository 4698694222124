
import {RhyteItLogging} from './UserManagement';
import {uuidv4} from "./UtilitiesManagement";
import { generateClient } from 'aws-amplify/api';
import {createAudienceGeoInfo}  from '../graphql/mutations'

export async function fetchGeoLocation(pFirstTouch, pPage) {

    const client = generateClient();

    try {

    if (pFirstTouch === true && process.env.REACT_APP_ENV === "PRODUCTION") {
        //console.log("LandingPageV4.handleMouseOver.FirstTouch: ", FirstTouch);

            const response = await fetch("https://api.ipgeolocation.io/ipgeo?apiKey=e3799c9af1204482a813f785fb5c5390");
            if (!response.ok) {
                throw new Error("Failed to fetch geolocation data");
            }
            const data = await response.json();


                if (data["ip"] !== "73.255.98.62") {
                console.log("fetchGeoLocation.data:  ",data);
                console.log("fetchGeoLocation.data:  ",data["city"]);
        
                let DynamoUUID =  await uuidv4()
        
                let AudienceGeoItem = {
                id: DynamoUUID,
                UserId: "NA",
                Page: pPage,
                IPv4: data["ip"],
                city: data["city"],
                country_code: data["country_code2"],
                country_name: data["country_name"],
                latitude: data["latitude"],
                longitude:data["longitude"],
                postal:data["zipcode"],
                state: data["country"],
                CreationName:  "AuthenticatorRhyteIt",
                UpdateName: "AuthenticatorRhyteIt"
                }                      
                

                let createAudienceGeoInfoResponse = await client.graphql({                    
                            query: createAudienceGeoInfo,
                            variables:{
                                input: AudienceGeoItem
                            }
                        });         
                    
        
                        return data;

                    }

                }

        } catch (err)  {

                const errorMessage = err.message || err.toString();
                const errorStack = err.stack || 'No stack trace available';


                RhyteItLogging(
                    "NA",
                    "geoLocationService",
                    "geoLocationService",
                    errorMessage,
                    '{"ERROR": "' + errorStack + '"}',
                    0
                );
          
          
          return null;
    }
  }