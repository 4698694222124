import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,
  Button,
  Image,
} from "@aws-amplify/ui-react";

import {} from "../../graphql/queries";
import {
  deleteRhyteItvRhyteItLogEntrys,
  updateRhyteItLog,
} from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import { Link } from "react-router-dom";

import styles from "./RhyteItLogMetricRow.module.css";
import { UserContext } from "../../BusinessLogic/Hooks/UserContext";
import { RhyteItLogging } from "../../BusinessLogic/UserManagement";
import RhyteItLogRow from "./RhyteItLogRow";


const RhyteItLogMetricRow = (props) => {
  RhyteItLogMetricRow.propTypes = {
    RhyteItLogEntry: PropTypes.array,
    RhyteItLogs: PropTypes.array,
  };

  const client = generateClient();

  const { Users, setUsers } = useContext(UserContext);
  const [ErrorMessage, setErrorMessage] = useState("");
  let ErrorMessageStr = "";
  let debugConsoleOutPut = true;

  const [message, setMessage] = useState("");

  const [vRhyteItLogEntry, setvRhyteItLogEntry] = useState(
    props.RhyteItLogEntry
  );
  const [vRhyteItLogs, setvRhyteItLogs] = useState(props.RhyteItLogs);
  const [vRhyteItLogsFiltered, setvRhyteItLogsFiltered] = useState();
  const [vRowClicked, setvRowClicked] = useState(false);
  

  useEffect(() => {
    // console.log("RhyteItLogMetricRow.useEffect[].props.vRhyteItLogEntry:  ",props.vRhyteItLogEntry)
  }, []);

  useEffect(() => {
    console.log(
      "RhyteItLogMetricRow.useEffect[vRhyteItLogEntry].vRhyteItLogEntry:  ",
      vRhyteItLogEntry
    );
  }, [vRhyteItLogEntry]);

  const handleRowClick = (logEntry) => {
    try {

    if (vRowClicked === false) {

      // Function to handle row click and filter logs
      const filteredLogs = vRhyteItLogs.filter(log =>
            log.Environment === logEntry.Environment &&
            log.Page === logEntry.Page &&
            log.Function === logEntry.Function &&
            log.ErrorMessage === logEntry.ErrorMessage
        );
        setvRhyteItLogsFiltered(filteredLogs);

        setvRowClicked(true)

    } else {

      setvRhyteItLogsFiltered();

      setvRowClicked(false)

    }

   

    } catch (err) {
      let errorMessage = null;
      let errorStack = null;

      if ("data" in err && "errors" in err) {
        errorMessage = err.errors[0]["errorType"] || err.toString;
        errorStack = err.errors[0]["message"] || "No stack trace available";
      } else {
        errorMessage = err.message || err.toString;
        errorStack = err.stack || "No stack trace available";
      }

      RhyteItLogging(
        Users,
        "RhyteItLogMetricRow",
        "handleRowClick",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
    }
  }

  return (
    <TableBody>
      {vRhyteItLogEntry !== undefined && (
        <TableRow
          className={
            vRhyteItLogEntry.Environment === "PRODUCTION"
              ? styles.TableRowProduction
              : vRhyteItLogEntry.Environment === "TEST"
              ? styles.TableRowTest
              : styles.TableRowTest
          }
          key={vRhyteItLogEntry.id}
          onClick={() => handleRowClick(vRhyteItLogEntry)} 
        >
          <TableCell className={styles.TableRowHeadingData}>
            <Text className={styles.TextField}>
              {vRhyteItLogEntry.Environment}
            </Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>{vRhyteItLogEntry.ComponentType}</Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>{vRhyteItLogEntry.Component}</Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>
              {vRhyteItLogEntry.Function}
            </Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>{vRhyteItLogEntry.ErrorType}</Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>
              {vRhyteItLogEntry.ErrorMessage}
            </Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>{vRhyteItLogEntry.Count}</Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextFieldRecieved}>
              {new Date(vRhyteItLogEntry.LastRecorded).toLocaleString()}
            </Text>
          </TableCell>
          <TableCell as="td" className={styles.RhyteItMenuCell}>
            <Button loadingText="" ariaLabel="" className={styles.RowButtons}>
              D
            </Button>
          </TableCell>
        </TableRow>
      )}
      {vRowClicked === true  &&
              vRhyteItLogsFiltered !== undefined &&
              vRhyteItLogsFiltered.map((vRhyteItLogsFilteredRow) => ((
                <TableRow key={`child-${vRhyteItLogsFilteredRow.id}`} className={styles.ChildRow}>
              <TableCell colSpan={7} className={styles.ExpandedRow}>

                <RhyteItLogRow RhyteItLogEntry={vRhyteItLogsFilteredRow} />
            </TableCell>
            </TableRow>

              ))
      )}
    </TableBody>
  );
};

export default RhyteItLogMetricRow;
