
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Flex,
  Button,
  Text
  } from '@aws-amplify/ui-react'; 
import RhyteItLogMetricRow from './RhyteItLogMetricRow';  
import styles from './RhyteItLogMetricTable.module.css';
import {
  
  RhyteItLogging,
} from "../../BusinessLogic/UserManagement";

import {
  deleteRhyteItLog
} from "../../graphql/mutations";
import { generateClient } from "aws-amplify/api";


const RhyteItLogMetricTable = (props) => {       

  RhyteItLogMetricTable.propTypes = {
      RhyteItLogs: PropTypes.array,
      Users:  PropTypes.string,
      };

    let debugConsoleOutPut = true


    const client = generateClient();
    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [RhyteItLogs, setRhyteItLogs] = useState(props.RhyteItLogs)
    const [vUser, setvUser] = useState(props.Users)
    const [ShowTable, setShowTable] = useState(false);
    const [vLogMetrics, setvLogMetrics] = useState();
    const [vDeletingRecords, setvDeletingRecords] = useState(false);
    

    useEffect(() => {

        //console.log("RhyteItLogMetricTable.useEffect[].props.RhyteItLogs:  ",props.RhyteItLogs) 
        //console.log("RhyteItLogMetricTable.useEffect[].Object.keys(RhyteItLogs).length:  ",Object.keys(RhyteItLogs).length)
       // //console.log("RhyteItLogMetricTable.useEffect[].Object.Array.isArray(RhyteItLogs):  ",Array.isArray(RhyteItLogs))
         

        if (Object.keys(RhyteItLogs).length > 0) {
          setShowTable(true)
        }

      },[])  

      useEffect(() => {

        //console.log("RhyteItLogMetricTable.useEffect[RhyteItLogs].RhyteItLogs.length:  ",RhyteItLogs.length) 
        //console.log("RhyteItLogMetricTable.useEffect[RhyteItLogs].RhyteItLogs.First10:  ",RhyteItLogs.slice(0, 10)) 
        processLogs(RhyteItLogs)
  
      },[RhyteItLogs])        

      useEffect(() => {

        if (vLogMetrics !== undefined) {

        //console.log("RhyteItLogMetricTable.useEffect[vLogMetrics].vLogMetrics.length:  ",vLogMetrics.length) 
        //console.log("RhyteItLogMetricTable.useEffect[vLogMetrics].vLogMetrics:  ",vLogMetrics)

        }
  
      },[vLogMetrics])     

      function processLogs(logArray) {
        // Create a map to group logs by unique keys
        const logMap = new Map();
      
        logArray.forEach(log => {
          // Generate a unique key based on the combination of relevant fields
          const key = `${log.Environment}|${log.ipaddress}|${log.User}|${log.Page}|${log.Function}|${log.ErrorMessage}`;
      
          if (!logMap.has(key)) {
            // If the key doesn't exist, create a new entry
            logMap.set(key, {
              Environment: log.Environment,
              ComponentType: log.ComponentType,
              Component: log.Component,
              Function: log.Function,
              ErrorType: log.ErrorType,
              ErrorMessage: log.ErrorMessage,
              Count: 1,
              LastRecorded: log.CreationDateTime,
            });
          } else {
            // If the key exists, update the count and the latest CreationDateTime
            const existingLog = logMap.get(key);
            existingLog.Count += 1;
            if (new Date(log.CreationDateTime) > new Date(existingLog.LastRecorded)) {
              existingLog.LastRecorded = log.CreationDateTime;
            }
          }
        });
      
        // Convert the map values back into an array
        setvLogMetrics(Array.from(logMap.values()))
      }

 function DeleteAllFilteredRows() {
    try {

        setvDeletingRecords(true)
         //console.log("DeleteAllFilteredRows.Users:   ",vUser    ) 

          if (vUser === "edwardhawke@rhyteit.com") {
        
            RhyteItLogs.forEach(item => 
              {   
                LogDeleteRow(item) 

              })
             
        } 

        
        setvDeletingRecords(false)
    
        } catch (err) { 

          const errorMessage = err.message || 'Unknown error';
          const errorStack = err.stack || 'No stack trace available';
    
          
          //console.log("RhyteItLogMetricTable.DeleteAllFilteredRows.err.errorMessage: ", errorMessage);
    
          RhyteItLogging(
            vUser,
            "RhyteItLogMetricTable",
            "DeleteAllFilteredRows",
            errorMessage,
            JSON.stringify({ ERROR: errorStack }),
            0
          );     
      }               
    } 

  function LogDeleteRow(pLogEntry) {
    try {

         //console.log("LogDeleteRow.pLogEntry:  ",pLogEntry    ) 

          if (vUser === "edwardhawke@rhyteit.com") {
        
              var today = new Date();

                const vVariables = {
                  id: pLogEntry.id,
                  _version: pLogEntry._version
                }
                


                const ReturnMessage = client.graphql({
                  query: deleteRhyteItLog,
                  variables: {
                    input: vVariables
                  }
                });  

                
        } 
    
        } catch (err) { 

          const errorMessage = err.message || 'Unknown error';
          const errorStack = err.stack || 'No stack trace available';
    
          
          //console.log("RhyteItLog.LogDeleteRow.err.errorMessage: ", errorMessage);
    
          RhyteItLogging(
            vUser,
            "RhyteItLog",
            "LogDeleteRow",
            errorMessage,
            JSON.stringify({ ERROR: errorStack }),
            0
          );     
      }               
    }       

  return ( 
    <div>
       
       {
         RhyteItLogs.length !== undefined && RhyteItLogs.length > 0 &&  (
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
          <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >           
                 <Button
                      loadingText=""
                      ariaLabel=""
                      title="Delete Records"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => DeleteAllFilteredRows(e)}
                      
                    >
                    Delete
                </Button>      
                {
                  vDeletingRecords === true &&  (                
                  <Text  className={styles.AccountGroup}>..Deleting Records</Text>    
                )}             
          </Flex>
          {
            vDeletingRecords === false &&  (   
            <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="1rem"
                className={styles.FlexRow}
              >            
                                      
              
              <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
              <TableHead>          
                    <TableRow className={styles.TableHeaderRow}>
                      <TableCell className={styles.TableHeaderRowCells} >Environment</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Component Type</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Component</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Function</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Error Type</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Message</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Count</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} >Last Date</TableCell> 
                      <TableCell className={styles.TableHeaderRowCells} colSpan="2" >Actions</TableCell> 
                    </TableRow>                                      
                  </TableHead>  
                  {
                        ShowTable && vLogMetrics.length > 0 && vLogMetrics.map((RhyteItLogEntry) => (                      
                          (<RhyteItLogMetricRow   key={RhyteItLogEntry.id}  RhyteItLogEntry={RhyteItLogEntry} RhyteItLogs={RhyteItLogs}/>)        
                          ))
                      } 
                
              </Table>
            </Flex>       
          )}                    
        </Flex>
        )}    

      </div> 
    
  )

}
export default RhyteItLogMetricTable;


