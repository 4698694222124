
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Text,
  TableBody,
  TextField,  
  Button,
  Image
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../graphql/queries'
  import  { deleteRhyteItvRhyteItLogEntrys, updateRhyteItLog}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";

  import styles from './RhyteItLogRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';  

const RhyteItLogRow = (props) => {       

  RhyteItLogRow.propTypes = {
    RhyteItLogEntry: PropTypes.array
      };

    const client = generateClient();
  
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true

    const [message, setMessage] = useState('');

    const [vRhyteItLogEntry, setvRhyteItLogEntry] = useState(props.RhyteItLogEntry)      

    useEffect(() => {

       // console.log("RhyteItLogRow.useEffect[].props.vRhyteItLogEntry:  ",props.vRhyteItLogEntry) 
      
      },[])  

      useEffect(() => {

        console.log("RhyteItLogRow.useEffect[vRhyteItLogEntry].vRhyteItLogEntry:  ",vRhyteItLogEntry) 
       
       },[vRhyteItLogEntry])        





  return (
      <TableBody>   
      { vRhyteItLogEntry !== undefined &&  (        
          <TableRow  className={vRhyteItLogEntry.Environment === "PRODUCTION" ?  styles.TableRowProduction
                                  : vRhyteItLogEntry.Environment === "TEST" ?  styles.TableRowTest
                                  : styles.TableRowTest} key={vRhyteItLogEntry.id}>     

          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>
              {vRhyteItLogEntry.ipaddress}
            </Text>
          </TableCell>
          <TableCell className={styles.TableRowData}>
            <Text className={styles.TextField}>{vRhyteItLogEntry.User}</Text>
          </TableCell>      
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextField}>
                                  {vRhyteItLogEntry.ErrorJSON}
                </Text>  
            </TableCell>     
            <TableCell className={styles.TableRowData}>   
                <Text  className={styles.TextFieldRecieved}>
                                  {new Date(vRhyteItLogEntry.CreationDateTime).toLocaleString()}
                </Text>  
            </TableCell>       
            <TableCell as="td"  className={styles.RhyteItMenuCell}>                
            </TableCell>   
            <TableCell as="td"  className={styles.RhyteItMenuCell}> 
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.RowButtons}
                  >
                  D
                  </Button>                 
            </TableCell>                                                        
          </TableRow> )
              }     
      {
        ErrorMessage.length > 0 &&             
          <TableRow>              
            <TableCell 
              colSpan="7">   
              <Text className={styles.ErrorMessageRow}>  
              {ErrorMessage}
              </Text> 
            </TableCell>               
          </TableRow>  
      }                        
      </TableBody>
    
  )
}

export default RhyteItLogRow;

