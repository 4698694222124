import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  TextField,
  Flex,
} from "@aws-amplify/ui-react";
import NewsletterPublisherRow from "./NewsletterPublisherRow";
import { generateClient } from "aws-amplify/api";
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from "./NewsletterPublisherTable.module.css";
import { RhyteItLogV2 } from "../../../BusinessLogic/UserManagement";
import {
  listNewsletterEditionTrackings,
  listNewsletterEditions,
  listNewsletterSubscribers,
} from "../../../graphql/queries";
import {
  createNewsletterEditionTracking,
  deleteNewsletterEditionTracking,
} from "../../../graphql/mutations";

const NewsletterPublisherTable = (props) => {
  NewsletterPublisherTable.propTypes = {
    Newsletter: PropTypes.object,
    Users: PropTypes.string,
  };

  let debugConsoleOutPut = true;

  const [vNewsletter, setvNewsletter] = useState(props.Newsletter);
  const [Users, setUsers] = useState(props.Users);
  const [vSubscribers, setvSubscribers] = useState();
  const [vSubscribersOriginal, setvSubscribersOriginal] = useState();
  const [ShowTable, setShowTable] = useState(false);

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const mainControls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);
  const client = generateClient();

  const [vUpdateKey, setvUpdateKey] = useState();
  const [vSubscribersRefresh, setvSubscribersRefresh] = useState();
  let [vNewSubscriber, setvNewSubscriber] = useState();
  const [vAddSubscribersVisible, setvAddSubscribersVisible] = useState(false);
  const [updatedKey, setupdatedKey] = useState();
  const [vNewsletterEditionSelected, setvNewsletterEditionSelected] = useState({
    Edition: "None",
  });
  let [vNewsletterEditionList, setvNewsletterEditionList] = useState();
  const [vNewsletterEditionVisible, setvNewsletterEditionVisible] =
    useState(false);
  const [vNewsletterEditions, setvNewsletterEditions] = useState();
  const [vNewsletterSubscribers, setvNewsletterSubscribers] = useState();

  const [vContentHTML, setvContentHTML] = useState("");
  const [vTemplateJSON, setvTemplateJSON] = useState();
  const [vContentJSON, setvContentJSON] = useState();

  const [vUnsent, setvUnsent] = useState(0);
  const [vBounced, setvBounced] = useState(0);
  const [vOpened, setvOpened] = useState(0);
  const [vUnopened, setvUnopened] = useState(0);
  const [vSent, setvSent] = useState(0);

  const [vS3Key, setvS3Key] = useState("");
  const [vEmailId, setvEmailId] = useState("");

  const [nextToken, setnextToken] = useState(undefined);
  const [IsLoading, setIsLoading] = useState(false);
  const [vSubscribersReturned, setvSubscribersReturned] = useState();
  const [vTestEmail, setvTestEmail] = useState();

  
  useEffect(() => {}, []);

  useEffect(() => {
    //console.log("Hero.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  useEffect(() => {
    if (vSubscribers !== undefined) {
      if (vSubscribers.length > 0) {
      }
    }
  }, [vSubscribers]);

  useEffect(() => {
    //console.log("NewsletterPublisherTable.useEffect[vNewsletter].vNewsletter:  ",vNewsletter)

    if (vNewsletter !== undefined) {
      //console.log("NewsletterPublisherTable.useEffect[vNewsletter].vNewsletter2:  ",vNewsletter)

      const vTemplateJSONTmp = JSON.parse(vNewsletter["TemplateJSON"]);
      setvTemplateJSON(vTemplateJSONTmp);

      GetNewsletterEditions();
      //GetSubscribers();
      //GetNewsletterTrackingTable();
    }
  }, [vNewsletter]);

  useEffect(() => {
    //console.log("NewsletterPublisherTable.useEffect[vSubscribers].vSubscribers:  ",vSubscribers)
  }, [vSubscribers]);

  useEffect(() => {
    //console.log("NewsletterPublisherTable.useEffect[vNewsletterSubscribers].vNewsletterSubscribers:  ",vNewsletterSubscribers)
  }, [vNewsletterSubscribers]);

  useEffect(() => {
    //console.log("NewsletterPublisherTable.useEffect[vNewsletterEditionSelected].vNewsletterEditionSelected[Edition]:  ",vNewsletterEditionSelected["Edition"])

    if (vNewsletterEditionSelected["Edition"] !== "None") {
      GetNewsletterTrackingTable();
      CreateMetaData();
      GetSubscribers();
      //console.log("NewsletterPublisherTable.useEffect[vNewsletterEditionSelected].vNewsletterEditionSelected[EmailHTML]:  ",vNewsletterEditionSelected["EmailHTML"])

      setvContentHTML(vNewsletterEditionSelected["EmailHTML"]);
    }
  }, [vNewsletterEditionSelected]);

  useEffect(() => {
    const fetchAllNewsletterEditionTrackings = async () => {
        try {
            setIsLoading(true);

            let vAllSubscribers = vSubscribers ? [...vSubscribers] : [];  // Ensure it's an array
            let vNextToken = nextToken;

            //console.log("Starting fetch. Current subscribers:", vAllSubscribers.length);
            
            while (vNextToken) {
                //console.log("Fetching batch with nextToken:", vNextToken);

                let variables = {
                    nextToken: vNextToken,
                    filter: {
                        AccountGroup: { eq: vNewsletterEditionSelected["AccountGroup"] },
                        Newsletter: { eq: vNewsletterEditionSelected["Newsletter"] },
                        Edition: { eq: vNewsletterEditionSelected["Edition"] },
                    },
                };

                const response = await client.graphql({
                    query: listNewsletterEditionTrackings,
                    variables: variables,
                });

                let newEntries = response.data.listNewsletterEditionTrackings.items || [];
                vNextToken = response.data.listNewsletterEditionTrackings.nextToken || null;

                console.log(`Fetched ${newEntries.length} new entries.`);

                // Add new items while ensuring no duplicates
                newEntries.forEach((item) => {
                    if (!vAllSubscribers.some((existingItem) => existingItem.id === item.id)) {
                        vAllSubscribers.push(item);
                    }
                });

                //console.log("Total subscribers collected so far:", vAllSubscribers.length);
            }

            // Final state update when all data is fetched
            setvSubscribers(vAllSubscribers);
            setnextToken(null);
            setIsLoading(false);

            //console.log("Final list updated. Total subscribers:", vAllSubscribers.length);
        } catch (err) {
            console.error("Error in fetchAllNewsletterEditionTrackings:", err);
            setIsLoading(false);
        }
    };

    if (nextToken !== null && nextToken !== undefined) {
        fetchAllNewsletterEditionTrackings();
    }
}, [nextToken]);

  useEffect(() => {
    if (
      vSubscribers !== undefined &&
      IsLoading === false &&
      nextToken === undefined
    ) {
      //console.log("NewsletterPublisherTable.useEffect[IsLoading].Done");

      let vSubscribersSorted = SortPublishedByUpdateDateTime(vSubscribers);
      setvSubscribers(vSubscribersSorted);
      setvSubscribersOriginal(vSubscribersSorted);
      setvSubscribersReturned(
        vSubscribers === undefined ? 0 : vSubscribers.length
      );
      setShowTable(true);
      setvUpdateKey(Date.now());
      setvSubscribersRefresh(Date.now);
    }
  }, [IsLoading]);

  useEffect(() => {
    if (
      vSubscribers !== undefined &&
      IsLoading === false &&
      nextToken === undefined &&
      vSubscribersOriginal !== undefined
    ) {
      CalculateEditionStats();
    }
  }, [vSubscribersOriginal]);

  async function CreateMetaData() {
    try {
      setErrorMessage("");

      var vUpdateDateTime = new Date().toISOString();

      let vId = (
        vNewsletter["Newsletter"] +
        "-" +
        vNewsletter["AccountGroup"] +
        "-" +
        vNewsletterEditionSelected["Edition"] +
        "-" +
        Users +
        "-" +
        vUpdateDateTime
      )
        .replace(/["']/g, "")
        .replace(/\s+/g, "")
        .replace(/[^\w]/g, "");
      let vS3Key =
        "/" +
        (
          vNewsletter["Newsletter"] +
          "-" +
          vNewsletter["AccountGroup"] +
          "-" +
          vNewsletterEditionSelected["Edition"] +
          "-" +
          Users
        )
          .replace(/["']/g, "")
          .replace(/\s+/g, "")
          .replace(/[^\w]/g, "") +
        "/" +
        vId +
        ".html";

      setvEmailId(vId);
      setvS3Key(vS3Key);
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "CreateMetaData", err);
    }
  }

  async function GetNewsletterEditions() {
    try {
      setErrorMessage("");

      //console.log("NewsletterEditorTable.GetNewsletterEditions.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: vNewsletter["AccountGroup"],
            },
            Newsletter: {
              eq: vNewsletter["Newsletter"],
            },
          },
        };
      } else {
        variables = {};
      }

      const listNewsletterEditionsData2 = await client.graphql({
        query: listNewsletterEditions,
        variables: variables,
      });

      //console.log("NewsletterEditorTable.GetNewsletterEditions.listNewsletterEditions.data.listNewsletterEditions.items.length : ", listNewsletterEditionsData2.data.listNewsletterEditions.items.length );

      if (
        listNewsletterEditionsData2.data.listNewsletterEditions.items.length > 0
      ) {
        const NewsletterEditorTable1 =
          listNewsletterEditionsData2.data.listNewsletterEditions.items;
        let vSortedNewsletterEditions = SortNewsletterEditionsByName(
          NewsletterEditorTable1
        );
        setvNewsletterEditions(vSortedNewsletterEditions);
        setvNewsletterEditionList(vSortedNewsletterEditions);
      }
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "GetNewsletterEditions",
        err
      );
    }
  }

  function SortNewsletterEditionsByName(pNewsletterEditionEmail) {
    pNewsletterEditionEmail.sort(GFG_sortFunction);
    return pNewsletterEditionEmail;
    //    //console.log(""JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let NewsletterEditionA = a.Edition;
    let NewsletterEditionB = b.Edition;
    return NewsletterEditionA > NewsletterEditionB ? 1 : -1;
  }

  async function GetNewsletterTrackingTable() {
    try {
      setErrorMessage("");

      setvSubscribers();
      setnextToken();
      setIsLoading(false);
      //console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.vNewsletterEditionSelected.AccountGroup: ", vNewsletterEditionSelected["AccountGroup"]);
      //console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.vNewsletterEditionSelected.Newsletter: ", vNewsletterEditionSelected["Newsletter"]);
      //console.log("NewsletterPublisherTable.GetNewsletterTrackingTable.vNewsletterEditionSelected.Edition: ", vNewsletterEditionSelected["Edition"]);
      let variables;
      variables = {
        filter: {
          AccountGroup: {
            eq: vNewsletterEditionSelected["AccountGroup"],
          },
          Newsletter: {
            eq: vNewsletterEditionSelected["Newsletter"],
          },
          Edition: {
            eq: vNewsletterEditionSelected["Edition"],
          },
        },
      };

      const listNewsletterEditionTrackingsData2 = await client.graphql({
        query: listNewsletterEditionTrackings,
        variables: variables,
      });

      console.log(
        "NewsletterPublisherTable.GetNewsletterTrackingTable.listNewsletterEditionTrackings.items.length : ",
        listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings
          .items.length
      );

      if (
        listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings
          .items.length > 0
      ) {
        const NewsletterEditionTrackingsTable1 =
          listNewsletterEditionTrackingsData2.data
            .listNewsletterEditionTrackings.items;
        setvSubscribers(NewsletterEditionTrackingsTable1);
        setvSubscribersOriginal(NewsletterEditionTrackingsTable1);
      }

      console.log(
        "GetNewsletterTrackingTable.listNewsletterEditionTrackings.nextToken: ",
        listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.nextToken.substring(
          0,
          4
        )
      );

      if (
        listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings
          .nextToken !== null
      ) {
        setnextToken(
          listNewsletterEditionTrackingsData2.data
            .listNewsletterEditionTrackings.nextToken
        );
        setIsLoading(true);
      } else {
        //console.log("listNewsletterEditionTrackingsData2.data.listNewsletterEditionTrackings.setIsLoading(false)");

        setIsLoading(false);
      }
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "GetNewsletterTrackingTable",
        err
      );
    }
  }

  function SortPublishedByUpdateDateTime(pSubscriberEmail) {
    pSubscriberEmail.sort(UpdateDateTime_sortFunction);
    return pSubscriberEmail;
    //    //console.log(JSON.stringify(pEmails));
  }

  function UpdateDateTime_sortFunction(a, b) {
    let UpdateDateTimeA = a.UpdateDateTime;
    let UpdateDateTimeB = b.UpdateDateTime;
    return UpdateDateTimeA < UpdateDateTimeB ? 1 : -1;
  }

  async function GetSubscribers() {
    try {
      setErrorMessage("");

      //console.log("NewsletterPublisherTable.GetSubscribers.vNewsletter: ", vNewsletter);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: vNewsletter["AccountGroup"],
            },
            Newsletter: {
              eq: vNewsletter["Newsletter"],
            },
            SubscriptionStatus: {
              eq: "subscribed",
            },
          },
        };
      } else {
        variables = {};
      }

      //console.log("NewsletterPublisherTable.GetSubscribers.variables: ", variables);

      const listNewsletterSubscribersData2 = await client.graphql({
        query: listNewsletterSubscribers,
        variables: variables,
      });

      //console.log("NewsletterPublisherTable.GetSubscribers.listNewsletterSubscribers.data.listNewsletterSubscribers.items.length : ", listNewsletterSubscribersData2.data.listNewsletterSubscribers.items.length );

      if (
        listNewsletterSubscribersData2.data.listNewsletterSubscribers.items
          .length > 0
      ) {
        const NewsletterPublisherTable1 =
          listNewsletterSubscribersData2.data.listNewsletterSubscribers.items;
        setvNewsletterSubscribers(NewsletterPublisherTable1);
      }
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "GetSubscribers", err);
    }
  }

  async function CalculateEditionStats() {
    try {
      /** 
        const [vUnsent, setvUnsent] 
        const [vBounced, setvBounced] 
        const [vOpened, setvOpened] 
        const [vSent, setvSent] 
*/
      let counts = {
        sent: 0,
        unopened: 0,
        bounced: 0,
        opened: 0,
        unsent: 0,
      };

      vSubscribersOriginal.forEach((vSubscriber) => {
        switch (vSubscriber.DeliveryStatus) {
          case "Delivery":
            counts.unopened++;
            break;
          case "Bounce":
            counts.bounced++;
            break;
          case "PUBLISH":
            counts.unsent++;
            break;
          case "OPENED":
            counts.opened++;
            break;
          case "SEND":
            counts.opened++;
            break;
          default:
            console.log(`Unknown status: ${vSubscriber.DeliveryStatus}`);
            break;
        }
      });

      counts.sent = counts.opened + counts.unopened + counts.bounced;

      setvUnsent(counts.unsent);
      setvBounced(counts.bounced);
      setvOpened(counts.opened);
      setvSent(counts.sent);
      setvUnopened(counts.unopened);
      setvUpdateKey(Date.now());
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "CalculateEditionStats",
        err
      );
    }
  }

  async function NewsletterEditionSelectedAction(pNewsletterEdition) {
    try {
      //console.log("NewsletterPublisherTable.NewsletterEditionSelectedAction.pNewsletterEdition: ", pNewsletterEdition);
      let vSelectedNewsletterGroup = vNewsletterEditionList.filter(
        (vNewsletterEditionItem) =>
          vNewsletterEditionItem["Edition"] === pNewsletterEdition
      );

      //console.log("NewsletterPublisherTable.NewsletterEditionSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
      setvNewsletterEditionVisible(true);

      //setvNewsletterSelected(pAccount);
      setvNewsletterEditionSelected(vSelectedNewsletterGroup[0]);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "NewsletterEditionSelectedAction",
        err
      );
    }
  }

  async function IterateThroughSubscribers(pDeliveryStatus) {
    try {
      //console.log("NewsletterPublisherTable.IterateThroughSubscribers.vNewsletterEditionSelected[Edition]: ", vNewsletterEditionSelected["Edition"]);

      if (vSubscribers === undefined) {
        vNewsletterSubscribers.forEach((vNewsletterSubscriber) => {
          SaveNewsletterEditionToSubscriber(
            vNewsletterSubscriber["SubscriberEmail"],
            pDeliveryStatus
          );
        });
      } else {
        vNewsletterSubscribers.forEach((vNewsletterSubscriber) => {
          let vDoesSubscriberExistForThisEdition = vSubscribers.filter(
            (vSubscriber) =>
              vNewsletterSubscriber["SubscriberEmail"] ===
                vSubscriber["RecipientEmail"] &&
              vNewsletterSubscriber["Newsletter"] ===
                vSubscriber["Newsletter"] &&
              vNewsletterSubscriber["AccountGroup"] ===
                vSubscriber["AccountGroup"] &&
              vNewsletterEditionSelected["Edition"] === vSubscriber["Edition"]
          );

          if (
            vDoesSubscriberExistForThisEdition.length === 0 &&
            vNewsletterSubscriber["SubscriberEmail"] !== undefined
          ) {
            SaveNewsletterEditionToSubscriber(
              vNewsletterSubscriber["SubscriberEmail"],
              pDeliveryStatus
            );
          }
        });
      }
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "IterateThroughSubscribers",
        err
      );
    }
  }

  function convertToISO(datetimeStr) {
    // Create a new Date object from the provided string
    let date = new Date(datetimeStr.replace(" ", "T") + "Z"); // Replace the space with 'T' to make it compatible

    // Convert it to ISO string
    return date.toISOString();
  }

  async function SendNewsletter() {
    try {
      IterateThroughSubscribers("SEND");
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "SendNewsletter", err);
    }
  }

  async function TestNewsletter() {
    try {
      console.log("NewsletterPublisherTable.TestNewsletter.vTestEmail:  ",vTestEmail);
      SaveNewsletterEditionToSubscriber(vTestEmail, "TEST SEND");
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "TestNewsletter", err);
    }
  }

  async function SaveNewsletterEditionToSubscriber(
    pSubscriber,
    pDeliveryStatus
  ) {
    try {
      //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.pSubscriber:  ",pSubscriber);
      var vUpdateDateTime = new Date().toISOString();

      let vId = (
        vNewsletter["Newsletter"] +
        "-" +
        vNewsletter["AccountGroup"] +
        "-" +
        vNewsletterEditionSelected["Edition"] +
        "-" +
        pSubscriber +
        "-" +
        vUpdateDateTime
      )
        .replace(/["']/g, "")
        .replace(/\s+/g, "")
        .replace(/[^\w]/g, "");
      let vS3Key =
        "/" +
        (
          vNewsletter["Newsletter"] +
          "-" +
          vNewsletter["AccountGroup"] +
          "-" +
          vNewsletterEditionSelected["Edition"] +
          "-" +
          pSubscriber
        )
          .replace(/["']/g, "")
          .replace(/\s+/g, "")
          .replace(/[^\w]/g, "") +
        "/" +
        vId +
        ".html";

      //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.vId:  ",vId);

      let vContentHTMLFinal = vNewsletterEditionSelected["EmailHTML"]
        .replace(/<SubscriberEmail>/g, pSubscriber)
        .replace(/<SubscriberEmailId>/g, vId);
      vContentHTMLFinal = vContentHTMLFinal
        .replace(/&lt;EmailHTML&gt;/g, pSubscriber)
        .replace(/&lt;SubscriberEmailId&gt;/g, vId);

      //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.vContentHTMLFinal:  ",vContentHTMLFinal);

      let datetimeStr = "1999-01-01 01:00:00";
      let isoDate = convertToISO(datetimeStr);

      let vNewsletterEditionCreateItem = {
        id: vId,
        AccountGroup: vNewsletterEditionSelected["AccountGroup"],
        CampaignName: vNewsletterEditionSelected["CampaignName"],
        Newsletter: vNewsletterEditionSelected["Newsletter"],
        Edition: vNewsletterEditionSelected["Edition"],
        EmailHTML: vContentHTMLFinal,
        SESMessageId: "",
        S3HtmlLocation: "",
        S3Bucket: "rhyteitnewsletters",
        S3Key: vS3Key,
        RecipientEmail: pSubscriber,
        SendDateTime: isoDate,
        FirstOpenDateTime: isoDate,
        OpenDateTime: isoDate,
        LastClickDateTime: isoDate,
        UnsubscribeDateTime: isoDate,
        SendCount: 0,
        OpenCount: 0,
        ClickCount: 0,
        DeliveryStatus: pDeliveryStatus,
        BounceReason: "",
        UnsubscribeStatus: false,
        AudienceSegment: "Internal",
        DeviceType: "",
        UserAgent: "",
        IPAddress: "",
        City: "",
        Country: "",
        ContentVersion: 1,
        NewsletterTrackingStatus: pDeliveryStatus,
        CreationName: Users,
        UpdateName: Users,
        CreationDateTime: vUpdateDateTime,
        UpdateDateTime: vUpdateDateTime,
      };

      //console.log("NewsletterSubscriberTable.CreateNewsletterEditionTracking.vNewsletterEditionCreateItem: ",vNewsletterEditionCreateItem)

      let createNewsletterEditionTrackingResponse = await client.graphql({
        query: createNewsletterEditionTracking,
        variables: { input: vNewsletterEditionCreateItem },
      });

      //console.log("NewsletterSubscriberTable.SaveNewsletterEditionToSubscriber.createNewsletterEditionTrackingResponse: ",createNewsletterEditionTrackingResponse)

      setvAddSubscribersVisible(false);
      setvNewSubscriber("");
      GetNewsletterTrackingTable();
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterPublisherTable",
        "SaveNewsletterEditionToSubscriber",
        err
      );
    }
  }

  async function DeleteAllRows(event) {
    try {
      vSubscribers.forEach((vNewsletterSubscriber) => {
        DeleteRow(vNewsletterSubscriber);
      });

      GetNewsletterTrackingTable();
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "DeleteAllRows", err);
    }
  }

  async function DeleteRow(pSubsciber) {
    try {
      setErrorMessage("");

      const DeleteNewsletterEditionTrackingRow = {
        id: pSubsciber.id,
        _version: pSubsciber._version,
      };

      const vDeleteResponse = await client.graphql({
        query: deleteNewsletterEditionTracking,
        variables: {
          input: DeleteNewsletterEditionTrackingRow,
        },
      });

      ErrorMessageStr = vDeleteResponse;
      setErrorMessage(ErrorMessageStr);
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "DeleteRow", err);
    }
  }

  async function ButtonFilter(event, pFilter) {
    try {
      let vSubscribersTmp = [];

      if (pFilter === "SENT") {
        vSubscribersTmp = vSubscribersOriginal.filter(
          (subscriber) => subscriber.DeliveryStatus !== "PUBLISH"
        );
      } else {
        vSubscribersTmp = vSubscribersOriginal.filter(
          (subscriber) => subscriber.DeliveryStatus === pFilter
        );
      }

      setvSubscribers(vSubscribersTmp);
    } catch (err) {
      RhyteItLogV2(Users, "NewsletterPublisherTable", "ButtonFilter", err);
    }
  }

    function Field_handleChange(event, data, pFieldName) {
      try {


        let vTmp = event.target.value

        if (pFieldName === "TestEmail") {
          setvTestEmail(vTmp)

        }


      } catch (err) {
        RhyteItLogV2(Users, "NewsletterPublisherTable", "Field_handleChange", err);
      }
    }      

  return (
    <section ref={ref} className={styles.Section}>
      {vNewsletter !== undefined && (
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexLeftColumn}
        >
          <motion.div
            className={styles.NewsletterTitleDiv}
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <motion.h1 className={styles.NewsletterTitle}>
              {vNewsletter["Newsletter"]}
            </motion.h1>
          </motion.div>
          <motion.div
            className={styles.NewsletterDescriptionDiv}
            style={{
              transform: isInView ? "none" : "translateX(-200px)",
              opacity: isInView ? 1 : 0,
              transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
            }}
          >
            <motion.h1 className={styles.NewsletterDescription}>
              {vNewsletter["Description"]}
            </motion.h1>
          </motion.div>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexRow}
          >
            <SelectField
              className={styles.SelectField}
              value={vNewsletterEditionSelected.Edition}
              placeholder="Select newsletter edition"
              onChange={(e) => NewsletterEditionSelectedAction(e.target.value)}
            >
              {vNewsletterEditionList !== undefined &&
                vNewsletterEditionList.map((NewsletterEdition) => (
                  <option
                    key={NewsletterEdition.Edition}
                    className={styles.SelectFieldOption}
                    value={NewsletterEdition.Edition}
                    label={NewsletterEdition.Edition}
                  >
                    {NewsletterEdition.Edition}
                  </option>
                ))}
            </SelectField>
            <Button
              className={styles.Button}
              variation="primary"
              onClick={(e) => SendNewsletter(e)}
            >
              Send
            </Button>
            <Button
              className={styles.Button}
              variation="primary"
              onClick={(e) => GetNewsletterTrackingTable(e)}
            >
              Refresh
            </Button>
            <Button
              className={styles.Button}
              variation="primary"
              onClick={(e) => DeleteAllRows(e)}
            >
              Delete All
            </Button>
          </Flex>
          {vSubscribers !== undefined && (
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexRow}
          >
            <TextField
                className={styles.TextField}
                name="TestEmail"
                placeholder="Enter test email"
                defaultValue={vTestEmail}
                onChange={(e) => Field_handleChange(e, vTestEmail, "TestEmail")}
              />   
            <Button
              className={styles.Button}
              variation="primary"
              onClick={(e) => TestNewsletter(e)}
            >
              Test Send
            </Button>           
          </Flex>     
              )}     
          <Table className={styles.Table} highlightOnHover={true}>
            <TableHead>
              <TableRow className={styles.TableHeaderRow}>
                <TableCell className={styles.TableHeaderRowCells}>
                  Unsent
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Sent
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Unopened
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Opened
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Bounced
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={styles.TableRow} key={vUpdateKey}>
                <TableCell className={styles.TableCell}>
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => ButtonFilter(e, "PUBLISH")}
                  >
                    {vUnsent}
                  </Button>
                </TableCell>
                <TableCell className={styles.TableCell}>
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => ButtonFilter(e, "SENT")}
                  >
                    {vSent}
                  </Button>
                </TableCell>
                <TableCell className={styles.TableCell}>
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => ButtonFilter(e, "Delivery")}
                  >
                    {vUnopened}
                  </Button>
                </TableCell>
                <TableCell className={styles.TableCell}>
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => ButtonFilter(e, "OPENED")}
                  >
                    {vOpened}
                  </Button>
                </TableCell>
                <TableCell className={styles.TableCell}>
                  <Button
                    loadingText=""
                    ariaLabel=""
                    className={styles.Button}
                    onClick={(e) => ButtonFilter(e, "Bounce")}
                  >
                    {vBounced}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table
            className={styles.Table}
            key={vUpdateKey}
            highlightOnHover={true}
          >
            <TableHead>
              <TableRow className={styles.TableHeaderRow}>
                <TableCell className={styles.TableHeaderRowCells}>
                  Expand
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Actions
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Email
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Status
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Sent
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  First Open
                </TableCell>
                <TableCell className={styles.TableHeaderRowCells}>
                  Update
                </TableCell>
              </TableRow>
            </TableHead>

            {vSubscribers !== undefined &&
              IsLoading === false &&
              vNewsletter !== undefined &&
              vSubscribers.map((Subscriber) => (
                <NewsletterPublisherRow
                  key={Subscriber.id}
                  Users={Users}
                  Newsletter={vNewsletter}
                  Subscriber={Subscriber}
                />
              ))}
          </Table>
        </Flex>
      )}
    </section>
  );
};

export default NewsletterPublisherTable;
