import React from "react";
import { RhyteItLogV2 } from "../../BusinessLogic/UserManagement";  // Import your logging function
import {
    UserContext,
    CognitoGroupContext,
  } from "../../BusinessLogic/Hooks/UserContext";
  
import MenuBackground from "../../pages/Menu/MenuBackground";
  import styles from "./ErrorBoundary.module.css";
  import {
    Button,
    Fieldset,
    SelectField,
    Flex,
    Text,
    TextField,
    Card,
    Badge,
    Image,
    Heading,
    Link,
    TableCell,
    TableRow,
    Table,
    TableHead,
    TableBody,
    TextAreaField
  } from "@aws-amplify/ui-react";  

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null, failingComponent: null, failingFunction: null };
    }


    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);

        // Extract failing component and function
        const failingComponent = this.extractComponentName(errorInfo?.componentStack);
        const failingFunction = this.extractFunctionName(error.stack);

        // Capture logged-in user (if available)
        const currentUser = this.props.Users; // Replace this with actual user context if available

        // Log error into RhyteItLogV2
        RhyteItLogV2(
            currentUser,
            this.props.pageName || failingComponent || "UnknownComponent",
            failingFunction || "UnknownFunction",
            error
        );

        this.setState({ errorInfo, failingComponent, failingFunction });
    }

    extractComponentName(componentStack) {
        if (!componentStack) return null;
        const matches = componentStack.match(/at (\w+)/g);
        return matches && matches.length > 1 ? matches[1].replace("at ", "") : "UnknownComponent";
    }

    extractFunctionName(stackTrace) {
        if (!stackTrace) return null;
        const matches = stackTrace.match(/at (\w+)/g);
        return matches && matches.length > 1 ? matches[1].replace("at ", "") : "UnknownFunction";
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                <MenuBackground
                className={styles.MenuBackground}
                GradientStopColor1="#0000"
                GradientStopColor2="#000"
                GradientStopColor3="#00CCCC"
                GradientStopColor4="#8880"
              />                
                <Flex
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    gap="1rem"
                    className={styles.FlexColumn}
                >
                    <h2 className={styles.SectionHeader}>Ooooh man!! I just sat down to eat my ice cream...</h2>
                    <h2 className={styles.SectionHeader}>Ok...let me go back to my computer and figure this out.</h2>
                    <h2 className={styles.SectionHeader}>You can email or discord us if you want updates on resolving this: support@rhyteit.com or https://discord.gg/ktNGvKcmr7</h2>                    
                    <h2 className={styles.SectionHeader}>Happens everytime I think its safe to go for ice cream...</h2>
                </Flex>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;