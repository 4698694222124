
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import  { deleteContent, updateNewsletterSubscribers, createContent}  from '../../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './NewsletterSubscriberRow.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function NewsletterSubscriberRow(
  props
) {       

  NewsletterSubscriberRow.propTypes = {
    Newsletter: PropTypes.object,
    Subscriber: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vNewsletter, setvNewsletter] = useState(props.Newsletter)  
    const [vSubscriber, setvSubscriber] = useState(props.Subscriber)   
    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    const [vSubscriptionStatus, setvSubscriptionStatus] = useState(props.Subscriber["SubscriptionStatus"]) 
  
        


      useEffect(() => {

     // console.log("NewsletterSubscriberRow.useEffect[].vSubscriber:  ",vSubscriber) 




      },[])  

      useEffect(() => {
      console.log("NewsletterSubscriberRow.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {
      console.log("NewsletterSubscriberRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
      }, [vExpandRow]);   
      
      useEffect(() => {
        console.log("NewsletterSubscriberRow.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vSubscriptionStatus]);    
      

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        console.log("NewsletterSubscriberRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)


          } catch (err) { 

          ErrorMessageStr = "NewsletterSubscriberRow.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     

      async function SaveRecord(event) {
        
        let vSubStatusTmp = vSubscriptionStatus
  
        console.log("NewsletterSubscriberRow.Unsubscribe.event: ",event)
        try {           
           
          if (vSubscriptionStatus === "subscribed") {
            vSubStatusTmp = "unsubscribed"
          } else {
            vSubStatusTmp = "subscribed"
          }

          setvSubscriptionStatus(vSubStatusTmp)
          UpdateRecord(vSubStatusTmp)
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterSubscriberRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    

      async function UpdateRecord(pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()
  
  
            vSubscriber.UpdateDateTime = vUpdateDateTime       
            vSubscriber.UpdateName = Users   
                
              let vSubscriberUpdateItem = {
                "id": vSubscriber["id"],
                "Newsletter": vSubscriber["Newsletter"],
                "AccountGroup": vSubscriber["AccountGroup"],
                "SubscriberEmail": vSubscriber["SubscriberEmail"],
                "SubscriptionStatus": pStatus,
                "SubscribedDate": vSubscriber["SubscribedDate"],
                "SubscriberSegment": vSubscriber["SubscriberSegment"],
                "LastEmailSentDate": vSubscriber["LastEmailSentDate"],
                "LastEmailOpenedDate": vSubscriber["LastEmailOpenedDate"],
                "SendCount": vSubscriber["SendCount"],
                "OpenCount": vSubscriber["OpenCount"],
                "ClickCount": vSubscriber["ClickCount"],
                "SubscriptionSource": vSubscriber["SubscriptionSource"],
                "UnsubscribeReason": "Administrative",
                "Geolocation": vSubscriber["Geolocation"],
                "SubscriptionPreferences": vSubscriber["SubscriptionPreferences"],
                "BounceReason": vSubscriber["BounceReason"],
                "Active": vSubscriber["Active"],
                "CreationName": vSubscriber["CreationName"],
                "UpdateName": vSubscriber["UpdateName"],
                "CreationDateTime": vSubscriber["CreationDateTime"],
                "UpdateDateTime": vSubscriber["UpdateDateTime"]
              }
  
              console.log("NewsletterSubscriberRow.Unsubscribe.vSubscriberUpdateItem: ",vSubscriberUpdateItem)
  
              let updateNewsletterSubscribersResponse = await client.graphql({                    
                      query: updateNewsletterSubscribers,
                      variables:{input: vSubscriberUpdateItem}            
                }
              )
            
            console.log("NewsletterSubscriberRow.Unsubscribe.updateNewsletterSubscribersResponse: ",updateNewsletterSubscribersResponse)

            setvSubscriber(updateNewsletterSubscribersResponse.data.updateNewsletterSubscribers)        
  
          } catch (err) { 
            RhyteItLogging(Users, "NewsletterSubscriberRow", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("NewsletterSubscriberRow.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
  


      return (
        <TableBody>         
          <TableRow  className={vSubscriber.SubscriptionStatus === "subscribed" ? styles.TableRow : styles.TableRowUnsubscribed}  key={vSubscriber.id}>    
            <TableCell className={styles.TableCell}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => ExpandRow(vExpandRow)}
                    >
                    +
                </Button>   
            </TableCell>             
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.SubscriberEmail}
              </Text>                           
            </TableCell>   
            <TableCell className={styles.TableCell}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => SaveRecord(e)}
                    >
                    {vSubscriptionStatus}
                </Button>   
            </TableCell>               
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.SubscribedDate !== "1999-01-01T01:00:00.000Z" ? new Date(vSubscriber.SubscribedDate).toISOString().slice(0, 10) : ""}
              </Text>                           
            </TableCell>           
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.LastEmailSentDate !== "1999-01-01T01:00:00.000Z" ? new Date(vSubscriber.LastEmailSentDate).toISOString().slice(0, 10) : ""}
              </Text>                           
            </TableCell>          
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.LastEmailOpenedDate !== "1999-01-01T01:00:00.000Z" ? new Date(vSubscriber.LastEmailOpenedDate).toISOString().slice(0, 10) : ""}
              </Text>                           
            </TableCell>            
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.SendCount}
              </Text>                           
            </TableCell>            
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.OpenCount}
              </Text>                           
            </TableCell>            
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {vSubscriber.ClickCount}
              </Text>                           
            </TableCell>       
            <TableCell className={styles.TableCell}>  
              <Text  className={styles.TextField}>
              {new Date(vSubscriber.UpdateDateTime).toISOString().slice(0, 10)}
              </Text>                           
            </TableCell>                                          
          </TableRow>  

          {
            vExpandRow === true &&  (                   
              <TableRow  className={styles.TableRow}  key={vSubscriber.CreationDateTime}>              
              <TableCell className={styles.TableCell}>  
                <Text  className={styles.TextField}>               
                </Text>                           
              </TableCell>  
              <TableCell colSpan="1">                   
                  <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                    >                 
                    <Text  className={styles.TextFieldTitle}>
                      Segment:
                    </Text>  
                    <Text  className={styles.TextField}>      
                      {vSubscriber.SubscriberSegment}
                    </Text>                             
                  </Flex>                                    
                </TableCell>             
                <TableCell className={styles.TableCell}>                  
                  <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                        Source:
                      </Text>                    
                    <Text  className={styles.TextField}>
                    {vSubscriber.SubscriptionSource}
                    </Text>                              
                  </Flex>                             
                </TableCell>            
                <TableCell className={styles.TableCell}>  
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                        Location:
                      </Text>                       
                      <Text  className={styles.TextField}>
                      {vSubscriber.Geolocation}
                      </Text>                                 
                  </Flex>                          
                </TableCell>            
                <TableCell className={styles.TableCell}> 
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Unsubscribe:
                      </Text>                        
                  <Text  className={styles.TextField}>
                  {vSubscriber.UnsubscribeReason}
                  </Text>                              
                </Flex>                                  
                </TableCell>    
                <TableCell className={styles.TableCell}>  
                <Flex
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        gap="1rem"
                      >                 
                      <Text  className={styles.TextFieldTitle}>
                      Bounce:
                      </Text>                                  
                      <Text  className={styles.TextField}>
                      {vSubscriber.BounceReason}
                      </Text>                                 
                  </Flex>                          
                </TableCell>                                 
              </TableRow>  
            )
          }              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default NewsletterSubscriberRow;

