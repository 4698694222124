
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Menu.module.css';
import { SEO } from '../../SEO/SEO';
import {useNavigate,Link } from 'react-router-dom';
import axios from 'axios';

import  { createAudienceGeoInfo}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';

import { useInView, useScroll, useTransform, motion, useAnimation } from "framer-motion";
//import {useInView} from "react-intersection-observer";

import Footer from '../../RhyteItComponents/Footer/Footer';
import FooterV2 from '../../RhyteItComponents/Footer/FooterV2';
import {
    Button,
    Flex, 
    Text,
    Image,
    useAuthenticator
  } from '@aws-amplify/ui-react';
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import { NavBarMenuClickedContext} from '../../../BusinessLogic/Hooks/NavBarMenuContext';
import { signOut } from 'aws-amplify/auth';
import { uuidv4 } from "../../../BusinessLogic/UtilitiesManagement";
import { fetchGeoLocation } from "../../../BusinessLogic/geoLocationService";



const Menu  = (props) => {
 
    Menu.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };


  
  const [location, setLocation] = useState(null);
  const [vFirstTouch, setvFirstTouch] = useState(true);  
 const client = generateClient();
  const { authStatus } = useAuthenticator(context => [context.authStatus]);
  let navigate = useNavigate(); 

  let ErrorMessageStr = ""
  const {Users, setUsers} = useContext(UserContext);    
  const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);  
  const [vNavBarMenuClicked, setvNavBarMenuClicked] = useState(false);  
  const [vHeaderTextKey, setvHeaderTextKey] = useState();   
  const [vHeaderSubTextKey, setvHeaderSubTextKey] = useState(); 
  const [vHomeImageVisible, setvHomeImageVisible] = useState(false); 
  const [vProductImageVisible, setvProductImageVisible] = useState(false); 
  const [vNewsImageVisible, setvNewsImageVisible] = useState(false);   
  const [vModelsImageVisible, setvModelsImageVisible] = useState(false);   
  const [vAboutImageVisible, setvAboutImageVisible] = useState(false); 
  
  const { scrollYProgress } = useScroll();

  const rotate = useTransform(scrollYProgress, [0, 1], [20, 0]);
  const scale = useTransform(scrollYProgress, [0, 1], [1.05, 1]);
  const translate = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  const HomeTextRef = useRef(null);
  const HomeImageRef = useRef(null);

  const ref= useRef(null);
  const isInView = useInView(ref)

  const mainControls = useAnimation()

  let customStyles = {
    '--x': '4s',
  };


  useEffect(() => {



       // navigator.geolocation.getCurrentPosition(success, error);
       (async function () {
        try {
          setvHeaderTextKey(Date.now())
          setvHeaderSubTextKey(Date.now())
              async function getLocation() {
                const data = await fetchGeoLocation(vFirstTouch,"Menu");
                setLocation(data);
                setvFirstTouch(false)
              }
              getLocation();
        } catch (err) {



        let errorMessage = null;
        let errorStack = null;
        let errorType = err.name;
  
        //console.log("NewsletterPublisherTable.GetSubscribers.err: ", err);
  
        if ("data" in err && "errors" in err) {
          errorMessage = err.errors[0]["errorType"] || err.toString;
          errorStack = err.errors[0]["message"] || "No stack trace available";
        } else {
          errorMessage = err.message || err.toString;
          errorStack = err.stack || "No stack trace available";
        }

        RhyteItLogging(
          "NA",
          "Menu",
          "useEffect[]",
          errorType,
          errorMessage,
          '{"ERROR": "' + errorStack + '"}',
          0
        );    

        }
      })();
    

    },[]) 


    useEffect(() => {
        
       // console.log("Menu.useEffect[isInView].isInView: " ,isInView);
      //  console.log("Menu.useEffect[isInView].scrollYProgress: " ,scrollYProgress);
        
        if (isInView) {
            mainControls.start("visible")
            window.scrollTo(0, 0)
        }
    
        },[isInView]) 
    

    useEffect(() => {

     //console.log("Menu.useEffect[vNavBarMenuClicked].vNavBarMenuClicked: " ,vNavBarMenuClicked);

    },[vNavBarMenuClicked]) 

    useEffect(() => {

   
       },[vHomeImageVisible]) 


  
async function GoToMenuSignedIn() {
    try {
  
     // console.log("LandingPageNavBar.GoToMenuSignedIn.authStatus: " ,authStatus, " - .CognitoGroup: " ,CognitoGroup, " - .Users: " ,Users);
  
  
          navigate('/AuthenticatorRhyteIt', 
          {
            state: {
              UserId:Users,                                
              UserName:Users,                                
              CognitoGroup:CognitoGroup,
              authStatus: authStatus
            }
          }
        );  
          
          
  
      } catch (err) { 
    
        let errorMessage = null;
        let errorStack = null;
        let errorType = err.name;
  
        //console.log("NewsletterPublisherTable.GetSubscribers.err: ", err);
  
        if ("data" in err && "errors" in err) {
          errorMessage = err.errors[0]["errorType"] || err.toString;
          errorStack = err.errors[0]["message"] || "No stack trace available";
        } else {
          errorMessage = err.message || err.toString;
          errorStack = err.stack || "No stack trace available";
        }

        RhyteItLogging(
          "NA",
          "Menu",
          "GoToMenuSignedIn",
          errorType,
          errorMessage,
          '{"ERROR": "' + errorStack + '"}',
          0
        );   

    }        
  }  
  
  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      //console.log('error signing out: ', error);
    }
  }
    
    

  return (
                          

                <section
                    ref={ref}
                    className={styles.HeaderTextContainer}>      
                    {authStatus !== undefined && (            
                        <Flex
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                        gap="0.25rem"
                        className={styles.MenuTextContainer}
                    >      
               
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                gap="2rem"
                                className={styles.MenuTextRow}
                                >     
                                <div className={styles.MenuHomeDiv}>  
                                <Text className={styles.MenuText}                    
                                    id="HomeText"
                                    ref={HomeTextRef}
                                    onMouseEnter={() => setvHomeImageVisible(true)}
                                    onMouseLeave={() =>  setvHomeImageVisible(false)}
                                    onClick={(e) => setvNavBarMenuClicked(!vNavBarMenuClicked)}
                                    >
                                    Home
                                </Text>                                                                
                                </div>                                                                                
                                <Link className={styles.Link} to="/TurboFanBoost">              
                                    <Text className={styles.MenuText}
                                    
                                    onMouseEnter={() => setvProductImageVisible(true)}
                                    onMouseLeave={() =>  setvProductImageVisible(false)}
                                    >
                                        Products
                                    </Text>                                                                 
                                </Link>  
                                <Link className={styles.Link} to="/AdultContentCreatorSearch">               
                                    <Text className={styles.MenuText}
                                    onMouseEnter={() => setvModelsImageVisible(true)}
                                    onMouseLeave={() =>  setvModelsImageVisible(false)}>
                                        Models
                                    </Text>            
                                </Link> 
                            </Flex>                                
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                gap="2rem"
                                className={styles.MenuTextRow}
                                >                                                                            
                                <Link className={styles.Link} to="/news">              
                                    <Text className={styles.MenuText}
                                    onMouseEnter={() => setvNewsImageVisible(true)}
                                    onMouseLeave={() =>  setvNewsImageVisible(false)}>
                                        News
                                    </Text>          
                                </Link>             
                                <Link className={styles.Link} to="/Blog"
                                    onMouseEnter={() => setvAboutImageVisible(true)}
                                    onMouseLeave={() =>  setvAboutImageVisible(false)}>          
                                    <Text className={styles.MenuText}>
                                        Blog
                                    </Text>     
                                </Link>              
                                <Link className={styles.Link} to="/Podcast"
                                    onMouseEnter={() => setvAboutImageVisible(true)}
                                    onMouseLeave={() =>  setvAboutImageVisible(false)}>          
                                    <Text className={styles.MenuText}>
                                        Podcast
                                    </Text>     
                                </Link>                                                              
                            </Flex>   
                            <Flex
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                                gap="2rem"
                                className={styles.AuthenticateRowTextRow}
                                >                                                                                                        
                    
                                <button  className={styles.Button}  onClick={(e) => GoToMenuSignedIn()}>                        
                                    {authStatus === "authenticated" ? 
                                          
                                        <Text className={styles.MenuText}>
                                            Enter
                                        </Text> :   
                                        <Text className={styles.MenuText}>
                                            Sign In
                                        </Text>  
                                      }
                                </button>  
                                <button  className={styles.Button}  onClick={(e) => handleSignOut()}>
                                    <Text className={styles.MenuText}>
                                        Sign Out
                                    </Text>                                      
                                </button>                               
                            </Flex>                                                 
                        </Flex>   
                    )}
                </section>   

        );
  }

export default Menu