import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styles from "./MainMenuV2.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import MenuBackground from "./MenuBackground";
import Header from "../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../BusinessLogic/Hooks/UserContext";
import {
  Table,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Flex,
  Link,
  Authenticator,
  Text,
  useAuthenticator,
  Fieldset,
} from "@aws-amplify/ui-react";
import { signOut } from "aws-amplify/auth";
import { fetchGeoLocation } from "../../BusinessLogic/geoLocationService";

import { RhyteItLogging } from "../../BusinessLogic/UserManagement";

const MainMenuV2 = (props) => {
  MainMenuV2.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string,
  };

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  let navigate = useNavigate();
  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: true },
  ];

  var ErrorMessageStr = "";

  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);
  const [location, setLocation] = useState(null);
  const [vFirstTouch, setvFirstTouch] = useState(true);

  useEffect(() => {
    (async function () {
      try {
        async function getLocation() {
          const data = await fetchGeoLocation(vFirstTouch, "MainMenuV2");
          setLocation(data);
          setvFirstTouch(false);
        }
        getLocation();
      } catch (err) {
        const errorMessage = err.message || "Unknown error";
        const errorStack = err.stack || "No stack trace available";

        RhyteItLogging(
          "NA",
          "MainMenuV2",
          "useEffect[]",
          errorMessage,
          '{"ERROR": "' + errorStack + '"}',
          0
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (authStatus !== "authenticated") {
      navigate("/AuthenticatorRhyteIt", {});
    } else {
      if (CognitoGroup !== undefined) {
        const vCognitoGroup = Object.values(CognitoGroup).filter((cg) => {
          return cg === "rhyteitAdmins";
        }).length;

        if (vCognitoGroup === 0) {
          navigate("/LandingPageV4", {
            state: {},
          });
        }
      }
    }
  }, [CognitoGroup]);

  async function handleSignOut() {
    try {
      await signOut();
    } catch (err) {
      const errorMessage = err.message || "Unknown error";
      const errorStack = err.stack || "No stack trace available";

      RhyteItLogging(
        "NA",
        "MainMenuV2",
        "handleSignOut",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
    }
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div>
          <MenuBackground
            className={styles.MenuBackground}
            GradientStopColor1="#0000"
            GradientStopColor2="#000"
            GradientStopColor3="#777"
            GradientStopColor4="#8880"
          />
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="wrap"
            gap="1rem"
            className={styles.MenuContainer}
          >
            <Header
              Title="Main Menu"
              SubTitle="Where you can find anything"
              BreadCrumbItems={vBreadCrumbItems}
              pageSource="MainMenu"
            />
            <Text className={styles.MenuHeaderText}>
              {Users} is {authStatus} to rhyteitAdmins
            </Text>
            <Text
              className={styles.ErrorMessage}
              ErrorMessage={ErrorMessage}
            ></Text>
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="wrap"
              gap="1rem"
              className={styles.GridContainer}
            >
              <Text className={styles.Text}>SMMAD</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/SMMADPlatforms"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Platforms
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SMMADCampaign"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Campaigns
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/TurboFanBoostUser"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Manage accounts
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SMMADBatchJobs"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Batch Jobs
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SMMADOrganize"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Organize Campaigns
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SMMADContentMetrics"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Statistics
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Affiliate Program</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/AffiliateProgramManager"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Affiliate Manager
                  </Button>
                </Link>
              </Flex>

              <Text className={styles.Text}>Development</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/RhyteItLog"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    RhyteIt Log
                  </Button>
                </Link>
              </Flex>

              <Text className={styles.Text}>Blog Manager</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/BlogManager"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Blog Manager
                  </Button>
                </Link>
                <Link className={styles.Link} href="/Blog" isExternal={false}>
                  <Button className={styles.NavButton} variation="primary">
                    Blog
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Newsletter</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/NewsletterManager"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Newsletter
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Marketing</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/Dashboard"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Dashboard
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/RhyteItAccounts"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    RhyteIt Accounts
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/TwitterStats"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Twitter Management
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/ApplicantManagement"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Applicants
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Product Management</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/ProductManagement"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Products
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/DigitalMarketPlace"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Digital Market
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/CustomerPurchases"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Your purchases
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Email</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/EmailManagement"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    RhyteIt Emails
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>News Management</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="/SBSMQueries"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    Query Terms
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SBSMNewsAdmin"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    News Admin
                  </Button>
                </Link>
                <Link
                  className={styles.Link}
                  href="/SBSMRSS"
                  isExternal={false}
                >
                  <Button className={styles.NavButton} variation="primary">
                    RSS Feeds
                  </Button>
                </Link>
              </Flex>
              <Text className={styles.Text}>Support</Text>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                gap="1rem"
                className={styles.FlexRowButton}
              >
                <Link
                  className={styles.Link}
                  href="https://discord.gg/QymmajFQ"
                  isExternal={true}
                >
                  Discord
                </Link>
                <Link
                  className={styles.Link}
                  href="https://twitter.com/RhyteIt"
                  isExternal={true}
                >
                  Twitter
                </Link>
                <Link
                  className={styles.Link}
                  href="https://medium.com/@support_13571"
                  isExternal={true}
                >
                  Medium
                </Link>
                <Link
                  className={styles.Link}
                  href="https://www.instagram.com/rhyteit/"
                  isExternal={true}
                >
                  Instagram
                </Link>
                <Button
                  className={styles.Link}
                  variation="primary"
                  onClick={(e) => handleSignOut()}
                >
                  Sign Out
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </div>
      )}
    </Authenticator>
  );
};

export default MainMenuV2;
