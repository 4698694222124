import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listNewsletters,
  listSMMADAccounts,
  listSMMADAccountGroups,
} from "../../../graphql/queries";
import {
  createNewsletter,updateNewsletter
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogV2,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { get_uuidv4 } from "../../../BusinessLogic/uuidv4";
import { useNavigate } from "react-router-dom";
import NewsletterGeneration from "../NewsletterGeneration/NewsletterGeneration"
import NewsletterPrompts from "../NewsletterPrompts/NewsletterPrompts"
import NewsletterFileURLs from "../NewsletterFileURLs/NewsletterFileURLs"
import NewsletterImageURLs from "../NewsletterImageURLs/NewsletterImageURLs"
import EmailHTMLTemplate from "../NewsletterEmailHTMLTemplate/EmailHTMLTemplate"


import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./NewsletterManager.module.css";

const NewsletterManager = (props) => {
  NewsletterManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vNewsletterManager, setvNewsletterManager] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/NewsletterManager", Title: "NewsletterManager", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [vCampaignsActive, setvCampaignsActive] = useState(false);
  const [vAccountType, setvAccountType] = useState(false);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);
  
  const [vAccountGroup, setvAccountGroup] = useState();
  
  const [vAccountGroupSelected, setvAccountGroupSelected] = useState({"AccountGroup":"None"});
  let [vAccountGroupList, setvAccountGroupList] = useState();

  const [vNewsletterSelected, setvNewsletterSelected] = useState({"Newsletter":"None"});
  let [vNewsletterList, setvNewsletterList] = useState();  
  const [vAddNewletterVisible, setvAddNewletterVisible] = useState(false);
  const [vNewsletterVisible, setvNewsletterVisible] = useState(false);
  
  let [vNewNewsletter, setvNewNewsletter] = useState();
  let [vNewNewsletterDescription, setvNewNewsletterDescription] = useState();

  let [vViewNewsletterTemplateSection, setvViewNewsletterTemplateSection] = useState(false);
  let [vNewsletterTitle, setvNewsletterTitle] = useState();
  let [vNewsletterDescription, setvNewsletterDescription] = useState();
  let [vPixelTrackerAPIURL, setvPixelTrackerAPIURL] = useState();
  let [vNewsletterBannerURL, setvNewsletterBannerURL] = useState();
  let [vUnsubscriberURL, setvUnsubscriberURL] = useState();
  let [vCopyright, setvCopyright] = useState();
  let [vAddress, setvAddress] = useState();
  let [vPodcastBanner, setvPodcastBanner] = useState();
  let [vPodcastEmail, setvPodcastEmail] = useState();
  let [vNewsletterEmail, setvNewsletterEmail] = useState();
  let [vUnsubscribeEmail, setvUnsubscribeEmail] = useState();

  let [vOpenAIapiKey, setvOpenAIapiKey] = useState();
  let [vOpenAIOrganization, setvOpenAIOrganization] = useState();
  let [vOpenAIProject, setvOpenAIProject] = useState();
  let [vOpenAIModel, setvOpenAIModel] = useState();
  let [vS3Bucket, setvS3Bucket] = useState();

  let [vLogoURL, setvLogoURL] = useState();
  let [vIconURL, setvIconURL] = useState();
  let [vNewsletterURL, setvNewsletterURL] = useState();
  
  
let [vViewNewsletterGenerationSection, setvViewNewsletterGenerationSection] = useState(false);
let [vViewNewsletterPromptsSection, setvViewNewsletterPromptsSection] = useState(false);
let [vViewNewsletterFileURLsSection, setvViewNewsletterFileURLsSection] = useState(false);
let [vViewEmailHTMLTemplateSection, setvViewEmailHTMLTemplateSection] = useState(false);


let [vFieldNameSelected, setvFieldNameSelected] = useState()  
let [vLabelSelected, setvLabelSelected] = useState()  
let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 

const [hasError, setHasError] = useState(false);

let vFieldButtons =  [
  {"FieldName" : "MetaData", "LabelName" : "MetaData", "FieldType" : "Section"},
  {"FieldName" : "NewsletterGeneration", "LabelName" : "HTML Templates", "FieldType" : "Section"},
  {"FieldName" : "EmailHTMLTemplate", "LabelName" : "Email HTML Templates", "FieldType" : "Section"},
  {"FieldName" : "NewsletterPrompts", "LabelName" : "AI Prompts", "FieldType" : "Section"},
  {"FieldName" : "NewsletterFileURLs", "LabelName" : "File URLs", "FieldType" : "Section"},
  {"FieldName" : "NewsletterImageURLs", "LabelName" : "Image URLs", "FieldType" : "Section"},
  {"FieldName" : "Subscribers", "LabelName" : "Subscribers", "FieldType" : "Page"},
  {"FieldName" : "Editions", "LabelName" : "Editions", "FieldType" : "Page"},
  {"FieldName" : "Publishing", "LabelName" : "Publishing", "FieldType" : "Page"},
]

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccountGroups();
           
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogV2(
          Users,
          "NewsletterManager",
          "useEffect[CognitoGroup]",
          err
      );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

    if (vAccountGroupSelected.AccountGroup !== "None") {
    //console.log("NewsletterManager.useEffect[vAccountGroupSelected].vAccountGroupSelected:  ",vAccountGroupSelected)
  
     GetNewsletters(vAccountGroupSelected.AccountGroup);
    }
  }, [vAccountGroupSelected]);
  
  useEffect(() => {

   //console.log("NewsletterManager.useEffect[vNewsletterSelected].vNewsletterSelected:  ",vNewsletterSelected)

   if (vNewsletterSelected["Newsletter"] !== "None") {
    setvNewsletterTitle(vNewsletterSelected["Newsletter"])

   if ("Description" in vNewsletterSelected) {
    setvNewsletterDescription(vNewsletterSelected["Description"])
   }

   if ("S3Bucket" in vNewsletterSelected) {
    setvS3Bucket(vNewsletterSelected["S3Bucket"])
   }

   if ("OpenAIapiKey" in vNewsletterSelected) {
    setvOpenAIapiKey(vNewsletterSelected["OpenAIapiKey"])
   }

   if ("OpenAIOrganization" in vNewsletterSelected) {
    setvOpenAIOrganization(vNewsletterSelected["OpenAIOrganization"])
   }
   
   if ("OpenAIProject" in vNewsletterSelected) {
    setvOpenAIProject(vNewsletterSelected["OpenAIProject"])
   }

   if ("OpenAIModel" in vNewsletterSelected) {
    setvOpenAIModel(vNewsletterSelected["OpenAIModel"])
   }

   if ("LogoURL" in vNewsletterSelected) {
    setvLogoURL(vNewsletterSelected["LogoURL"])
   }

   if ("IconURL" in vNewsletterSelected) {
    setvIconURL(vNewsletterSelected["IconURL"])
   }

   if ("NewsletterURL" in vNewsletterSelected) {
    setvNewsletterURL(vNewsletterSelected["NewsletterURL"])
   }



   if ("TemplateJSON" in vNewsletterSelected) {
    const vTemplateJSON = JSON.parse(vNewsletterSelected["TemplateJSON"]);  
    
    //console.log("NewsletterManager.useEffect[vNewsletterSelected].vTemplateJSON:  ",vTemplateJSON)

    if ("PixelTrackerAPIURL" in vTemplateJSON) {
      setvPixelTrackerAPIURL(vTemplateJSON["PixelTrackerAPIURL"])
    }
    
    if ("NewsletterBannerURL" in vTemplateJSON) {
      setvNewsletterBannerURL(vTemplateJSON["NewsletterBannerURL"])
    }

    if ("UnsubscriberURL" in vTemplateJSON) {
      setvUnsubscriberURL(vTemplateJSON["UnsubscriberURL"])
    }

    if ("Copyright" in vTemplateJSON) {
      setvCopyright(vTemplateJSON["Copyright"])
    }

    if ("Address" in vTemplateJSON) {
      setvAddress(vTemplateJSON["Address"])
    }

    if ("PodcastBanner" in vTemplateJSON) {
      setvPodcastBanner(vTemplateJSON["PodcastBanner"])
    }

    if ("PodcastEmail" in vTemplateJSON) {
      setvPodcastEmail(vTemplateJSON["PodcastEmail"])
    }
    
    if ("NewsletterEmail" in vTemplateJSON) {
      setvNewsletterEmail(vTemplateJSON["NewsletterEmail"])
    }

    if ("UnsubscribeEmail" in vTemplateJSON) {
      setvUnsubscribeEmail(vTemplateJSON["UnsubscribeEmail"])
    }
  }
    
  }

   

  }, [vNewsletterSelected]);

  useEffect(() => {

    console.log("NewsletterManager.useEffect[vFieldNameSelected].vFieldNameSelected:  ",vFieldNameSelected) 
 
   }, [vFieldNameSelected]);

   useEffect(() => {

    if (hasError) {
      throw new Error("An error occurred in GetNewsletters!");
   }
 
   }, [hasError]);



  async function GetNewsletters(pAccountGroup) {
    try {
      setErrorMessage("");
      
      //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: pAccountGroup,
            },
          },
        };
      } else {
        variables = {};
      }

      const listNewslettersData2 = await client.graphql({
        query: listNewsletters,
        variables: variables,
      });

      if (listNewslettersData2.data.listNewsletters.items.length > 0) {
        const NewsletterManager1 =
          listNewslettersData2.data.listNewsletters.items;
          SortNewsletterByName(NewsletterManager1)
          setvNewsletterList(NewsletterManager1);
      } 
    } catch (err) {

      RhyteItLogV2(
          Users,
          "NewsletterManager",
          "GetNewsletters",
          err
      );

      // 🔥 Rethrow to allow ErrorBoundary to catch it
      setHasError(true);
  }

  }



  async function GetSMMADAccountGroups() {
    try {
      setErrorMessage("");
      
      //console.log("NewsletterManager.GetNewsletters.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            ParentAccount: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountGroupsData2 = await client.graphql({
        query: listSMMADAccountGroups,
        variables: variables,
      });

      //console.log("NewsletterManager.GetSMMADAccountGroups.listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length : ", listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length );

      if (listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length > 0) {
        const NewsletterManager1 =
          listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items;

          setvAccountGroupList(NewsletterManager1);
      } 



    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "GetSMMADAccountGroups",
        err
    );

    }
  }

  function SortNewsletterByName(pNewsletter) {
    pNewsletter.sort(GFG_sortFunction);
    return pNewsletter;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let NewsletterA = a.Newsletter;
    let NewsletterB = b.Newsletter;
    return NewsletterA > NewsletterB ? 1 : -1;
  }

  async function CreateNewletter() {
    try {
      //console.log("NewsletterManagerTable.CreateNewletter.vNewNewsletter:  ",vNewNewsletter);

      var vUpdateDateTimeNew = new Date().toISOString();

        const vTemplateJSON = {
            id: vNewNewsletter,
            Description: "",
            PixelTrackerAPIURL: "",
            NewsletterBannerURL: "",
            Sections: ["In This Issue", "Featured Article", "Quick Tips", "Success Story", "Podcast", "Introduction", "Footer"],
            UnsubscriberURL: "",
            Copyright: "",
            Address: "",
            PodcastBanner: "",
            PodcastEmail: "",
            NewsletterEmail: "",
            UnsubscribeEmail: ""
        };

        const vTemplateJSONString = JSON.stringify(vTemplateJSON);      

      let NewletterItem = {
        id: vNewNewsletter,
        Newsletter: vNewNewsletter,
        AccountGroup: vAccountGroupSelected["AccountGroup"],
        NewsletterDate: vUpdateDateTimeNew,
        User: vAccountGroupSelected["ParentAccount"],
        TemplateJSON: vTemplateJSONString,
        Edition: "0",
        Content: "",
        S3Bucket: "",
        S3Key: "",
        NewsletterStatus: "new",
        Active: true,
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",CampaignItem);

      const createNewsletterResponse = await client.graphql({
        query: createNewsletter,
        variables: {
          input: NewletterItem,
        },
      });
      setvAddNewletterVisible(false);
      setvNewNewsletter("");
      GetNewsletters();
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "CreateNewletter",
        err
    );
    }
  }

  async function UpdateNewsletter() {
    try {
      
      //console.log("NewsletterManager.UpdateNewsletter.vNewsletterSelected:  ",vNewsletterSelected);
      //console.log("NewsletterManager.UpdateNewsletter.vNewsletterTitle:  ",vNewsletterTitle);

      var vUpdateDateTimeNew = new Date().toISOString();

      vNewsletterSelected["Newsletter"] = vNewsletterTitle
      vNewsletterSelected["Description"] = vNewsletterDescription

      const vTemplateJSON = {
        id: vNewsletterSelected["id"],
        PixelTrackerAPIURL: vPixelTrackerAPIURL,
        NewsletterBannerURL: vNewsletterBannerURL,
        Sections: ["In This Issue", "Featured Article", "Quick Tips", "Success Story", "Podcast", "Introduction", "Footer"],
        UnsubscriberURL: vUnsubscriberURL,
        Copyright: vCopyright,
        Address: vAddress,
        PodcastBanner: vPodcastBanner,
        PodcastEmail: vPodcastEmail,
        NewsletterEmail: vNewsletterEmail,
        UnsubscribeEmail: vUnsubscribeEmail,
      };

      const vTemplateJSONString = JSON.stringify(vTemplateJSON);     

      let NewletterItem = {
        id: vNewsletterSelected["id"],
        Newsletter: vNewsletterSelected["Newsletter"],
        Description: vNewsletterSelected["Description"],
        User: vNewsletterSelected["User"],
        TemplateJSON: vTemplateJSONString,
        AccountGroup: vNewsletterSelected["AccountGroup"],
        NewsletterDate: vNewsletterSelected["NewsletterDate"],
        Edition:vNewsletterSelected["Edition"],
        Content: vNewsletterSelected["Content"],
        S3Bucket: vS3Bucket,
        S3Key: vNewsletterSelected["S3Key"],
        LogoURL: vLogoURL,
        IconURL: vIconURL,
        NewsletterURL: vNewsletterURL,
        SendCount: vNewsletterSelected["SendCount"],
        OpenCount: vNewsletterSelected["OpenCount"],
        ClickCount: vNewsletterSelected["ClickCount"],
        OpenAIapiKey: vOpenAIapiKey,
        OpenAIOrganization: vOpenAIOrganization,
        OpenAIProject: vOpenAIProject,
        OpenAIModel: vOpenAIModel,  
        NewsletterStatus: "Updated template values",
        Active:vNewsletterSelected["Active"],
        CreationName: vNewsletterSelected["CreationName"],
        CreationDateTime: vNewsletterSelected["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("NewsletterManagerTable.SaveRow.CampaignItem:  ",NewletterItem);

      const updateNewsletterResponse = await client.graphql({
        query: updateNewsletter,
        variables: {
          input: NewletterItem,
        },
      });

      
      //console.log("NewsletterManager.UpdateNewsletter.updateNewsletterResponse:  ",updateNewsletterResponse);

      setvNewsletterSelected(updateNewsletterResponse.data.updateNewsletter)

    } catch (err) {

      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "UpdateNewsletter",
        err
    );

    }
  }


  async function NewsletterSelectedAction(pNewsletter) {
    try {
      //console.log("NewsletterManager.NewsletterSelectedAction.pNewsletter: ", pNewsletter);
      let vSelectedNewsletter = vNewsletterList.filter(
        (vNewletterItem) => vNewletterItem["Newsletter"] === pNewsletter
      );

      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter: ",vSelectedNewsletter);
      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
      setvNewsletterVisible(true);
      //setvNewsletterSelected(pAccount);
      setvNewsletterSelected(vSelectedNewsletter[0]);
    } catch (err) {
    
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "NewsletterSelectedAction",
        err
    );
    }
  }

  async function AccountGroupSelectedAction(pAccountGroup) {
    try {
      //    //console.log("NewsletterManager.NewsletterSelectedAction.pAccount: ", pAccount);
      let vSelectedNewsletterGroup = vAccountGroupList.filter(
        (vAccountGroupItem) => vAccountGroupItem["AccountGroup"] === pAccountGroup
      );

      //console.log("NewsletterManager.AccountGroupSelectedAction.vSelectedNewsletterGroup: ",vSelectedNewsletterGroup);
      //console.log("NewsletterManager.NewsletterSelectedAction.vSelectedNewsletter[0]: ",vSelectedNewsletter[0]);
      setvNewsletterVisible(true);
      //setvNewsletterSelected(pAccount);
      setvAccountGroupSelected(vSelectedNewsletterGroup[0]);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "AccountGroupSelectedAction",
        err
    );
    }
  }

  async function ViewConfig(event) {
    try {
      //console.log("NewsletterManager.ViewConfig.event: ", event);
      //console.log("NewsletterManager.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
      setvAddNewletterVisible(!vAddNewletterVisible)


    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "ViewConfig",
        err
    );
    }
  }

  const handleNavigateToNewsletterSubscribers = () => {
    navigate("/NewsletterSubscribers", { state: { NewsletterSelected: vNewsletterSelected }});
  };

  const handleNavigateToNewsletterEditor = () => {
   // //console.log("NewsletterManager.handleNavigateToNewsletterEditor.vNewsletterSelected: ", vNewsletterSelected);
    navigate("/NewsletterEditor", { state: { NewsletterSelected: vNewsletterSelected }});
  };

  const handleNavigateToNewsletterPublisher = () => {
    // //console.log("NewsletterManager.handleNavigateToNewsletterEditor.vNewsletterSelected: ", vNewsletterSelected);
     navigate("/NewsletterPublisher", { state: { NewsletterSelected: vNewsletterSelected }});
   };
 

   function NewNewsletter_handleChange(event, data) {
    try {
      setvNewNewsletter(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "NewNewsletter_handleChange",
        err
    );
    }
  }

  function Newsletter_handleChange(event, data) {
    try {
      setvNewsletterTitle(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "Newsletter_handleChange",
        err
    );
    }
  }

  function NewsletterDescription_handleChange(event, data) {
    try {
      setvNewsletterDescription(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "NewsletterDescription_handleChange",
        err
    );
    }
  }

  function PixelTrackerAPIURL_handleChange(event, data) {
    try {
      setvPixelTrackerAPIURL(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "PixelTrackerAPIURL_handleChange",
        err
    );
    }
  }

  function UnsubscriberURL_handleChange(event, data) {
    try {
      setvUnsubscriberURL(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "UnsubscriberURL_handleChange",
        err
    );
    }
  }

  function NewsletterBannerURL_handleChange(event, data) {
    try {
      setvNewsletterBannerURL(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "NewsletterBannerURL_handleChange",
        err
    );
    }
  }
  
  function Copyright_handleChange(event, data) {
    try {
      setvCopyright(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "Copyright_handleChange",
        err
    );
    }
  }

  function Address_handleChange(event, data) {
    try {
      setvAddress(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "Address_handleChange",
        err
    );
    }
  }

  function PodcastEmail_handleChange(event, data) {
    try {
      setvPodcastEmail(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "PodcastEmail_handleChange",
        err
    );
    }
  }

  function NewsletterEmail_handleChange(event, data) {
    try {
      setvNewsletterEmail(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "NewsletterEmail_handleChange",
        err
    );
    }
  }

  function UnsubscribeEmail_handleChange(event, data) {
    try {
      setvUnsubscribeEmail(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "UnsubscribeEmail_handleChange",
        err
    );
    }
  } 
  
  function PodcastBanner_handleChange(event, data) {
    try {
      setvPodcastBanner(event.target.value);
    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "PodcastBanner_handleChange",
        err
    );
    }
  }

  function Field_handleChange(event, data, pField) {
    try {


      if (pField === "vOpenAIapiKey")  {
        setvOpenAIapiKey(event.target.value);
      } else if (pField === "vOpenAIOrganization")  {
        setvOpenAIOrganization(event.target.value);
      } else if (pField === "vOpenAIProject")  {
        setvOpenAIProject(event.target.value);
      } else if (pField === "vOpenAIModel")  {
        setvOpenAIModel(event.target.value);
      } else if (pField === "vS3Bucket")  {
        setvS3Bucket(event.target.value);
      } else if (pField === "vLogoURL")  {
        setvLogoURL(event.target.value);
      } else if (pField === "vIconURL")  {
        setvIconURL(event.target.value);
      } else if (pField === "vNewsletterURL")  {
        setvNewsletterURL(event.target.value);
      } 

    

    } catch (err) {
      RhyteItLogV2(
        Users,
        "NewsletterManager",
        "Field_handleChange",
        err
    );
    }
  }



   async function ShowSection(pShowSection) {
    try {
      let pShowSectionTmp = pShowSection 
      console.log("NewsletterManager.ShowSection.pShowSection);: " ,pShowSection);
      setvFieldNameSelected(pShowSection["FieldName"])
      setvLabelSelected(pShowSection["LabelName"])
      setvFieldTypeSelected(pShowSection["FieldType"])

      if (pShowSection["FieldName"] === "Subscribers") {
        handleNavigateToNewsletterSubscribers()
      }

      if (pShowSection["FieldName"] === "Editions") {
        handleNavigateToNewsletterEditor()
      }

      if (pShowSection["FieldName"] === "Publishing") {
        handleNavigateToNewsletterPublisher()
      }

      /**
       
      console.log("EmailHTMLTemplateTable.ShowSection.pShowSection: " ,pShowSection);
      console.log("EmailHTMLTemplateTable.ShowSection.FieldName: " ,pShowSection["FieldName"]);
      console.log("EmailHTMLTemplateTable.ShowSection.LabelName: " ,pShowSection["LabelName"]);
      console.log("EmailHTMLTemplateTable.ShowSection.FieldType: " ,pShowSection["FieldType"]);* 
      */
        } catch (err) { 

          RhyteItLogV2(
            Users,
            "NewsletterManager",
            "ShowSection",
            err
        );       
      }    
    }       

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="Newsletter Manager"
          SubTitle="Manage your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="NewsletterManager"
        />
        {vAccountGroupList !== undefined &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Account Group</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
          <SelectField
            className={styles.SelectField}
            value={vAccountGroupSelected.AccountGroup}
            placeholder="Select account"
            onChange={(e) => AccountGroupSelectedAction(e.target.value)}
          >
            {vAccountGroupList !== undefined &&
              vAccountGroupList.map((AccountGroup) => (
                <option
                  key={AccountGroup.AccountGroup}
                  className={styles.SelectFieldOption}
                  value={AccountGroup.AccountGroup}
                  label={AccountGroup.AccountGroup}
                >
                  {AccountGroup.AccountGroup}
                </option>
              ))}
          </SelectField>  
          {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
                      <Flex
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="0.25rem"
                    >
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.AccountGroup}</Text>  
            </Flex> 
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroupLabel}>Parent Account:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.ParentAccount}</Text>
              <Text  className={styles.AccountGroupLabel}>Sync On:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.SyncAccounts.toString()}</Text>
            </Flex> 
            </Flex>              
          )}
          </Flex>         
        </Fieldset>        
        )}   
        {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Newsletters</legend>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
          >
            <SelectField
              className={styles.SelectField}
              value={vNewsletterSelected.Account}
              placeholder="Select Newsletter"
              onChange={(e) => NewsletterSelectedAction(e.target.value)}
            >
              {vNewsletterList !== undefined &&
                vNewsletterList.map((Newsletter) => (
                  <option
                    key={Newsletter.Newsletter}
                    className={styles.SelectFieldOption}
                    value={Newsletter.Newsletter}
                    label={Newsletter.Newsletter}
                  >
                    {Newsletter.Newsletter}
                  </option>
                ))}
            </SelectField>
            <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => ViewConfig(e)}
              >
                Add Newsletter
              </Button>            
          </Flex>
          {vAddNewletterVisible === true && (
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.NewNewsletterRow}
            >
              <TextField
                className={styles.CampaignField}
                name="Account"
                placeholder="Add new Newsletter"
                defaultValue={vNewNewsletter}
                onChange={(e) => NewNewsletter_handleChange(e, vNewNewsletter)}
              />
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => CreateNewletter(e)}
              >
                Create Newsletter
              </Button>
            </Flex>
          )}
          {vNewsletterSelected["Newsletter"] !== "None" &&  (
              <Flex
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    gap="1rem"
                    className={styles.FlexRow}>   
              {
                vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (      
                                  
                  (<Button   key={vFieldButton.FieldName}  
                              className={styles.ButtonSection}
                              onClick={(e) => ShowSection(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
                  ))
              }            
                              
              </Flex>      
          )}                   
        </Fieldset>             
        )}             
      </Flex>
      {vFieldNameSelected === "MetaData" &&  (
      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="3rem"
            className={styles.NewNewsletterRow}
          >
              <Text className={styles.SectionHeader}>
                Edit Template
              </Text>  
              <Button
                    className={styles.Button}
                    variation="primary"
                    onClick={(e) => UpdateNewsletter(e)}
                  >
                    Save Values
              </Button>  
              <Text className={styles.SectionHeader}>                
                {new Date(vNewsletterSelected["UpdateDateTime"]).toISOString().slice(0, 10)} {new Date(vNewsletterSelected["UpdateDateTime"]).toISOString().slice(11, 16)}
              </Text>                          
          </Flex>      
          <Table className={styles.Table}   highlightOnHover={true} key={vNewsletterSelected.id}>   
          <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells} >Parameter</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Value</TableCell> 
            </TableRow>                                      
          </TableHead>       
          <TableBody>         
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Title
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterTitle"
                placeholder="Enter newsletter title"
                defaultValue={vNewsletterTitle}
                onChange={(e) => Newsletter_handleChange(e, vNewNewsletter)}
              />              
            </TableCell>                                          
          </TableRow>             
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Description
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextAreaField
                className={styles.TextAreaField}
                name="vNewsletterDescription"
                placeholder="Enter newsletter Description"
                defaultValue={vNewsletterDescription}
                rows={5}
                onChange={(e) => NewsletterDescription_handleChange(e, vNewNewsletter)}
              />              
            </TableCell>                                          
          </TableRow>         
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Pixel Tracker AP IURL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPixelTrackerAPIURL"
                placeholder="Enter Pixel Tracker API URL"
                defaultValue={vPixelTrackerAPIURL}
                onChange={(e) => PixelTrackerAPIURL_handleChange(e, vPixelTrackerAPIURL)}
              />              
            </TableCell>                                          
          </TableRow>           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Newsletter Banner URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterBannerURL"
                placeholder="Enter  Banner URL"
                defaultValue={vNewsletterBannerURL}
                onChange={(e) => NewsletterBannerURL_handleChange(e, vNewsletterBannerURL)}
              />              
            </TableCell>                                          
          </TableRow>           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Unsubscribe URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vUnsubscriberURL"
                placeholder="Enter Unsubscribe URL"
                defaultValue={vUnsubscriberURL}
                onChange={(e) => UnsubscriberURL_handleChange(e, vUnsubscriberURL)}
              />              
            </TableCell>                                          
          </TableRow>                
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Copyright
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vCopyright"
                placeholder="Enter Copyright"
                defaultValue={vCopyright}
                onChange={(e) => Copyright_handleChange(e, vCopyright)}
              />              
            </TableCell>                                          
          </TableRow>                    
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Address
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vAddress"
                placeholder="Enter Address"
                defaultValue={vAddress}
                onChange={(e) => Address_handleChange(e, vAddress)}
              />              
            </TableCell>                                          
          </TableRow>                     
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Podcast Banner
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPodcastBanner"
                placeholder="Enter Podcast Banner"
                defaultValue={vPodcastBanner}
                onChange={(e) => PodcastBanner_handleChange(e, vPodcastBanner)}
              />              
            </TableCell>                                          
          </TableRow>                      
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Podcast Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vPodcastEmail"
                placeholder="Enter Podcast Email"
                defaultValue={vPodcastEmail}
                onChange={(e) => PodcastEmail_handleChange(e, vPodcastEmail)}
              />              
            </TableCell>                                          
          </TableRow>                       
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Newsletter Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterEmail"
                placeholder="Enter Newsletter Email"
                defaultValue={vNewsletterEmail}
                onChange={(e) => NewsletterEmail_handleChange(e, vNewsletterEmail)}
              />              
            </TableCell>                                          
          </TableRow>                           
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
                Unsubscribe Email
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vUnsubscribeEmail"
                placeholder="Enter Unsubscribe Email"
                defaultValue={vUnsubscribeEmail}
                onChange={(e) => UnsubscribeEmail_handleChange(e, vUnsubscribeEmail)}
              />              
            </TableCell>                                          
          </TableRow>
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Open AI api Key
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vOpenAIapiKey"
                placeholder="Enter Open AI api Key"
                defaultValue={vOpenAIapiKey}
                onChange={(e) => Field_handleChange(e, vOpenAIapiKey, "vOpenAIapiKey")}
              />              
            </TableCell>                                          
          </TableRow>
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              OpenAI Organization
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vOpenAIOrganization"
                placeholder="Enter OpenAI Organization"
                defaultValue={vOpenAIOrganization}
                onChange={(e) => Field_handleChange(e, vOpenAIOrganization, "vOpenAIOrganization")}
              />              
            </TableCell>                                          
          </TableRow> 
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              OpenAI Project
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vOpenAIProject"
                placeholder="Enter OpenAI Project"
                defaultValue={vOpenAIProject}
                onChange={(e) => Field_handleChange(e, vOpenAIProject, "vOpenAIProject")}
              />              
            </TableCell>                                          
          </TableRow> 
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              OpenAI Model
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vOpenAIModel"
                placeholder="Enter OpenAI Model"
                defaultValue={vOpenAIModel}
                onChange={(e) => Field_handleChange(e, vOpenAIModel, "vOpenAIModel")}
              />              
            </TableCell>                                          
          </TableRow>        
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              S3 Bucket
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vS3Bucket"
                placeholder="Enter S3 Bucket"
                defaultValue={vS3Bucket}
                onChange={(e) => Field_handleChange(e, vS3Bucket, "vS3Bucket")}
              />              
            </TableCell>                                          
          </TableRow>        
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Logo URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vLogoURL"
                placeholder="Enter Logo URL"
                defaultValue={vLogoURL}
                onChange={(e) => Field_handleChange(e, vLogoURL, "vLogoURL")}
              />              
            </TableCell>                                          
          </TableRow>        
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Icon URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vIconURL"
                placeholder="Enter Icon URL"
                defaultValue={vIconURL}
                onChange={(e) => Field_handleChange(e, vIconURL, "vIconURL")}
              />              
            </TableCell>                                          
          </TableRow>        
          <TableRow  className={styles.TableRow} >    
            <TableCell className={styles.TableCellParameter}>  
              <Text  className={styles.TextField}>
              Newsletter URL
              </Text>
            </TableCell>             
            <TableCell className={styles.TableCellValue}> 
            <TextField
                className={styles.CampaignField}
                name="vNewsletterURL"
                placeholder="Enter Newsletter URL"
                defaultValue={vNewsletterURL}
                onChange={(e) => Field_handleChange(e, vNewsletterURL, "vNewsletterURL")}
              />              
            </TableCell>                                          
          </TableRow> 
         </TableBody>                    
        </Table>                        
      </Flex>         
        )}   
      {vFieldNameSelected === "NewsletterGeneration" &&  (
        <NewsletterGeneration Newsletter={vNewsletterSelected} Users={Users}/>               
      )} 
      {vFieldNameSelected === "EmailHTMLTemplate" &&  (
        <EmailHTMLTemplate Newsletter={vNewsletterSelected} Users={Users}/>               
      )}         
      {vFieldNameSelected === "NewsletterPrompts" &&  (
        <NewsletterPrompts Newsletter={vNewsletterSelected} Users={Users}/>               
      )}     
      {vFieldNameSelected === "NewsletterFileURLs" &&  (
        <NewsletterFileURLs Newsletter={vNewsletterSelected} Users={Users}/>               
      )}     
      {vFieldNameSelected === "NewsletterImageURLs" &&  (
        <NewsletterImageURLs Newsletter={vNewsletterSelected} Users={Users}/>               
      )}       

    </m.div>
  );
};


export default NewsletterManager;
