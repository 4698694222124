import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import { SEO } from "../../SEO/SEO";
import { generateClient } from "aws-amplify/api";

import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./RhyteItSignUp.module.css";

const RhyteItSignUp = (props) => {
  RhyteItSignUp.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();

  const navigate = useNavigate();
  

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
        <SEO
          title="RhyteIt: Smart Automation & AI Solutions for Adult Creators"
          description="Unlock your potential with RhyteIt, offering innovative automation and AI tools for adult content creators. Streamline your workflow and maximize profits effortlessly."
          name="RhyteIt"
          type="website"
          image="https://news.rhyteit.com/adverts/PressReleasePoster3.png" 
          keywords="OnlyFans marketing, How to grow OnlyFans account fast, X (Twitter) marketing for adult creators"
          CanonicalURL="https://www.rhyteit.com/RhyteItSignUp"
        />
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <h1 className={styles.h1}>The Smartest Way to Grow Your OnlyFans Business—On Autopilot!</h1>
        <h2 className={styles.h2}>Unlimited AI-powered social media marketing, PR, analytics, and affiliate revenue—all for just $20/month.</h2>
        <ul>
          <li className={styles.li}>Growing an OnlyFans business is time-consuming and competitive.</li>
          <li className={styles.li}>Social media algorithms make it hard to get seen.</li>
          <li className={styles.li}>Many creators struggle with marketing and don’t know what works.</li>
        </ul>
        <ul>
          <li className={styles.li}>AI-powered marketing that posts for you 24/7 on multiple platforms.</li>
          <li className={styles.li}>A press release every week to boost credibility.</li>
          <li className={styles.li}>Industry insights and an OnlyFans profile evaluator to optimize success.</li>
          <li className={styles.li}>A built-in affiliate program to make passive income.</li>
        </ul>    
        <h2 className={styles.h2}>Want to see how RhyteIt can 10x your audience?</h2>                              
        <Link className={styles.Link} href="/TwitterStats" isExternal={false}>
          <Button className={styles.Button}    
                  variation="primary">Learn More</Button>  
          </Link>  
      </Flex>                   
    </m.div>
  );
};

export default RhyteItSignUp;
